import React, { useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Menu, MenuItem, Badge, Theme, Link } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useWindowSize } from 'hooks/useWindowSize';
import { UserContext } from '../App';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import firebase from 'firebase/app';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    height: (props: any) => props.height - 56 - 1,
    [theme.breakpoints.up('sm')]: {
      height: (props: any) => props.height - 64 - 1,
    },
    overflowY: 'scroll',
  },
  drawerContent: {
    height: (props: any) => props.height - 56 - 1,
    [theme.breakpoints.up('sm')]: {
      height: (props: any) => props.height - 64 - 1,
    },
    overflowY: 'scroll',
  },
  drawerInnerContent: {
    padding: theme.spacing(3),
  },
  navListContainer: {
    marginTop: 5,
  },
}));

function SideNavWrapper({ children }: any) {
  const { width, height } = useWindowSize();
  const classes = useStyles({ height, width });
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { user } = useContext(UserContext);

  let isSuperAdmin = false;
  if (user && user.permissionGroups) {
    isSuperAdmin = user.permissionGroups.reduce(
      (accumulator: boolean, group: any) => {
        return group.name === 'super_admins' || accumulator;
      },
      false
    );
  }
  let isPLAllowed = false;
  if (user && user.permissionGroups) {
    isPLAllowed = user.permissionGroups.reduce(
      (accumulator: boolean, group: any) => {
        return group.name === 'parallel_learning' || accumulator;
      },
      false
    );
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <div className={classes.drawerContent}>
        <div className={classes.drawerInnerContent}>
          <Link exact component={NavLink} to="/">
            <Typography variant="h6" component="h1">
              Dashboard
            </Typography>
          </Link>
          <ul className={classes.navListContainer}>
            {user.license && (
              <>
                <li>
                  <Link component={NavLink} to="/micro-learning">
                    Micro Learning
                  </Link>
                </li>
                <li>
                  <Link component={NavLink} to="/quiz">
                    Quiz
                  </Link>
                </li>
              </>
            )}
            {/* <li>
              <Link component={RouterLink} to="/work-permit">Work Permit</Link>
            </li>
            <li>
              <Link component={RouterLink} to="/company-admin/micro-learning">Messages</Link>
            </li> */}
          </ul>
          {isSuperAdmin && (
            <div>
              <Link component={RouterLink} to="/super-admin">
                <Typography variant="h6" component="h1">
                  Super Admin
                </Typography>
              </Link>
              {/* <ul className={classes.navListContainer}>
                <li>
                  <Link component={RouterLink} to="/super-admin/company-admins">
                    Admins
                  </Link>
                </li>
                <li>
                  <Link
                    component={RouterLink}
                    to="/super-admin/company-licenses"
                  >
                    Licenses
                  </Link>
                </li>
              </ul> */}
            </div>
          )}
          {user && user.adminFor && (
            <div>
              <Link exact component={NavLink} to="/company-admin">
                <Typography variant="h6" component="h1">
                  Admin
                </Typography>
              </Link>
              <ul className={classes.navListContainer}>
                <li>
                  <Link
                    exact
                    component={NavLink}
                    to={`/company-admin/${user.adminFor.id}/users`}
                  >
                    Users
                  </Link>
                </li>
                <li>
                  <Link
                    exact
                    component={NavLink}
                    to={`/company-admin/${user.adminFor.id}/micro-learning`}
                  >
                    Micro Learning
                  </Link>
                </li>
                <li>
                  <Link
                    exact
                    component={NavLink}
                    to={`/company-admin/${user.adminFor.id}/safety-eye`}
                  >
                    Safety Eye
                  </Link>
                </li>
                <li>
                  <Link
                    exact
                    component={NavLink}
                    to={`/company-admin/${user.adminFor.id}/quiz`}
                  >
                    Quiz
                  </Link>
                </li>
              </ul>
            </div>
          )}
          {isPLAllowed && (
            <div>
              <Link component={RouterLink} to="/parallel-learning">
                <Typography variant="h6" component="h1">
                  Parallel Learning
                </Typography>
              </Link>
              <ul className={classes.navListContainer}>
                <li>
                  <Link component={RouterLink} to="/parallel-learning/printing">
                    Printing
                  </Link>
                </li>
                <li>
                  <Link
                    component={RouterLink}
                    to="/parallel-learning/work-orders"
                  >
                    Work Orders
                  </Link>
                </li>
                <li>
                  <Link
                    component={RouterLink}
                    to="/parallel-learning/micro-learning-sessions"
                  >
                    ML Sessions
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div style={{ flexGrow: 1 }}>
            <Typography variant="h6" noWrap>
              i4pt0
            </Typography>
          </div>
          <div>
            <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={0} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem button={false} style={{ outline: 'none' }}>
                <Typography color="textSecondary">
                  Signed in as {user && user.userName}
                </Typography>
              </MenuItem>
              <MenuItem to="/profile" component={RouterLink}>
                Your profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  firebase.auth().signOut();
                }}
              >
                Sign out
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main>
        <div className={classes.toolbar} />
        <div className={classes.content}>{children}</div>
      </main>
    </div>
  );
}

export default SideNavWrapper;
