import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { GET_SESSION_QUERY } from 'components/parallel-learning/micro-learning/MicrolearningSessionData';
import {
  MicroLearningSessionGET,
  MicroLearningSessionGETVariables
} from '__generated__/MicroLearningSessionGET';
import { LoadingOrError } from 'components/LoadingOrError';

const MLSessionLive = React.lazy(() => import('./MLSessionLive'));

export function MLSessionView() {
  const { id }: any = useParams();
  const { data, loading, error } = useQuery<
    MicroLearningSessionGET,
    MicroLearningSessionGETVariables
  >(GET_SESSION_QUERY, {
    variables: {
      id
    }
  });

  if (data && data.microLearningSession) {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <MLSessionLive config={JSON.parse(data.microLearningSession.data)} />
      </Suspense>
    );
  }

  return <LoadingOrError {...{ loading, error }} />;
}
