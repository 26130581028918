import React, { useEffect, useState, useCallback } from 'react';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import FullScreenExitIcon from '@material-ui/icons/FullscreenExit';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import ReplayIcon from '@material-ui/icons/Replay';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton, makeStyles, Typography, Button } from '@material-ui/core';
import screenfull from 'screenfull';
import { useHistory } from 'react-router-dom';
import { useSpring, animated as a } from 'react-spring';
import './style.css';
import { useWindowSize } from 'hooks/useWindowSize';
import firebase from 'firebase/app';

function shuffle(array: any) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const PPES_SORTED = Array.from(Array(24).keys()).map((index) =>
  index % 2 === 0
    ? `https://storage.googleapis.com/pl-public-assets/flipthetileassets/PPE-${
        Math.floor(index / 2) + 1
      }.svg`
    : `PAIR::https://storage.googleapis.com/pl-public-assets/flipthetileassets/PPE-${
        Math.floor(index / 2) + 1
      }.svg`
);

const PPES: string[] = shuffle(PPES_SORTED);

const useStyles = makeStyles(() => {
  return {
    container: (props: any) => ({
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: 'repeat(6, 1fr)',
      gridTemplateRows: 'repeat(4, 1fr)',
      padding: 16,
      height: props.height,
      width: props.width,
      gridGap: 12,
    }),
  };
});

function GameFinish(props: any) {
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('game_finsished')) {
      return;
    } else {
      localStorage.setItem('game_finsished', 'TRUE');
    }
    const doc = firebase
      .firestore()
      .collection('shubhlife-session')
      .doc(localStorage.getItem('@Shubhlife_username') as string);
    doc.set({
      time: props.timeElapsed,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: '#fff',
          padding: 16,
          borderRadius: 12,
          textAlign: 'center',
          minWidth: 180,
          minHeight: 150,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="body2">Congratulations!</Typography>
        <Typography variant="body2">
          You have completed the challnege.
        </Typography>
        {/* <div>
          <IconButton size="medium" onClick={props.reset}>
            <ReplayIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              history.push('/safety-week-2020');
            }}
            size="medium"
          >
            <ClearIcon />
          </IconButton>
        </div> */}
      </div>
    </div>
  );
}

function Card(props: { ppe: string; onReveal: any; pair: any; solved: any }) {
  const [flipped, set] = useState(false);
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const handleClick = useCallback(
    () =>
      set((state) => {
        if (props.solved[props.ppe.replace('PAIR::', '')]) {
          console.log('is flipped already...');
          return true;
        }
        return !state;
      }),
    [props.ppe, props.solved]
  );

  useEffect(() => {
    props.onReveal(props.ppe, flipped);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flipped]);

  useEffect(() => {
    if (
      flipped &&
      !props.pair.includes(props.ppe) &&
      !props.solved[props.ppe.replace('PAIR::', '')]
    ) {
      set(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pair]);

  return (
    <div onClick={handleClick} style={{ position: 'relative', padding: 0 }}>
      <a.div
        className="c back"
        style={{ opacity: opacity.interpolate((o: any) => 1 - o), transform }}
      >
        <EmojiObjectsIcon style={{ fontSize: 32 }} />
      </a.div>
      <a.div
        className="c front"
        style={{
          opacity,
          transform: transform.interpolate((t) => `${t} rotateY(180deg)`),
        }}
      >
        <img
          src={props.ppe.replace('PAIR::', '')}
          alt={props.ppe}
          style={{ height: '100%', width: '100%' }}
        />
      </a.div>
    </div>
  );
}

export function Flip() {
  const [start, setStart] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [playKey, setPlayKey] = useState(0);
  const [solved, setSolved] = useState<{ [key: string]: boolean }>({});
  const [pair, setPair] = useState<string[]>([]);
  const { width, height } = useWindowSize();
  const classes = useStyles({ width, height });
  const [fullscreen, setFullScreen] = useState(false);
  const history = useHistory();
  let timer: any;

  const updateTime = useCallback(() => {
    setTimeElapsed((pre) => pre + 1000);
  }, []);

  const startTimer = () => {
    setStart(true);
    timer = setInterval(updateTime, 1000);
  };

  useEffect(() => {
    const callback = () => {
      setFullScreen((screenfull as any).isFullscreen);
    };
    if ((screenfull as any).isEnabled) {
      (screenfull as any).on('change', callback);
    }
    return () => {
      (screenfull as any).off('change', callback);
    };
  }, []);

  const onReveal = useCallback((ppe: string, flipped: boolean) => {
    if (flipped) {
      setPair((pair) => {
        return Array.from(new Set([...pair, ppe]));
      });
    }
  }, []);

  useEffect(() => {
    if (pair.length === 2) {
      if (pair[0].replace('PAIR::', '') === pair[1].replace('PAIR::', '')) {
        setSolved({ ...solved, [pair[0].replace('PAIR::', '')]: true });
        setPair([]);
      }
    }
    if (pair.length === 3) {
      if (pair[0].replace('PAIR::', '') === pair[1].replace('PAIR::', '')) {
        setSolved({ ...solved, [pair[0].replace('PAIR::', '')]: true });
        setPair(
          pair.filter((p, i) => {
            if (i === 0 || i === 1) {
              return false;
            }
            return true;
          })
        );
      } else {
        setPair(
          pair.filter((p, i) => {
            if (i === 0 || i === 1) {
              return false;
            }
            return true;
          })
        );
      }
    }
  }, [pair, solved]);

  useEffect(() => {
    if (timer) {
      clearInterval(timer);
    }
  }, [timer]);

  const reset = () => {
    setSolved({});
    setPair([]);
    setPlayKey((prev) => prev + 1);
    setTimeElapsed(0);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          left: '50%',
          width: 100,
          textAlign: 'center',
          top: 0,
          padding: 8,
          zIndex: 5,
          borderRadius: 12,
          backgroundColor: 'rgba(255,255,255,0.5)',
          transform: 'translateX(-50px)',
        }}
      >
        <IconButton
          size="small"
          onClick={() => {
            if ((screenfull as any).isEnabled) {
              (screenfull as any).toggle();
            }
          }}
        >
          {fullscreen ? (
            <FullScreenExitIcon style={{ color: 'red' }} />
          ) : (
            <FullScreenIcon style={{ color: 'red' }} />
          )}
        </IconButton>
        &nbsp;&nbsp;
        <IconButton
          size="small"
          onClick={() => {
            history.push('/safety-week-2020');
          }}
        >
          <ClearIcon style={{ color: 'red' }} />
        </IconButton>
      </div>
      <div
        style={{
          position: 'absolute',
          right: 0,
          width: 100,
          textAlign: 'center',
          top: 0,
          padding: 8,
          zIndex: 5,
          borderRadius: 12,
          backgroundColor: 'rgba(255,255,255,0.5)',
        }}
      >
        <Typography align="center">
          {`${
            Math.floor(timeElapsed / 1000 / 60) < 10
              ? '0' + Math.floor(timeElapsed / 1000 / 60)
              : Math.floor(timeElapsed / 1000 / 60)
          }:${
            (timeElapsed / 1000) % 60 < 10
              ? '0' + ((timeElapsed / 1000) % 60)
              : (timeElapsed / 1000) % 60
          }`}
        </Typography>
      </div>
      {!start && (
        <div
          style={{
            height,
            width,
            backgroundColor: 'transparent',
            position: 'absolute',
            zIndex: 10,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div
            style={{
              width: width - 32,
              height: height - 32,
              backgroundColor: 'rgba(0,0,0,0.8)',
              borderRadius: 12,
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h5"
              style={{ fontWeight: 'bold', paddingTop: 8, color: '#fff' }}
            >
              INSTRUCTIONS TO PLAY THE GAME
            </Typography>
            <Typography
              style={{
                padding: 32,
                paddingBottom: 8,
                color: '#fff',
                textAlign: 'justify',
              }}
            >
              The aim of this game is to match the pair of cards. Click on a
              card in the interactivity below to turn it over. Then click on
              another one. If the two cards match, they will stay face-up. If
              the two cards do not match, they will return to being face down.
              The game ends when all the cards have been matched in pairs.
            </Typography>
            <br />
            <Button onClick={startTimer} variant="contained">
              START
            </Button>
          </div>
        </div>
      )}
      <div className={classes.container} key={playKey}>
        {(Object.keys(solved).length === 12 ||
          localStorage.getItem('game_finsished')) && (
          <GameFinish reset={reset} timeElapsed={timeElapsed} />
        )}
        {PPES.map((ppe) => {
          return (
            <Card
              key={ppe}
              ppe={ppe}
              onReveal={onReveal}
              pair={pair}
              solved={solved}
            />
          );
        })}
      </div>
    </div>
  );
}
