import React, { useState, useEffect, useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import firebase from 'firebase/app';
import gql from 'graphql-tag';
import { UserContext } from 'App';
import { useApolloClient } from '@apollo/react-hooks';

export const USER_FRAGMENT = gql`
  fragment UserInfo on User {
    id
    email
    userName
    firstName
    lastName
    deviceToken
    contactNumber
    birthDate
    deleted
    blocked
    createdAt
    department
    empNo
    company {
      id
      name
    }
    adminFor {
      id
      name
    }
    license {
      id
      createdAt
      expires
    }
    permissionGroups {
      id
      name
    }
  }
`;

export const ME_QUERY = gql`
  query Me {
    me {
      ...UserInfo
    }
  }
  ${USER_FRAGMENT}
`;

export const PrivateRoute: React.FC<any> = ({ children, ...rest }) => {
  const { pathname } = useLocation();
  const { user, setUser } = useContext(UserContext);
  const [auth, setAuth] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const client = useApolloClient();

  useEffect(() => {
    // firebase.auth().signOut();
    return firebase.auth().onAuthStateChanged(async (userAuth) => {
      if (!userAuth) {
        setLoading(false);
      } else {
        // console.log(await userAuth.getIdToken());
        if (!user) {
          try {
            const { data } = await client.query({ query: ME_QUERY });
            console.log(data);
            if (data) {
              setAuth(userAuth);
              setUser(data.me);
              setLoading(false);
            }
          } catch (err) {
            setAuth(userAuth);
            setUser(null as any);
            setLoading(false);
            console.log(err);
          }
        } else {
          setAuth(userAuth);
          setLoading(false);
        }
      }
    });
  }, [client, setAuth, setUser, user]);

  // console.log(user, auth, loading);

  if (loading) {
    return null;
  }

  if (!auth && !loading) {
    return (
      <Redirect
        to={{
          pathname: '/auth/firebase-login',
          state: { from: pathname },
        }}
      />
    );
  }

  if (!user && auth && !loading) {
    return (
      <Redirect
        to={{
          pathname: rest.userNotFoundRedirect || '/not-registered',
          state: { from: pathname },
        }}
      />
    );
  }

  if (auth?.email && !auth.emailVerified) {
    return (
      <Redirect
        to={{
          pathname: '/auth/email-verification-pending',
          state: { from: pathname },
        }}
      />
    );
  }

  return <Route {...rest}>{children}</Route>;
};
