import React from 'react';
import firebase from 'firebase/app';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';

function SignUp() {
  const history = useHistory();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={() =>
        Yup.object().shape({
          email: Yup.string().required('Email is required!').email(),
          password: Yup.string().min(6).required('Password is required!'),
        })
      }
      onSubmit={async (
        values,
        { setSubmitting, setStatus /* setValues and other goodies */ }
      ) => {
        try {
          await firebase
            .auth()
            .createUserWithEmailAndPassword(values.email, values.password);
          await (firebase.auth().currentUser as any).sendEmailVerification();
          history.push('/auth/email-verification-pending');
        } catch (error) {
          setSubmitting(false);
          setStatus({ type: 'error', message: error.message });
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        status,
      }) => (
        <div className="login-container">
          <div style={{ padding: 12, maxWidth: 400, margin: '0 auto' }}>
            <br />
            <Typography variant="h5" component={'h1'}>
              Sign up
            </Typography>
            <br />
            <form onSubmit={handleSubmit} noValidate>
              {status && (
                <div>
                  <Alert severity={status.type}>{status.message}</Alert>
                  <br />
                </div>
              )}
              <TextField
                label="Email"
                required
                fullWidth
                variant="outlined"
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                onChange={(e) => setFieldValue('email', e.target.value)}
                placeholder="Enter email"
                value={values.email}
              />
              <br />
              <br />
              <TextField
                label="Password"
                fullWidth
                variant="outlined"
                required
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                onChange={(e) => setFieldValue('password', e.target.value)}
                value={values.password}
                placeholder="Enter password"
                type="password"
              />
              <br />
              <br />
              <Button
                disabled={isSubmitting}
                variant="outlined"
                color="secondary"
                type="submit"
              >
                Sign up
              </Button>
            </form>
            <br />
          </div>
        </div>
      )}
    </Formik>
  );
}
export default SignUp;
