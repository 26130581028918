import React from 'react';
import { Typography, Grid, TextField, Button } from '@material-ui/core';
import { Formik, getIn } from 'formik';
import * as Yup from 'yup';

export function HazardSuggestions({
  onSubmit,
  onNext,
  results,
  preview
}: {
  onSubmit: (answers: any, cb: any) => void;
  onNext: () => void;
  results: any;
  preview?: boolean;
}) {
  return (
    <div style={{ padding: 16 }}>
      <Typography variant="h4">Part D: Suggestions to improve</Typography>
      <br />
      <Typography>
        Think about your work and report 2 important hazards which may pose high
        or medium risk.
      </Typography>
      <Typography>
        Remember, your suggestions are important for improving safety of work
        environment.
      </Typography>
      <br />
      <Formik
        initialValues={
          results && results.answers
            ? results.answers
            : {
                suggestions: [
                  { task: '', hazard: '', controls: '' },
                  { task: '', hazard: '', controls: '' }
                ]
              }
        }
        validationSchema={Yup.object().shape({
          suggestions: Yup.array()
            .min(2, 'Minimum 2 inputs required')
            .of(
              Yup.object({
                task: Yup.string().required('Task is a required field'),
                hazard: Yup.string().required('Hazard is a required field'),
                controls: Yup.string().required('Controls is a required field')
              })
            )
        })}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, () => {
            setSubmitting(false);
          });
        }}
      >
        {({
          errors,
          values,
          handleBlur,
          handleChange,
          touched,
          isSubmitting,
          handleSubmit
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              {values.suggestions.map((suggest: any, index: any) => {
                return (
                  <div key={index}>
                    <Typography>{index + 1}</Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          multiline
                          disabled={!!results}
                          rows={3}
                          label="Task"
                          fullWidth
                          value={suggest.task}
                          name={`suggestions[${index}].task`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            !!(
                              getIn(touched, `suggestions[${index}].task`) &&
                              getIn(errors, `suggestions[${index}].task`)
                            )
                          }
                          helperText={
                            getIn(touched, `suggestions[${index}].task`) &&
                            getIn(errors, `suggestions[${index}].task`)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          multiline
                          disabled={!!results}
                          rows={3}
                          label="Hazard"
                          fullWidth
                          value={suggest.hazard}
                          name={`suggestions[${index}].hazard`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            !!(
                              getIn(touched, `suggestions[${index}].hazard`) &&
                              getIn(errors, `suggestions[${index}].hazard`)
                            )
                          }
                          helperText={
                            getIn(touched, `suggestions[${index}].hazard`) &&
                            getIn(errors, `suggestions[${index}].hazard`)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          multiline
                          disabled={!!results}
                          rows={3}
                          label="Controls Suggested:"
                          fullWidth
                          value={suggest.controls}
                          name={`suggestions[${index}].controls`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            !!(
                              getIn(
                                touched,
                                `suggestions[${index}].controls`
                              ) &&
                              getIn(errors, `suggestions[${index}].controls`)
                            )
                          }
                          helperText={
                            getIn(touched, `suggestions[${index}].controls`) &&
                            getIn(errors, `suggestions[${index}].controls`)
                          }
                        />
                      </Grid>
                    </Grid>
                    <br />
                  </div>
                );
              })}
              <br />
              {!results && !preview ? (
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="outlined"
                  color="primary"
                >
                  Submit
                </Button>
              ) : (
                <Button variant="outlined" color="primary" onClick={onNext}>
                  Next
                </Button>
              )}
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

HazardSuggestions.defaultProps = {
  preview: false
};
