import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
  useHistory,
  useParams,
} from 'react-router-dom';
import { MicroLearningVideo } from './MicroLearningVideo';
// import { QuizQuestions } from './QuizQuestions';
import { MicroLearningQuiz } from './MicroLearningQuiz';
import { AlertContext } from 'App';
import { MultipleChoiceQuestionWithImage } from './MultipleChoiceQuestionWithImage';
import { HazardSuggestions } from './HazardSuggestions';
import { ExperienceRating } from './ExperienceRating';
import { FeedbackFour } from './FeedbackFour';
import { UserContext } from 'App';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { LoadingOrError } from 'components/LoadingOrError';
import {
  GetMicroLearningSessionAnswers,
  GetMicroLearningSessionAnswersVariables,
} from '__generated__/GetMicroLearningSessionAnswers';
import {
  CreateOneMicroLearningSessionAnswer,
  CreateOneMicroLearningSessionAnswerVariables,
} from '__generated__/CreateOneMicroLearningSessionAnswer';
import {
  UpdateOneMicroLearningSessionAnswer,
  UpdateOneMicroLearningSessionAnswerVariables,
} from '__generated__/UpdateOneMicroLearningSessionAnswer';

type SectionType = 'VIDEO' | 'SPOT_IDENTIFICATION' | 'QUIZ';

const SpotIdentification = React.lazy(() => import('./SpotIdentification'));

export const GET_SESSION_ANSWER = gql`
  query GetMicroLearningSessionAnswers(
    $uid: String!
    $session: String!
    $courseId: String!
  ) {
    microLearningSessionAnswers(
      where: {
        user: { id: { equals: $uid } }
        session: { id: { equals: $session } }
        course: { id: { equals: $courseId } }
      }
    ) {
      id
      createdAt
      data
    }
  }
`;

const CREATE_SESSION_ANSWER = gql`
  mutation CreateOneMicroLearningSessionAnswer(
    $data: String!
    $session: String!
    $company: String!
    $uid: String!
    $courseId: String!
  ) {
    createOneMicroLearningSessionAnswer(
      data: {
        data: $data
        session: { connect: { id: $session } }
        user: { connect: { id: $uid } }
        company: { connect: { id: $company } }
        course: { connect: { id: $courseId } }
      }
    ) {
      id
      createdAt
      data
    }
  }
`;

const UPDATE_SESSION_ANSWER = gql`
  mutation UpdateOneMicroLearningSessionAnswer($data: String!, $id: String!) {
    updateOneMicroLearningSessionAnswer(
      data: { data: $data }
      where: { id: $id }
    ) {
      id
      createdAt
      data
    }
  }
`;

export default function MLSessionLive({ config }: any) {
  const { user } = useContext(UserContext);
  const { id, course }: any = useParams();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const [index, setIndex] = useState(0);
  const { showAlert } = useContext(AlertContext);
  const { data, loading, error } = useQuery<
    GetMicroLearningSessionAnswers,
    GetMicroLearningSessionAnswersVariables
  >(GET_SESSION_ANSWER, {
    variables: {
      uid: user.id,
      session: id,
      courseId: course,
    },
  });
  const [createOneMicroLearningSessionAnswer] = useMutation<
    CreateOneMicroLearningSessionAnswer,
    CreateOneMicroLearningSessionAnswerVariables
  >(CREATE_SESSION_ANSWER);
  const [updateOneMicroLearningSessionAnswer] = useMutation<
    UpdateOneMicroLearningSessionAnswer,
    UpdateOneMicroLearningSessionAnswerVariables
  >(UPDATE_SESSION_ANSWER);

  useEffect(() => {
    if (index === 0 && pathname !== `${pathname}/${config.sections[0].id}`) {
      const location = path
        .replace(':id', id as string)
        .replace(':course', course);
      history.replace(`${location}/${config.sections[0].id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, id, index, path, pathname]);

  const createAnswer = useCallback(
    async (answer: any) => {
      return createOneMicroLearningSessionAnswer({
        variables: {
          company: user.company.id,
          data: JSON.stringify(answer),
          session: id as string,
          uid: user.id,
          courseId: course,
        },
        update: (cache, { data }) => {
          if (data && data.createOneMicroLearningSessionAnswer) {
            cache.writeQuery({
              query: GET_SESSION_ANSWER,
              variables: {
                uid: user.id,
                session: id as string,
                courseId: course,
              },
              data: {
                microLearningSessionAnswers: [
                  data.createOneMicroLearningSessionAnswer,
                ],
              },
            });
          }
        },
      });
    },
    [course, createOneMicroLearningSessionAnswer, id, user.company.id, user.id]
  );

  const upadateAnswer = useCallback(
    async (answer: any) => {
      if (!data || !data.microLearningSessionAnswers) {
        return;
      }
      return updateOneMicroLearningSessionAnswer({
        variables: {
          data: JSON.stringify(answer),
          id: data.microLearningSessionAnswers[0].id,
        },
        update: (cache, { data }) => {
          if (data && data.updateOneMicroLearningSessionAnswer) {
            cache.writeQuery({
              query: GET_SESSION_ANSWER,
              variables: {
                uid: user.id,
                session: id as string,
              },
              data: {
                microLearningSessionAnswers: [
                  data.updateOneMicroLearningSessionAnswer,
                ],
              },
            });
          }
        },
      });
    },
    [data, id, updateOneMicroLearningSessionAnswer, user.id]
  );

  const updateScreen = useCallback(
    (section: any) => {
      console.log(section, config.sections, index);
      if (index === config.sections.length - 1) {
        console.log('go ahead');
        showAlert(
          {
            message:
              'Thank you for completing this session successfully. We will see you next week with a new session.',
            type: 'Info',
          },
          () => history.push(`/micro-learning`)
        );
      } else {
        console.log('got someplace sels go ahead');
        history.push(
          pathname.replace(
            `/${section.id}`,
            `/${config.sections[index + 1].id}`
          )
        );
        setIndex(index + 1);
      }
    },
    [config.sections, history, index, pathname, showAlert]
  );

  const getSelection = (section: { type: SectionType; id: string }) => {
    if (!data || !data.microLearningSessionAnswers) {
      return null;
    }
    const answer = data.microLearningSessionAnswers[0];
    if (section.type === 'VIDEO') {
      return (
        <MicroLearningVideo
          sectionInfo={config.details[section.id]}
          onNext={() => {
            console.log('video ended going to section');
            updateScreen(section);
          }}
        />
      );
    } else if (section.type === 'QUIZ') {
      let results = null;
      let parsedData: any = null;
      if (answer) {
        parsedData = JSON.parse(answer.data);
        if (parsedData[section.id]) {
          results = parsedData[section.id];
        }
      }
      return (
        <MicroLearningQuiz
          onSubmit={async (answers: any, score: number, cb: any) => {
            const submission = {
              answers,
              score,
              email: user.email,
              name: user.userName,
              uid: user.id,
            };
            try {
              if (!answer || !parsedData) {
                await createAnswer({
                  [section.id]: submission,
                });
              } else {
                await upadateAnswer({
                  ...parsedData,
                  [section.id]: submission,
                });
              }
            } catch (err) {
              console.error(err);
            } finally {
              cb();
            }
          }}
          quiz={config.details[section.id].quiz}
          onNext={() => {
            updateScreen(section);
          }}
          results={results}
        />
      );
    } else if (section.type === 'SPOT_IDENTIFICATION') {
      let results = null;
      let parsedData: any = null;
      if (answer) {
        parsedData = JSON.parse(answer.data);
        if (parsedData[section.id]) {
          results = parsedData[section.id];
        }
      }
      const info = config.details[section.id];
      return (
        <SpotIdentification
          key={section.id}
          predefs={info.predefs}
          imgUrl={info.img}
          dims={info.dimensions}
          onNext={() => {
            updateScreen(section);
          }}
          exitIfFullscreen={info.exitIfFullscreen}
          showTestHelper={info.showTestHelper}
          onCaptures={async (captures, matches, score, elapsedTime, cb) => {
            const submission = {
              matches,
              score,
              email: user.email,
              name: user.userName,
              uid: user.id,
              time: elapsedTime,
              captures,
            };
            try {
              if (!answer || !parsedData) {
                await createAnswer({
                  [section.id]: submission,
                });
              } else {
                await upadateAnswer({
                  ...parsedData,
                  [section.id]: submission,
                });
              }
            } catch (err) {
              console.error(err);
            } finally {
              cb();
            }
          }}
          results={results && results.captures ? results.captures : []}
          title="Hazard Identification"
        />
      );
    } else if (section.type === 'IMG_QUIZ_ITEM') {
      let results = null;
      let parsedData: any = null;
      if (answer) {
        parsedData = JSON.parse(answer.data);
        if (parsedData[section.id]) {
          results = parsedData[section.id];
        }
      }
      const info = config.details[section.id];
      return (
        <MultipleChoiceQuestionWithImage
          imgUrl={info.image}
          question={info}
          onSubmit={async (answers, score, cb) => {
            const submission = {
              answers,
              score,
              email: user.email,
              name: user.userName,
              uid: user.id,
            };
            try {
              if (!answer || !parsedData) {
                await createAnswer({
                  [section.id]: submission,
                });
              } else {
                await upadateAnswer({
                  ...parsedData,
                  [section.id]: submission,
                });
              }
            } catch (err) {
              console.error(err);
            } finally {
              cb();
            }
          }}
          onNext={() => {
            updateScreen(section);
          }}
          results={results}
        />
      );
    } else if (section.type === 'HAZARD_SUGGESTION') {
      let results = null;
      let parsedData: any = null;
      if (answer) {
        parsedData = JSON.parse(answer.data);
        if (parsedData[section.id]) {
          results = parsedData[section.id];
        }
      }
      return (
        <HazardSuggestions
          onSubmit={async (answers, cb) => {
            const submission = {
              answers,
              email: user.email,
              name: user.userName,
              uid: user.id,
            };
            try {
              if (!answer || !parsedData) {
                await createAnswer({
                  [section.id]: submission,
                });
              } else {
                await upadateAnswer({
                  ...parsedData,
                  [section.id]: submission,
                });
              }
            } catch (err) {
              console.error(err);
            } finally {
              cb();
            }
          }}
          onNext={() => {
            updateScreen(section);
          }}
          results={results}
        />
      );
    } else if (section.type === 'EXPERIENCE_RATING') {
      let results = null;
      let parsedData: any = null;
      if (answer) {
        parsedData = JSON.parse(answer.data);
        if (parsedData[section.id]) {
          results = parsedData[section.id];
        }
      }
      return (
        <ExperienceRating
          onSubmit={async (answers, cb) => {
            const submission = {
              answers,
              email: user.email,
              name: user.userName,
              uid: user.id,
            };
            try {
              if (!answer || !parsedData) {
                await createAnswer({
                  [section.id]: submission,
                });
              } else {
                await upadateAnswer({
                  ...parsedData,
                  [section.id]: submission,
                });
              }
            } catch (err) {
              console.error(err);
            } finally {
              cb();
            }
          }}
          onNext={() => {
            updateScreen(section);
          }}
          results={results}
        />
      );
    } else if (section.type === 'FEEDBACK_FOUR') {
      let results = null;
      let parsedData: any = null;
      if (answer) {
        parsedData = JSON.parse(answer.data);
        if (parsedData[section.id]) {
          results = parsedData[section.id];
        }
      }
      return (
        <FeedbackFour
          onSubmit={async (answers, cb) => {
            const submission = {
              answers,
              email: user.email,
              name: user.userName,
              uid: user.id,
            };
            try {
              if (!answer || !parsedData) {
                await createAnswer({
                  [section.id]: submission,
                });
              } else {
                await upadateAnswer({
                  ...parsedData,
                  [section.id]: submission,
                });
              }
            } catch (err) {
              console.error(err);
            } finally {
              cb();
            }
          }}
          onNext={() => {
            updateScreen(section);
          }}
          results={results}
        />
      );
    }
    return null;
  };

  if (data && data.microLearningSessionAnswers) {
    return (
      <div>
        <Route exact path={path}>
          <Redirect to={`${pathname}/${config.sections[0].id}`} />
        </Route>
        {config.sections.map((section: any) => {
          return (
            <Route key={section.id} path={`${path}/${section.id}`}>
              {getSelection(section)}
            </Route>
          );
        })}
      </div>
    );
  }

  return <LoadingOrError {...{ loading, error }} />;
}
