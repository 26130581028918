import React from 'react';
import {
  Route,
  useRouteMatch,
  useParams,
  Link as RouterLink,
  useLocation
} from 'react-router-dom';
import { MLCourse } from './MLCourse';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  MicroLearningCourses,
  MicroLearningCoursesVariables
} from '__generated__/MicroLearningCourses';
import {
  CreateOneMicroLearningCourse,
  CreateOneMicroLearningCourseVariables
} from '__generated__/CreateOneMicroLearningCourse';
import { LoadingOrError } from 'components/LoadingOrError';
import { TextField, Button, Link } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { DatePicker } from '@material-ui/pickers';

const CREATE_ML_COURSE = gql`
  mutation CreateOneMicroLearningCourse(
    $name: String!
    $launchesOn: DateTime!
    $company: String!
  ) {
    createOneMicroLearningCourse(
      data: {
        name: $name
        launchesOn: $launchesOn
        company: { connect: { id: $company } }
      }
    ) {
      id
      name
      launchesOn
    }
  }
`;

const COMPANY_COURSES = gql`
  query MicroLearningCourses($company: String!) {
    microLearningCourses(
      where: { company: { id: { equals: $company } } }
      orderBy: { name: asc }
    ) {
      id
      name
      launchesOn
    }
  }
`;

function CourseList() {
  const { cid } = useParams();
  const { pathname } = useLocation();
  const { data, loading, error } = useQuery<
    MicroLearningCourses,
    MicroLearningCoursesVariables
  >(COMPANY_COURSES, {
    variables: {
      company: cid as string
    }
  });
  const [createOneMicroLearningCourse] = useMutation<
    CreateOneMicroLearningCourse,
    CreateOneMicroLearningCourseVariables
  >(CREATE_ML_COURSE);
  if (data && data.microLearningCourses) {
    return (
      <div style={{ padding: 16 }}>
        <Formik
          initialValues={{ name: '', launchesOn: new Date() }}
          validationSchema={() =>
            Yup.object().shape({
              name: Yup.string()
                .label('Name')
                .min(5)
                .required(),
              launchesOn: Yup.date().required()
            })
          }
          onSubmit={async (values, actions) => {
            await createOneMicroLearningCourse({
              variables: { ...values, company: cid as string },
              update: (cache, { data }) => {
                if (data && data.createOneMicroLearningCourse) {
                  const { microLearningCourses } = cache.readQuery({
                    query: COMPANY_COURSES,
                    variables: {
                      company: cid as string
                    }
                  }) as any;

                  cache.writeQuery({
                    query: COMPANY_COURSES,
                    variables: {
                      company: cid as string
                    },
                    data: {
                      microLearningCourses: [
                        ...microLearningCourses,
                        data.createOneMicroLearningCourse
                      ]
                    }
                  });
                }
              }
            });
            actions.resetForm();
          }}
        >
          {formikProps => (
            <form
              onSubmit={formikProps.handleSubmit}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextField
                size="small"
                variant="outlined"
                label="Course name"
                fullWidth
                value={formikProps.values.name}
                name="name"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={formikProps.touched.name && !!formikProps.errors.name}
                helperText={formikProps.touched.name && formikProps.errors.name}
              />
              &nbsp;&nbsp;
              <DatePicker
                disablePast
                label="Launches on"
                value={formikProps.values.launchesOn}
                onChange={(date: any) =>
                  formikProps.setFieldValue('launchesOn', date)
                }
                animateYearScrolling
              />
              &nbsp;&nbsp;
              <Button variant="contained" color="primary" type="submit">
                Add
              </Button>
            </form>
          )}
        </Formik>
        <ul>
          {data.microLearningCourses.map(item => {
            return (
              <li key={item.id}>
                <Link to={`${pathname}/${item.id}`} component={RouterLink}>
                  {item.name} | {format(parseISO(item.launchesOn), 'dd-MMM-yy')}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  return <LoadingOrError {...{ loading, error }} />;
}

export function MLCourseList() {
  const { path } = useRouteMatch();
  return (
    <div>
      <Route exact path={path}>
        <CourseList />
      </Route>
      <Route path={`${path}/:course`}>
        <MLCourse />
      </Route>
    </div>
  );
}
