import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GetMicroLearningSessions } from '__generated__/GetMicroLearningSessions';
import { LoadingOrError } from 'components/LoadingOrError';
import { TextField, Button, IconButton, Link } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import EditIcon from '@material-ui/icons/Edit';
import {
  CreateOneMicroLearningSession,
  CreateOneMicroLearningSessionVariables
} from '__generated__/CreateOneMicroLearningSession';
import {
  UpdateOneMicroLearningSession,
  UpdateOneMicroLearningSessionVariables
} from '__generated__/UpdateOneMicroLearningSession';
import { useRouteMatch, Link as RouterLink } from 'react-router-dom';

const SESSIONS_QUERY = gql`
  query GetMicroLearningSessions {
    microLearningSessions {
      id
      name
    }
  }
`;

const CREATE_SESSION_MUTATION = gql`
  mutation CreateOneMicroLearningSession($name: String!, $data: String!) {
    createOneMicroLearningSession(data: { name: $name, data: $data }) {
      id
      name
    }
  }
`;

const UPDATE_SESSION_MUTATION = gql`
  mutation UpdateOneMicroLearningSession($name: String!, $id: String!) {
    updateOneMicroLearningSession(data: { name: $name }, where: { id: $id }) {
      id
      name
    }
  }
`;

function SessionName({ name, id }: { name: string; id: string }) {
  const { path } = useRouteMatch();
  const [showEdit, setShowEdit] = useState(false);
  const [updateOneMicroLearningSession] = useMutation<
    UpdateOneMicroLearningSession,
    UpdateOneMicroLearningSessionVariables
  >(UPDATE_SESSION_MUTATION);
  const [value, setValue] = useState(name);

  const handleChange = async (e: any) => {
    setValue(e.target.value);
  };

  const handleKeyPress = async (e: any) => {
    if (e.key === 'Enter') {
      await updateOneMicroLearningSession({
        variables: { name: e.target.value, id },
        update: (cache, { data }) => {
          if (data && data.updateOneMicroLearningSession) {
            const { microLearningSessions } = cache.readQuery({
              query: SESSIONS_QUERY
            }) as any;

            cache.writeQuery({
              query: SESSIONS_QUERY,
              data: {
                microLearningSessions: microLearningSessions.map(
                  (item: any) => {
                    if (item.id === id) {
                      return data.updateOneMicroLearningSession;
                    }
                    return item;
                  }
                )
              }
            });
          }
        }
      });
      setShowEdit(false);
    }
  };

  return showEdit ? (
    <TextField
      value={value}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
    />
  ) : (
    <div>
      <Link to={`${path}/${id}`} component={RouterLink}>
        {name}
      </Link>
      &nbsp;&nbsp;
      <IconButton size="small" onClick={() => setShowEdit(true)}>
        <EditIcon />
      </IconButton>
    </div>
  );
}

export function MicrolearningSessions() {
  const { data, loading, error } = useQuery<GetMicroLearningSessions>(
    SESSIONS_QUERY
  );
  const [createOneMicroLearningSession] = useMutation<
    CreateOneMicroLearningSession,
    CreateOneMicroLearningSessionVariables
  >(CREATE_SESSION_MUTATION);

  if (data && data.microLearningSessions) {
    return (
      <div style={{ padding: 16 }}>
        <Formik
          initialValues={{ name: '' }}
          validationSchema={() =>
            Yup.object().shape({
              name: Yup.string()
                .label('Name')
                .min(5)
                .required()
            })
          }
          onSubmit={async ({ name }, actions) => {
            await createOneMicroLearningSession({
              variables: {
                name,
                data: JSON.stringify({
                  title: 'Session ****',
                  sections: [
                    {
                      id: 's1',
                      type: 'VIDEO'
                    }
                  ],
                  details: {
                    s1: {
                      bucket: 'demo-stuff',
                      file: 'Introduction_HIRA_R1.mp4'
                    }
                  }
                })
              },
              update: (cache, { data }) => {
                if (data && data.createOneMicroLearningSession) {
                  const { microLearningSessions } = cache.readQuery({
                    query: SESSIONS_QUERY
                  }) as any;

                  cache.writeQuery({
                    query: SESSIONS_QUERY,
                    data: {
                      microLearningSessions: [
                        ...microLearningSessions,
                        data.createOneMicroLearningSession
                      ]
                    }
                  });
                }
              }
            });
            actions.resetForm();
          }}
        >
          {formikProps => (
            <form
              onSubmit={formikProps.handleSubmit}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextField
                size="small"
                variant="outlined"
                label="New session"
                fullWidth
                value={formikProps.values.name}
                name="name"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={formikProps.touched.name && !!formikProps.errors.name}
                helperText={formikProps.touched.name && formikProps.errors.name}
              />
              &nbsp;&nbsp;
              <Button variant="contained" color="primary" type="submit">
                Add
              </Button>
            </form>
          )}
        </Formik>
        <ul>
          {data.microLearningSessions.map(session => {
            return (
              <li key={session.id}>
                <SessionName name={session.name} id={session.id} />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return <LoadingOrError {...{ loading, error }} />;
}
