import React from 'react';
import { Typography, Button } from '@material-ui/core';
import firebase from 'firebase/app';
import { useHistory, useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';

export function NotRegistered() {
  const history = useHistory();
  let location: any = useLocation();
  let { from } = location.state || { from: { pathname: '/' } };
  const client = useApolloClient();

  return (
    <div style={{ padding: 32 }}>
      <Typography variant="h5">
        We could not find a registered Employee entry for your login.
      </Typography>
      <br />
      <Typography>Want to try with another email/phone number?</Typography>
      <br />
      <Button
        onClick={() => {
          client.cache.reset();
          firebase.auth().signOut();
          history.push('/auth/firebase-login', { from });
        }}
        variant="outlined"
      >
        Sign Out
      </Button>
    </div>
  );
}
