import React from 'react';
import {
  Route,
  useRouteMatch,
  Link as RouterLink,
  useLocation
} from 'react-router-dom';
import { Link } from '@material-ui/core';
import { SafetyEyeConfiguration } from './SafetyEyeConfiguration';
import { SafetyEyeData } from './SafetyEyeData';

export function SafetyEye() {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  return (
    <div>
      <Route exact path={path}>
        <ul>
          <li>
            <Link to={`${pathname}/configuration`} component={RouterLink}>
              Configuration
            </Link>
          </li>
          <li>
            <Link to={`${pathname}/data`} component={RouterLink}>
              Data
            </Link>
          </li>
        </ul>
      </Route>
      <Route path={`${path}/configuration`}>
        <SafetyEyeConfiguration />
      </Route>
      <Route path={`${path}/data`}>
        <SafetyEyeData />
      </Route>
    </div>
  );
}
