import React, { useContext } from 'react';
import { UserContext } from '../App';
import { useQuery } from '@apollo/react-hooks';
import { Quizzes, QuizzesVariables } from '__generated__/Quizzes';
import { LoadingOrError } from './LoadingOrError';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { QUIZES_QUERY } from './company-admin/Quiz';

export function UserQuiz() {
  const { user } = useContext(UserContext);
  const { loading, error, data } = useQuery<Quizzes, QuizzesVariables>(
    QUIZES_QUERY,
    {
      variables: {
        id: user.company.id,
      },
    }
  );
  if (data && data.quizzes) {
    return (
      <div style={{ padding: 16 }}>
        <ul>
          {data.quizzes.map((quiz) => {
            return (
              <li key={quiz.id}>
                <Link to={`/quiz/${quiz.id}`} component={RouterLink}>
                  {quiz.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return <LoadingOrError {...{ error, loading }} />;
}
