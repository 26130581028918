import React, { useState } from 'react';
import { useBrowserSupport } from 'hooks/useBrowserSupport';
import { UseLatestBrowser } from 'components/UseLatestBrowser';
import { Route, Switch, Redirect } from 'react-router-dom';
import { NotFound404 } from 'components/NotFound404';
import { SuperAdmin } from 'components/super-admin/SuperAdmin';
import { PrivateRoute } from 'components/PrivateRoute';
import SideNavWrapper from 'components/WithSideNavWrapper';
import { GCBucketItemList } from 'components/GCBucketItemList';
import { GCBucketItemListUsers } from 'components/GCBucketItemListUsers';
import { GCBucketVideoPreview } from 'components/GCBucketVideoPreview';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import MessageBox from 'components/MessageBox';
import { UserVerification } from 'components/UserVerification';
import { DemoFeedbackApp } from 'components/demos/DemoFeedbackApp';
import { DemoGoogleCustomPanorama } from 'components/demos/DemoGoogleCustomPanorama';
import { Profile } from 'components/ProfilePage';
import { CompanyAdmin } from 'components/company-admin/CompanyAdmin';
import { ParallelLearning } from 'components/parallel-learning/ParallelLearning';
import { MicroLearningUser } from 'components/micro-learning/MicroLearningUser';
import { MLSessionView } from 'components/micro-learning/MLSessionView';
import { RoadSafety2020 } from 'products/RoadSafety2020';
import { PanResponderDemo } from 'components/demos/PanResponderDemo';
import { PrivacyPolicy } from 'components/PrivacyPolicy';
import { UserQuiz } from 'components/UserQuiz';
import { TakeUserQuiz } from 'components/TakeUserQuiz';
import Schindler from 'components/Schindler';
import Bekaert from 'components/Bekaert';
import { JCB } from 'products/jcb/JCB';
import Oerlikon from 'components/Oerlikon';
import Shubhlife from 'components/Shubhlife';
import Sigma from 'components/Sigma';
import { PhoneAuth } from 'components/PhoneAuth';
import { Me_me } from '__generated__/Me';
import { TestNewAuth } from 'components/TestNewAuth';
import { SafetyWeek2020 } from 'components/safety-week-2020/SafetyWeek2020';
import Auth from 'components/Auth';
import { NotRegistered } from 'components/NotRegistered';
import { NotRegisteredBekaertRanjangaon } from 'components/Bekaert/NotRegisteredBekaertRanjangaon';
import { ScromTest } from 'components/pocs/ScromTest';
import ScromManagement from 'components/ScromManagement';

type AlertType = {
  message: string;
  type: 'Error' | 'Warning' | 'Success' | 'Info';
} | null;

type alertOptionsType = { showCancel: boolean } | null;
type AppStateType = {
  alert: AlertType;
  notification: string | null;
  notificationCallback: any;
  alertCallback: any;
  alertOptions: alertOptionsType;
};

export const AlertContext = React.createContext({
  showAlert: (
    _alert: AlertType,
    _alertCallback?: any,
    _options?: alertOptionsType
  ) => {},
});

export const UserContext = React.createContext<{
  user: Me_me;
  setUser: (user: Me_me) => void;
}>({
  user: null as any,
  setUser: () => {},
});

const App: React.FC = () => {
  const [user, setUser] = useState<Me_me>(null as any);
  const browserSupported = useBrowserSupport();
  const [alertState, setAlertState] = useState<AppStateType>({
    alert: null,
    alertCallback: null,
    alertOptions: null,
    notification: null,
    notificationCallback: null,
  });
  // const [sessSynced, setSessSynced] = useState(false);
  // const { pathname } = useLocation();
  // const history = useHistory();

  // const sessionStorage_transfer = useCallback(
  //   (event: any) => {
  //     if (!event) {
  //       event = window.event;
  //     } // ie suq
  //     if (event.key === 'getSessionStorage') {
  //       // another tab asked for the sessionStorage -> send it
  //       localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
  //       // the other tab should now have it, so we're done with it.
  //       localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
  //     } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
  //       // another tab sent data <- get it
  //       var data = JSON.parse(event.newValue);
  //       for (var key in data) {
  //         sessionStorage.setItem(key, data[key]);
  //       }
  //     } else if (event.key === 'logout') {
  //       sessionStorage.removeItem('token');
  //       if (pathname !== '/login') {
  //         history.replace('/login');
  //       }
  //     }
  //   },
  //   [history, pathname]
  // );

  // useEffect(() => {
  //   if (!sessionStorage.getItem('token')) {
  //     localStorage.setItem('getSessionStorage', 'foobar');
  //     localStorage.removeItem('getSessionStorage');
  //     setTimeout(() => {
  //       setSessSynced(true);
  //     }, 100);
  //   } else {
  //     setSessSynced(true);
  //   }
  //   window.addEventListener('storage', sessionStorage_transfer);
  //   return () => {
  //     window.removeEventListener('storage', sessionStorage_transfer);
  //   };
  // }, [sessionStorage_transfer]);

  if (!browserSupported) {
    return <UseLatestBrowser />;
  }

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <AlertContext.Provider
        value={{
          showAlert: (alert, alertCallback, options) => {
            setAlertState({
              ...alertState,
              alert,
              alertCallback: alertCallback ? alertCallback : null,
              alertOptions: options ? options : null,
            });
          },
        }}
      >
        <div>
          <Dialog
            open={!!alertState.alert}
            onClose={() => setAlertState({ ...alertState, alert: null })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">System Message</DialogTitle>
            <DialogContent>
              {alertState.alert && (
                <MessageBox type={alertState.alert.type}>
                  {alertState.alert.message}
                </MessageBox>
              )}
            </DialogContent>
            <DialogActions>
              {alertState.alertOptions && alertState.alertOptions.showCancel && (
                <Button
                  onClick={() => {
                    setAlertState({
                      ...alertState,
                      alert: null,
                      alertCallback: null,
                    });
                  }}
                  color="default"
                >
                  Cancel
                </Button>
              )}
              <Button
                onClick={() => {
                  if (alertState.alertCallback) {
                    (alertState.alertCallback as any)();
                  }
                  setAlertState({
                    ...alertState,
                    alert: null,
                    alertCallback: null,
                  });
                }}
                color="primary"
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Switch>
            <PrivateRoute exact path="/">
              <SideNavWrapper></SideNavWrapper>
            </PrivateRoute>
            <PrivateRoute exact path="/test-new-auth">
              <TestNewAuth />
            </PrivateRoute>
            <Route path="/not-registered">
              <NotRegistered />
            </Route>
            <Route path="/not-registered-bekaert-ranjangaon">
              <NotRegisteredBekaertRanjangaon />
            </Route>
            <Route path="/login">
              <Redirect to="/auth/firebase-login" />
            </Route>
            <Route path="/auth">
              <Auth />
            </Route>
            <Route path="/phone-auth">
              <PhoneAuth />
            </Route>
            <PrivateRoute path="/super-admin___" groups={['super_admins']}>
              <SideNavWrapper>
                <SuperAdmin />
              </SideNavWrapper>
            </PrivateRoute>
            <PrivateRoute path="/super-admin" groups={['super_admins']}>
              <SideNavWrapper>
                <SuperAdmin />
              </SideNavWrapper>
            </PrivateRoute>
            <PrivateRoute exact path="/micro-learning">
              <SideNavWrapper>
                <MicroLearningUser />
              </SideNavWrapper>
            </PrivateRoute>
            <PrivateRoute exact path="/quiz">
              <SideNavWrapper>
                <UserQuiz />
              </SideNavWrapper>
            </PrivateRoute>
            <PrivateRoute exact path="/quiz/:qid">
              <SideNavWrapper>
                <TakeUserQuiz />
              </SideNavWrapper>
            </PrivateRoute>
            <PrivateRoute path="/micro-learning/:course/:id">
              <MLSessionView />
            </PrivateRoute>
            <PrivateRoute path="/profile">
              <SideNavWrapper>
                <Profile />
              </SideNavWrapper>
            </PrivateRoute>
            <PrivateRoute path="/company-admin">
              <SideNavWrapper>
                <CompanyAdmin />
              </SideNavWrapper>
            </PrivateRoute>
            <Route path="/parallel-learning">
              <ParallelLearning />
            </Route>
            <Route exact path="/user-verification/:token">
              <UserVerification />
            </Route>
            <PrivateRoute exact path="/gcloud/:gcbucket">
              <GCBucketItemList />
            </PrivateRoute>
            <PrivateRoute exact path="/gcloud/:gcbucket/users">
              <GCBucketItemListUsers />
            </PrivateRoute>
            <PrivateRoute exact path="/gcloud/:gcbucket/preview">
              <GCBucketVideoPreview />
            </PrivateRoute>
            <PrivateRoute path="/scorm-management">
              <ScromManagement />
            </PrivateRoute>
            <Route path="/demos/feedback-app">
              <DemoFeedbackApp />
            </Route>
            <Route path="/pan-responder">
              <PanResponderDemo />
            </Route>
            <Route path="/demos/google-custom-panorama">
              <DemoGoogleCustomPanorama />
            </Route>
            <Route path="/road-safety-2020">
              <RoadSafety2020 />
            </Route>
            <Route path="/Schindler">
              <Schindler />
            </Route>
            <Route path="/bekaert">
              <Bekaert />
            </Route>
            <Route path="/schindler">
              <Schindler />
            </Route>
            <Route path="/oerlikon">
              <Oerlikon />
            </Route>
            <Route path="/Shubhlife">
              <Shubhlife />
            </Route>
            <Route path="/sigma">
              <Sigma />
            </Route>
            <Route path="/safety-week-2020">
              <SafetyWeek2020 />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/jcb">
              <JCB />
            </Route>
            <Route path="/scrom-test">
              <ScromTest />
            </Route>
            <Route path="*">
              <NotFound404 />
            </Route>
          </Switch>
        </div>
      </AlertContext.Provider>
    </UserContext.Provider>
  );
};

export default App;
