import React, { useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from '@material-ui/core';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { DatePicker } from '@material-ui/pickers';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { parseISO, differenceInDays, isToday } from 'date-fns';
import { Licenses } from '__generated__/Licenses';
import { CreateOneUser } from '__generated__/CreateOneUser';

export const COMPANY_USER_FRAGMENT = gql`
  fragment CompanyUserFragment on User {
    id
    email
    userName
    firstName
    lastName
    deleted
    blocked
    createdAt
    license {
      id
      expires
      createdAt
    }
  }
`;

export const COMPANY_USERS_QUERY = gql`
  query companyUsers($companyId: String!) {
    users(
      where: { company: { id: { equals: $companyId } } }
      orderBy: { email: asc }
    ) {
      ...CompanyUserFragment
    }
  }
  ${COMPANY_USER_FRAGMENT}
`;

export const LICENSES_QUERY = gql`
  query Licenses($companyId: String!, $expires: DateTime!) {
    licenses(
      where: {
        company: { id: { equals: $companyId } }
        expires: { gt: $expires }
        user: null
      }
    ) {
      id
      expires
    }
  }
`;

const CREATE_USER = gql`
  mutation CreateOneUser(
    $userName: String!
    $contactNumber: String
    $email: String!
    $companyId: ID!
    $license: ID
    $birthDate: DateTime
  ) {
    createOneUser(
      userName: $userName
      contactNumber: $contactNumber
      email: $email
      companyId: $companyId
      licenseId: $license
      birthDate: $birthDate
    ) {
      ...CompanyUserFragment
    }
  }
  ${COMPANY_USER_FRAGMENT}
`;

function CreateNewUserComponent({
  setShowCreateNewUserModal,
  companyId
}: {
  setShowCreateNewUserModal: any;
  companyId: string;
}) {
  const now = useRef(new Date());
  const { data } = useQuery<Licenses>(LICENSES_QUERY, {
    variables: {
      companyId,
      expires: now.current
    }
  });
  const [createOneUser] = useMutation<CreateOneUser>(CREATE_USER);
  return (
    <div>
      <Formik
        initialValues={{
          email: '',
          userName: '',
          contactNumber: '',
          firstName: '',
          lastName: '',
          birthDate: new Date(),
          license: ''
        }}
        validationSchema={() =>
          object().shape({
            userName: string()
              .label('User Name')
              .required()
              .min(4),
            email: string()
              .email()
              .label('Email')
              .required()
          })
        }
        onSubmit={async (
          { birthDate, ...others },
          { resetForm, setStatus }
        ) => {
          try {
            await createOneUser({
              variables: {
                ...others,
                companyId,
                ...(!isToday(birthDate) && { birthDate })
              },
              refetchQueries: [
                {
                  query: COMPANY_USERS_QUERY,
                  variables: {
                    companyId
                  }
                },
                {
                  query: LICENSES_QUERY,
                  variables: {
                    companyId,
                    expires: now.current
                  }
                }
              ]
            });

            setStatus({
              message: { success: true, text: 'User added successfully.' }
            });
          } catch {
            setStatus({
              message: {
                success: false,
                text: 'Seems like user with this email id already exists.'
              }
            });
          }
        }}
      >
        {props => (
          <form onSubmit={props.handleSubmit}>
            <Dialog
              maxWidth={'xl'}
              fullWidth={true}
              open={true}
              onClose={() => setShowCreateNewUserModal(false)}
              aria-labelledby="form-dialog-title"
              disableBackdropClick
            >
              <DialogTitle id="form-dialog-title">New User</DialogTitle>
              <DialogContent>
                <div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        autoFocus
                        value={props.values.userName}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        margin="dense"
                        name="userName"
                        label="User Name"
                        type="text"
                        fullWidth
                        required
                        error={!!props.errors.userName}
                        helperText={
                          props.touched.userName && props.errors.userName
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        value={props.values.firstName}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        margin="dense"
                        name="firstName"
                        label="First Name"
                        fullWidth
                        error={!!props.errors.firstName}
                        helperText={
                          props.touched.firstName && props.errors.firstName
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        value={props.values.lastName}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        margin="dense"
                        name="lastName"
                        label="Last Name"
                        fullWidth
                        error={!!props.errors.lastName}
                        helperText={
                          props.touched.lastName && props.errors.lastName
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        value={props.values.email}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        margin="dense"
                        name="email"
                        label="Email"
                        type="email"
                        fullWidth
                        required
                        error={!!props.errors.email}
                        helperText={props.touched.email && props.errors.email}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        value={props.values.contactNumber}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        margin="dense"
                        name="contactNumber"
                        label="Contact Number"
                        fullWidth
                        error={!!props.errors.contactNumber}
                        helperText={
                          props.touched.contactNumber &&
                          props.errors.contactNumber
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <DatePicker
                        disableFuture
                        label="Birth Date"
                        format="dd/MM/yyyy"
                        value={props.values.birthDate}
                        onChange={date =>
                          props.setFieldValue('birthDate', date)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      {data && data.licenses && (
                        <FormControl>
                          <InputLabel id="demo-simple-select-helper-label">
                            License
                          </InputLabel>
                          <Select
                            style={{ minWidth: 120 }}
                            name="license"
                            labelId="demo-simple-select-helper-label"
                            id="license"
                            value={props.values.license}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {data.licenses.map(license => (
                              <MenuItem key={license.id} value={license.id}>
                                <Typography
                                  style={{ fontWeight: 'bold' }}
                                  variant="body2"
                                >
                                  {license.id}
                                </Typography>
                                <Typography variant="caption">
                                  &nbsp;Expires in:&nbsp;
                                  {differenceInDays(
                                    parseISO(license.expires),
                                    new Date()
                                  )}
                                  &nbsp;Days
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                  {props.status && props.status.message && (
                    <Typography
                      variant="caption"
                      style={{
                        color: props.status.message.success
                          ? '#107c10'
                          : '#a80000',
                        marginTop: 12,
                        display: 'block'
                      }}
                    >
                      {props.status.message.text}
                    </Typography>
                  )}
                  <br />
                  <br />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setShowCreateNewUserModal(false)}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    props.submitForm();
                  }}
                  color="primary"
                  variant="contained"
                >
                  Add User
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )}
      </Formik>
    </div>
  );
}

export const CreateNewUser = React.memo(CreateNewUserComponent);
