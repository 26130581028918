import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useWindowSize } from 'hooks/useWindowSize';
import { useParams, useLocation } from 'react-router-dom';
import { VideoPlayer } from './VideoPlayer';
import queryString from 'query-string';
import { API_URL } from 'index';

export function GCBucketVideoPreview() {
  const params: any = useParams();
  const location = useLocation();
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const { height, width } = useWindowSize();
  const key = queryString.parse(location.search).key;

  useEffect(() => {
    fetch(`${API_URL}/signed-url?bucket=${params.gcbucket}&fileKey=${key}`)
      .then((res: any) => res.json())
      .then(res => {
        setUrl(res.url);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [key, location.search, params.gcbucket, params.key]);

  if (loading) {
    return <Typography>Fetching video...</Typography>;
  }

  if (url && !loading) {
    return (
      <VideoPlayer url={url} onEnded={() => {}} height={height} width={width} />
    );
  }

  return <Typography>Video not found!!!</Typography>;
}
