import React, { useRef, useState, useCallback } from 'react';
import { PanResponder, Animated, Platform } from 'react-native';

function DraggableComponent({
  imgHeight,
  imgWidth,
  onFinalPosition,
  diameter,
  isLast,
  initOffsetX,
  index
}: {
  diameter: number;
  initOffsetX: number;
  imgHeight: number;
  imgWidth: number;
  onFinalPosition: (x: number, y: number, i: number) => void;
  isLast: boolean;
  index: number;
}) {
  const _previousLeft = useRef(0);
  const _previousTop = useRef(0);
  const [location, setLocation] = useState({ left: 0, top: 0 });
  const _handleStartShouldSetPanResponder = useCallback(
    (event: any, gestureState: any) => {
      return true;
    },
    []
  );
  const _handleMoveShouldSetPanResponder = useCallback(
    (event: any, gestureState: any) => {
      return true;
    },
    []
  );
  const _handlePanResponderGrant = useCallback(
    (event: any, gestureState: any) => {},
    []
  );
  const _handlePanResponderMove = useCallback(
    (event: any, gestureState: any) => {
      setLocation({
        left: _previousLeft.current + gestureState.dx,
        top: _previousTop.current + gestureState.dy
      });
    },
    []
  );
  const _handlePanResponderEnd = useCallback(
    (event: any, gestureState: any) => {
      _previousLeft.current += gestureState.dx;
      _previousTop.current += gestureState.dy;
      if (
        _previousLeft.current > imgWidth ||
        _previousLeft.current < 0 ||
        _previousTop.current < -imgHeight / 2 ||
        _previousTop.current > imgHeight / 2
      ) {
        return;
      }
      const setY = _previousTop.current / imgHeight;
      const setX = _previousLeft.current / imgWidth;
      onFinalPosition(setX - diameter / 2 / imgWidth, 1 / 2 + setY, index);
    },
    [diameter, imgHeight, imgWidth, index, onFinalPosition]
  );

  const _panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: _handleStartShouldSetPanResponder,
      onMoveShouldSetPanResponder: _handleMoveShouldSetPanResponder,
      onPanResponderGrant: _handlePanResponderGrant,
      onPanResponderMove: _handlePanResponderMove,
      onPanResponderRelease: _handlePanResponderEnd,
      onPanResponderTerminate: _handlePanResponderEnd
    })
  );

  return (
    <Animated.View
      {..._panResponder.current.panHandlers}
      style={{
        zIndex: 5,
        height: diameter - 1,
        width: diameter - 1,
        ...(Platform.OS === 'web' && { cursor: 'grab' }),
        backgroundColor:
          isLast ||
          location.left > diameter / imgWidth ||
          Math.abs(location.top) > diameter / imgHeight
            ? 'rgba(0,0,0,0.4)'
            : 'transparent',
        borderRadius: 50,
        position: 'absolute',
        top: imgHeight / 2 - diameter / 2,
        borderColor: 'red',
        borderWidth: 1,
        left: -diameter / 2,
        transform: [
          {
            translateX: location.left
          },
          {
            translateY: location.top
          }
        ]
      }}
    />
  );
}

export const Draggable = React.memo(DraggableComponent);
