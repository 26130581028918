import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { Users } from 'components/company-admin/Users';
import { MLCourseList } from './MLCourseList';
import { SafetyEye } from './SafetyEye';
import { Quiz } from './Quiz';

export function CompanyAdmin() {
  const { path } = useRouteMatch();
  return (
    <div>
      <Route exact path={`${path}/:cid/users`}>
        <Users />
      </Route>
      <Route path={`${path}/:cid/micro-learning`}>
        <MLCourseList />
      </Route>
      <Route path={`${path}/:cid/safety-eye`}>
        <SafetyEye />
      </Route>
      <Route path={`${path}/:cid/quiz`}>
        <Quiz />
      </Route>
    </div>
  );
}
