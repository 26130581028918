import React, { useState, useEffect } from 'react';
import { API_URL } from 'index';
import { VideoPlayer } from 'components/VideoPlayer';
import { useWindowSize } from 'hooks/useWindowSize';

export function MicroLearningVideo({ onNext, sectionInfo }: any) {
  const [videoUrl, setVideoUrl] = useState('');
  const { height, width } = useWindowSize();

  useEffect(() => {
    fetch(
      `${API_URL}/signed-url?bucket=${sectionInfo.bucket}&fileKey=${sectionInfo.file}`
    )
      .then((res: any) => res.json())
      .then(res => {
        return setVideoUrl(res.url);
      });
  }, [sectionInfo.bucket, sectionInfo.file]);

  if (!videoUrl) {
    return <div>Loading Video...</div>;
  }
  return (
    <VideoPlayer
      url={videoUrl}
      onEnded={() => onNext()}
      {...{ height, width }}
    />
  );
}
