import React, { useState, useEffect } from 'react';
import { Typography, Link } from '@material-ui/core';
import { useWindowSize } from 'hooks/useWindowSize';
import Lottie from 'react-lottie';
import { Link as RouterLink } from 'react-router-dom';

export function NotFound404() {
  const { width, height } = useWindowSize();
  const [animationData, setAnimationData] = useState<any>(false);

  useEffect(() => {
    fetch('/json-data/4958-404-not-found.json')
      .then((res) => res.json())
      .then((data) => setAnimationData(data));
  }, []);

  return (
    <div
      style={{
        padding: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height,
        width,
        flexDirection: 'column',
      }}
    >
      <Typography>
        Sorry we couldn't find what you were looking for...
      </Typography>
      <Link to="/" component={RouterLink}>
        Back to Home
      </Link>
      {animationData && (
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={200}
          width={200}
        />
      )}
    </div>
  );
}
