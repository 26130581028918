import React from 'react';
import { Typography } from '@material-ui/core';

export function UseLatestBrowser() {
  return (
    <div style={{ padding: 32 }}>
      <Typography variant="h1">
        Your browser is not supported. Please download the latest browser to use
        this application.
      </Typography>
    </div>
  );
}
