import React from 'react';
import { Typography, Button, Box } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Rating from '@material-ui/lab/Rating';

export function ExperienceRating({
  onSubmit,
  onNext,
  results,
  preview
}: {
  onSubmit: (answers: any, cb: any) => void;
  onNext: () => void;
  results: any;
  preview?: boolean;
}) {
  return (
    <div style={{ padding: 16 }}>
      <Typography variant="h4">Part E:</Typography>
      <br />
      <Typography>
        Your experience sharing matters to us in order to provide a better
        learning experience
      </Typography>
      <br />
      <Formik
        initialValues={
          results && results.answers
            ? results.answers
            : {
                '1': null,
                '2': null,
                '3': null,
                '4': null
              }
        }
        validationSchema={Yup.object().shape({
          1: Yup.number()
            .typeError('Answer is required')
            .required('Answer is required'),
          2: Yup.number()
            .typeError('Answer is required')
            .label('Answer is required')
            .required('Answer is required'),
          3: Yup.number()
            .typeError('Answer is required')
            .label('Answer is required')
            .required('Answer is required'),
          4: Yup.number()
            .typeError('Answer is required')
            .label('Answer is required')
            .required('Answer is required')
        })}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, () => {
            setSubmitting(false);
          });
        }}
      >
        {({
          handleChange,
          values,
          touched,
          errors,
          handleSubmit,
          isSubmitting
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box component="fieldset" mb={3} borderColor="primary">
                <Typography component="legend">
                  1. How you will rate content of the program
                </Typography>
                <Rating
                  disabled={!!results}
                  name="1"
                  value={parseInt(values[1])}
                  max={10}
                  onChange={(event, newValue) => {
                    handleChange('1')('' + newValue);
                  }}
                />
                <br />
                {touched[1] && errors[1] && (
                  <Typography variant="caption" color="error">
                    {errors[1]}
                  </Typography>
                )}
              </Box>
              <Box component="fieldset" mb={3} borderColor="primary">
                <Typography component="legend">
                  2. How you will rate the learning experience?
                </Typography>
                <Rating
                  disabled={!!results}
                  name="2"
                  value={parseInt(values[2])}
                  max={10}
                  onChange={(event, newValue) => {
                    handleChange('2')('' + newValue);
                  }}
                />
                <br />
                {touched[1] && errors[1] && (
                  <Typography variant="caption" color="error">
                    {errors[1]}
                  </Typography>
                )}
              </Box>
              <Box component="fieldset" mb={3} borderColor="primary">
                <Typography component="legend">
                  3. How you will rate the usefulness of this program?
                </Typography>
                <Rating
                  disabled={!!results}
                  name="3"
                  value={parseInt(values[3])}
                  max={10}
                  onChange={(event, newValue) => {
                    handleChange('3')('' + newValue);
                  }}
                />
                <br />
                {touched[1] && errors[1] && (
                  <Typography variant="caption" color="error">
                    {errors[1]}
                  </Typography>
                )}
              </Box>
              <Box component="fieldset" mb={3} borderColor="primary">
                <Typography component="legend">
                  4. Will you recommend this course to others
                </Typography>
                <Rating
                  disabled={!!results}
                  name="4"
                  value={parseInt(values[4])}
                  max={10}
                  onChange={(event, newValue) => {
                    handleChange('4')('' + newValue);
                  }}
                />
                <br />
                {touched[1] && errors[1] && (
                  <Typography variant="caption" color="error">
                    {errors[1]}
                  </Typography>
                )}
              </Box>
              <br />
              {!results && !preview ? (
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="outlined"
                  color="primary"
                >
                  Submit
                </Button>
              ) : (
                <Button variant="outlined" color="primary" onClick={onNext}>
                  Next
                </Button>
              )}
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

ExperienceRating.defaultProps = {
  preview: false
};
