import React, { useState, useRef, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import PlayIcon from '@material-ui/icons/PlayArrow';
import { useWindowSize } from 'hooks/useWindowSize';
import { getComputedHeightWidth } from 'utils';

function getSecondsFromTimeString(timestring: string) {
  const [minutes, seconds] = timestring.split(':');
  return parseInt(minutes, 10) * 60 + parseInt(seconds, 10);
}

export function InteractionPlayer({
  data,
  videoUrl,
  autoPlay = false
}: {
  data: any;
  videoUrl: string;
  autoPlay: boolean;
}) {
  const [began, setBegan] = useState(false);
  const { width, height } = useWindowSize();
  const [dims, setDims] = useState({ height: 0, width: 0, duration: 0 });
  const [crossedIndex, setCrossedIndex] = useState<null | number>(null);
  const [paused, setPaused] = useState(true);
  const ref: any = useRef(null);

  //   useEffect(() => {
  //     setTimeout(() => {
  //       setPaused(false);
  //     }, 1000);
  //   }, []);

  useEffect(() => {
    if (!ref.current || !dims.duration) {
      return;
    }
    if (paused) {
      ref.current.pause();
    } else {
      ref.current.play();
    }
  }, [paused, dims]);

  const onTimeUpdate = (evt: any) => {
    const flooredTime = Math.floor(evt.target.currentTime);
    if (data[flooredTime] && crossedIndex !== flooredTime) {
      ref.current.pause();
      setCrossedIndex(flooredTime);
      setPaused(true);
    }
  };

  const getButton = () => {
    if (!crossedIndex) {
      return;
    }
    const item = data[crossedIndex];
    const videoDims = getComputedHeightWidth(
      width,
      height,
      dims.width,
      dims.height
    );
    return item.map((trap: any, i: any) => (
      <button
        key={i}
        style={{
          position: 'absolute',
          left: trap.startX * videoDims.width,
          top: trap.startY * videoDims.height,
          border: 'none',
          backgroundColor: 'transparent',
          outline: 'none',
          height: trap.height * videoDims.height,
          width:
            trap.shape === 'circle'
              ? trap.height * videoDims.height
              : trap.width * videoDims.width,
          padding: 0,
          margin: 0,
          cursor: 'pointer',
          borderRadius: trap.shape === 'circle' ? 50 : 0
        }}
        onClick={() => {
          const seconds = getSecondsFromTimeString(trap.action.onClick.seek);
          if (seconds === crossedIndex) {
            setPaused(false);
          } else {
            ref.current.currentTime = seconds;
            setPaused(false);
          }
        }}
      >
        &nbsp;
      </button>
    ));
  };

  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height
      }}
    >
      <div
        style={{
          position: 'relative',
          ...getComputedHeightWidth(width, height, dims.width, dims.height)
        }}
      >
        <video
          ref={ref}
          onDurationChangeCapture={evt => {
            const { videoHeight, videoWidth, duration }: any = evt.target;
            setDims({ height: videoHeight, width: videoWidth, duration });
            if (autoPlay && paused) {
              setBegan(true);
              setPaused(false);
            }
          }}
          style={{
            ...getComputedHeightWidth(width, height, dims.width, dims.height)
          }}
          onTimeUpdate={onTimeUpdate}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
        {paused && getButton()}
        {!began && (
          <IconButton
            style={{
              position: 'absolute',
              zIndex: 10,
              left: '50%',
              top: '50%',
              transform: `translateX(-48px) translateY(-64px)`
            }}
            onClick={() => {
              setBegan(true);
              setPaused(false);
            }}
          >
            <PlayIcon style={{ fontSize: 64 }} />
          </IconButton>
        )}
      </div>
    </div>
  );
}
