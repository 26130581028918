import React, { useState, useEffect, useReducer } from 'react';
import { Route, useRouteMatch, Redirect, useHistory } from 'react-router-dom';
import { useWindowSize } from 'hooks/useWindowSize';
import {
  Grid,
  Paper,
  makeStyles,
  Typography,
  Hidden,
  TextField,
  Button,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  withStyles,
  InputBase,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { API_URL } from 'index';
import { VideoPlayer } from 'components/VideoPlayer';
import { MicroLearningQuiz } from 'components/micro-learning/MicroLearningQuiz';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { describeArc } from 'utils';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  CreateOneUnstructuredDataMutation,
  CreateOneUnstructuredDataMutationVariables
} from '__generated__/CreateOneUnstructuredDataMutation';
import { unstructuredDataJCB_LIFT_TOOLS } from '__generated__/unstructuredDataJCB_LIFT_TOOLS';
import { LoadingOrError } from 'components/LoadingOrError';
import { format } from 'date-fns';

export const CreateOneUnstructuredData_Mutation = gql`
  mutation CreateOneUnstructuredDataMutation(
    $data: String!
    $program: String!
    $company: String!
  ) {
    createOneUnstructuredData(
      data: { data: $data, program: $program, company: $company }
    ) {
      id
      program
      company
      data
    }
  }
`;

const quiz = [
  {
    id: '1',
    question:
      '1.	For lifting tools & tackles design calculation, what factor of safety to be used in JCB.',
    multichoice: false,
    choices: [
      {
        key: 'a',
        text: '3.5',
        isCorrect: false
      },
      {
        key: 'b',
        text: '4.0',
        isCorrect: true
      },
      {
        key: 'c',
        text: '6.2',
        isCorrect: false
      },
      {
        key: 'd',
        text: '5.0',
        isCorrect: false
      }
    ],
    image:
      'https://storage.googleapis.com/pl-public-assets/jcbvideoquestionbank/qe-1.svg',
    background: ''
  },
  {
    id: '2',
    question: '2.	What is the use of a foot device?',
    multichoice: false,
    choices: [
      {
        key: 'a',
        text: 'A.	To carry the load',
        isCorrect: false
      },
      {
        key: 'b',
        text:
          'B.	To balance the load with safe distance and to Protect hand and fingers',
        isCorrect: true
      },
      {
        key: 'c',
        text: 'C.	To lift the load',
        isCorrect: false
      },
      {
        key: 'd',
        text: 'D.	To push and pull the load',
        isCorrect: false
      },
      {
        key: 'e',
        text: 'E.	None of the above',
        isCorrect: false
      }
    ],
    image:
      'https://storage.googleapis.com/pl-public-assets/jcbvideoquestionbank/qe-2.svg',
    background: ''
  },
  {
    id: '3',
    question:
      '3.	Which life saving rule is applicable while working with load by crane.',
    multichoice: false,
    choices: [
      {
        key: 'a',
        text: 'A.	Seat belts',
        isCorrect: false
      },
      {
        key: 'b',
        text: 'B.	At Height',
        isCorrect: false
      },
      {
        key: 'c',
        text: 'C.	Suspended loads',
        isCorrect: true
      },
      {
        key: 'd',
        text: 'D.	In Roadways',
        isCorrect: false
      }
    ],
    image:
      'https://storage.googleapis.com/pl-public-assets/jcbvideoquestionbank/qe-3.svg',
    background: ''
  },
  {
    id: '4',
    question:
      '4.	What is the potential hazard while performing the lifting operation',
    multichoice: false,
    choices: [
      {
        key: 'a',
        text: 'A.	Hit by load',
        isCorrect: false
      },
      {
        key: 'b',
        text: 'B.	Falling of object',
        isCorrect: false
      },
      {
        key: 'c',
        text: 'C.	Failuare of wire rope, chains, hooks, etc',
        isCorrect: false
      },
      {
        key: 'd',
        text: 'D.	All of above',
        isCorrect: true
      }
    ],
    image:
      'https://storage.googleapis.com/pl-public-assets/jcbvideoquestionbank/qe-4.svg',
    background: ''
  },
  {
    id: '5',
    question:
      '5.	Which mechanism used in overhead crane to protect the two cranes from hitting each other.',
    multichoice: false,
    choices: [
      {
        key: 'a',
        text: 'A.	Light Barrier',
        isCorrect: false
      },
      {
        key: 'b',
        text: 'B.	Limit Switch',
        isCorrect: false
      },
      {
        key: 'c',
        text: 'C.	Mechanical Stopper',
        isCorrect: false
      },
      {
        key: 'd',
        text: 'D.	Anti-collision System ',
        isCorrect: true
      }
    ],
    image:
      'https://storage.googleapis.com/pl-public-assets/jcbvideoquestionbank/qe-5.svg',
    background: ''
  },
  {
    id: '6',
    question: '6.	Which limit switch being use in overhead cranes.',
    multichoice: false,
    choices: [
      {
        key: 'a',
        text: 'A.	Long travel limit switch',
        isCorrect: false
      },
      {
        key: 'b',
        text: 'B.	Cross travel limit switch ',
        isCorrect: false
      },
      {
        key: 'c',
        text: 'C.	Hoist Up and down limit switch',
        isCorrect: false
      },
      {
        key: 'd',
        text: 'D.	All of above',
        isCorrect: true
      }
    ],
    image:
      'https://storage.googleapis.com/pl-public-assets/jcbvideoquestionbank/qe-6.svg',
    background: ''
  },
  {
    id: '7',
    question:
      '7.	What does it mean if a red colour coding tag is placed on lifting tools/tackles?',
    multichoice: false,
    choices: [
      {
        key: 'a',
        text: 'A.	Ready to use',
        isCorrect: false
      },
      {
        key: 'b',
        text: 'B.	Inspection not done',
        isCorrect: false
      },
      {
        key: 'c',
        text: 'C.	Rejected tools/tackles',
        isCorrect: true
      },
      {
        key: 'd',
        text: 'D.	Hold for inspection',
        isCorrect: false
      }
    ],
    image:
      'https://storage.googleapis.com/pl-public-assets/jcbvideoquestionbank/qe-7.svg',
    background: ''
  },
  {
    id: '8',
    question: '8.	Rejection percentage for  hook throat opening is ?',
    multichoice: false,
    choices: [
      {
        key: 'a',
        text: 'A.	10%',
        isCorrect: true
      },
      {
        key: 'b',
        text: 'B.	12.2%',
        isCorrect: false
      },
      {
        key: 'c',
        text: 'C.	8%',
        isCorrect: false
      },
      {
        key: 'd',
        text: 'D.	13%',
        isCorrect: false
      }
    ],
    image:
      'https://storage.googleapis.com/pl-public-assets/jcbvideoquestionbank/qe-8.svg',
    background: ''
  },
  {
    id: '9',
    question: '9.	Which factor to be considered while lifting the load?',
    multichoice: false,
    choices: [
      {
        key: 'a',
        text: 'A.	Center of gravity',
        isCorrect: false
      },
      {
        key: 'b',
        text: 'B.	SWL of lifting tools',
        isCorrect: false
      },
      {
        key: 'c',
        text: 'C.	Weight of lifting load',
        isCorrect: false
      },
      {
        key: 'd',
        text: 'D.	All of above',
        isCorrect: true
      }
    ],
    image:
      'https://storage.googleapis.com/pl-public-assets/jcbvideoquestionbank/qe-9.svg',
    background: ''
  },
  {
    id: '10',
    question:
      '10.	What should be the height of load from ground while handling with crane?',
    multichoice: false,
    choices: [
      {
        key: 'a',
        text: 'A.	1.5 Meter',
        isCorrect: false
      },
      {
        key: 'b',
        text: 'B.	2 Meter',
        isCorrect: false
      },
      {
        key: 'c',
        text: 'C.	1 feet',
        isCorrect: true
      },
      {
        key: 'd',
        text: 'D.	2.5 Meter',
        isCorrect: false
      }
    ],
    image:
      'https://storage.googleapis.com/pl-public-assets/jcbvideoquestionbank/qe-10.svg',
    background: ''
  }
];

export const GEInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#A6B7C0',
    border: '1px solid #A6B7C0',
    fontSize: 16,

    padding: '10px 12px',
    color: '#fff',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: { width: 180 },
    [theme.breakpoints.up('sm')]: { width: 300 }
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    // // Use the system font instead of the default Roboto font.
    // fontFamily: [
    //   '-apple-system',
    //   'BlinkMacSystemFont',
    //   '"Segoe UI"',
    //   'Roboto',
    //   '"Helvetica Neue"',
    //   'Arial',
    //   'sans-serif',
    //   '"Apple Color Emoji"',
    //   '"Segoe UI Emoji"',
    //   '"Segoe UI Symbol"'
    // ].join(','),
    // '&:focus': {
    //   boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main
    // },
  }
}))(InputBase);

const useStyles = makeStyles(theme => ({
  whiteContainer: (props: any) => ({
    backgroundColor: 'rgb(252, 189, 86)',
    height: props.height,
    width: props.width,
    [theme.breakpoints.down('sm')]: { padding: 16 },
    [theme.breakpoints.up('sm')]: { padding: 32 }
  }),
  loginRight: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: { alignItems: 'center' },
    [theme.breakpoints.up('sm')]: { alignItems: 'flex-end' }
  },
  inputLabel: {
    color: '#223165',
    textAlign: 'center',
    fontSize: 22,
    marginLeft: 12
  },
  certContainer: (props: any) => ({
    height: props.height,
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FCBD56',
    width: props.width,
    display: 'flex',
    [theme.breakpoints.down('sm')]: { padding: 16 },
    [theme.breakpoints.up('sm')]: { padding: 80 }
  }),
  certBody: (props: any) =>
    ({
      boxShadow: '15px 15px #C79850',
      borderRadius: 8,
      position: 'relative',
      backgroundImage:
        'url(https://storage.googleapis.com/pl-public-assets/jcb/crane-module/Screen%202%2C3.svg)',
      backgroundColor: '#fff',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'bottom',
      backgroundPositionY: 55,
      [theme.breakpoints.down('sm')]: {
        height: props.height - 32,
        width: props.width - 32
      },
      [theme.breakpoints.up('sm')]: {
        height: props.height - 160,
        width: props.width - 160
      }
    } as any),
  downloadPos: {
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      right: 20,
      top: 20
    },
    [theme.breakpoints.up('sm')]: {
      right: 80,
      top: 40
    }
  }
}));

export const UNSTRUCTUREDATA_JCB_LIFT_TOOLS = gql`
  query unstructuredDataJCB_LIFT_TOOLS {
    unstructuredData(
      where: {
        company: { equals: "JCB" }
        program: { equals: "lift-tools-training" }
      }
    ) {
      id
      data
      createdAt
    }
  }
`;

function LiftToolsData() {
  const { data, loading, error } = useQuery<unstructuredDataJCB_LIFT_TOOLS>(
    UNSTRUCTUREDATA_JCB_LIFT_TOOLS
  );
  const [password, setPassword] = useState('');

  if (password !== 'eeleifohthaej0Uv') {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: 80 }}>
        <TextField
          label="Admin password"
          fullWidth
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </div>
    );
  }
  if (data && data.unstructuredData) {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sr. No</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Emp ID</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Date &#38; Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.unstructuredData.map((rowItem: any, i: number) => {
              const row = { ...rowItem, data: JSON.parse(rowItem.data) };

              return (
                <TableRow key={rowItem.id}>
                  <TableCell component="th" scope="row">
                    {i + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.data.info && row.data.info.name}
                  </TableCell>
                  <TableCell>{row.data.info && row.data.info.empId}</TableCell>
                  <TableCell>
                    {row.data.quizScore && row.data.quizScore.score}
                  </TableCell>
                  <TableCell>
                    {format(new Date(row.createdAt), 'dd-MMM-yy hh:mm a')}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return <LoadingOrError {...{ error, loading }} />;
}

type State = {
  rehydrated: boolean;
  info: any;
  quizScore: any;
  done: boolean;
};

type Action = {
  type: 'REHYDRATE' | 'SET_INFO' | 'SET_QUIZ_SCORE' | 'DONE';
  payload: any;
};

const initialState: State = {
  rehydrated: false,
  info: null,
  quizScore: null,
  done: false
};

export function LiftTools() {
  const [state, dispatch] = useReducer((prevState: State, action: Action) => {
    switch (action.type) {
      case 'REHYDRATE':
        return action.payload;
      case 'SET_INFO':
        return {
          ...prevState,
          info: action.payload
        };
      case 'SET_QUIZ_SCORE':
        return {
          ...prevState,
          quizScore: action.payload
        };
      case 'DONE':
        return {
          ...prevState,
          done: true
        };
      default:
        return prevState;
    }
  }, initialState);
  //   const [rehydrated, setRehydrated] = useState(false);
  const { path } = useRouteMatch();
  const { height, width } = useWindowSize();
  const classes = useStyles({ height, width });
  //   const [info, setInfo] = useState<any>(null);
  //   const [done, setDone] = useState(false);
  //   const [quizScore, setQuizScore] = useState<any>(null);
  const history = useHistory();
  const [url, setUrl] = useState('');
  const score = state.quizScore ? state.quizScore.score : 0;
  const [createOneUnstructuredData] = useMutation<
    CreateOneUnstructuredDataMutation,
    CreateOneUnstructuredDataMutationVariables
  >(CreateOneUnstructuredData_Mutation);

  useEffect(() => {
    if (state.rehydrated && state.info && state.quizScore) {
      localStorage.setItem('LIFT_TOOLS_DATA_V3', JSON.stringify(state));
    }
  }, [state]);

  useEffect(() => {
    const data = localStorage.getItem('LIFT_TOOLS_DATA_V3');
    if (data) {
      const { info, quizScore, done } = JSON.parse(data);
      dispatch({
        type: 'REHYDRATE',
        payload: {
          rehydrated: true,
          info,
          quizScore,
          done
        }
      });
    } else {
      dispatch({
        type: 'REHYDRATE',
        payload: {
          rehydrated: true,
          info: null,
          quizScore: null,
          done: false
        }
      });
    }
    const getData = () => {
      fetch(
        `${API_URL}/signed-url?bucket=pl-signed-assets&fileKey=JCB-Lifting-tools.mp4`
      )
        .then(res => res.json())
        .then(data => setUrl(data.url));
    };
    getData();
  }, []);

  return (
    <div style={{ height, width }}>
      <Route exact path={path}>
        <Redirect to={`${path}/info`} />
      </Route>
      <Route path={`${path}/info`}>
        <div className={classes.whiteContainer}>
          <Paper
            style={{ backgroundColor: '#fff', padding: 8, height: '100%' }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} style={{ textAlign: 'center' }}>
                <img
                  style={{ height: 120, padding: 20 }}
                  src="https://storage.googleapis.com/pl-public-assets/jcb/logo.svg"
                  alt="jcb logo"
                />
              </Grid>
              <Grid item xs={12} sm={8} className={classes.loginRight}>
                <div
                  style={{
                    padding: 16,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%'
                  }}
                >
                  <Hidden xsDown>
                    <img
                      style={{ height: 150 }}
                      src="https://storage.googleapis.com/pl-public-assets/jcb/crane-module/Screen%201%2C2%2C3.svg"
                      alt="crane-hang-icon"
                    />
                    <Typography
                      style={{
                        background: '#23323F',
                        color: '#fff',
                        borderRadius: 40,
                        minWidth: 200,
                        padding: 8,
                        paddingLeft: 16,
                        paddingRight: 16
                      }}
                      align="center"
                    >
                      Crane Examiniation Module
                    </Typography>
                  </Hidden>
                  <Formik
                    initialValues={
                      state.info || { email: '', empId: '', name: '' }
                    }
                    validationSchema={Yup.object().shape({
                      email: Yup.string()
                        .label('Email')
                        .email()
                        .required(),
                      empId: Yup.string()
                        .label('Employee ID')
                        .required(),
                      name: Yup.string()
                        .label('Name')
                        .required()
                    })}
                    onSubmit={(values, actions) => {
                      dispatch({
                        type: 'SET_INFO',
                        payload: values
                      });
                      history.push(`/jcb/lift-tools/video`);
                    }}
                  >
                    {formikProps => {
                      return (
                        <form onSubmit={formikProps.handleSubmit}>
                          <TextField
                            style={{ marginTop: 25, width: 250 }}
                            variant="filled"
                            size="small"
                            label="Email"
                            name="email"
                            value={formikProps.values.email}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={
                              formikProps.touched.email &&
                              !!formikProps.errors.email
                            }
                            helperText={
                              formikProps.touched.email &&
                              formikProps.errors.email
                            }
                          />
                          <br />
                          <br />
                          <TextField
                            style={{ width: 250 }}
                            variant="filled"
                            size="small"
                            label="Name"
                            name="name"
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.name}
                            error={!!formikProps.errors.name}
                            helperText={
                              formikProps.touched.name &&
                              formikProps.errors.name
                            }
                          />
                          <br />
                          <br />
                          <TextField
                            style={{ width: 250 }}
                            variant="filled"
                            size="small"
                            label="Employee ID"
                            name="empId"
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.empId}
                            error={!!formikProps.errors.empId}
                            helperText={
                              formikProps.touched.empId &&
                              formikProps.errors.empId
                            }
                          />
                          <br />
                          <br />
                          <div style={{ textAlign: 'center' }}>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: '#23323F',
                                color: '#fff',
                                width: 150
                              }}
                              type="submit"
                            >
                              NEXT
                            </Button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Route>
      <Route path={`${path}/video`}>
        <VideoPlayer
          height={height}
          width={width}
          url={url}
          onEnded={() => {
            history.push(`/jcb/lift-tools/quiz`);
          }}
        />
      </Route>
      <Route path={`${path}/quiz`}>
        <div
          style={{
            backgroundColor: 'rgb(252, 189, 86)',
            padding: 16,
            height,
            width,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Paper
            style={{
              backgroundColor: '#fff',
              height: height - 32,
              width: width - 32,
              overflowY: 'scroll'
            }}
          >
            <MicroLearningQuiz
              quiz={quiz}
              onSubmit={(answers, score, cb) => {
                dispatch({
                  type: 'SET_QUIZ_SCORE',
                  payload: { answers, score }
                });
                cb();
              }}
              results={state.quizScore}
              onNext={async () => {
                if (!state.done) {
                  await createOneUnstructuredData({
                    variables: {
                      company: 'JCB',
                      program: 'lift-tools-training',
                      data: JSON.stringify({
                        quizScore: state.quizScore,
                        info: state.info
                      })
                    }
                  });
                  dispatch({
                    type: 'DONE',
                    payload: null
                  });
                }
                history.push(`/jcb/lift-tools/certificate`);
              }}
            />
          </Paper>
        </div>
      </Route>
      <Route path={`${path}/certificate`}>
        {state.info && state.quizScore && (
          <div className={classes.certContainer}>
            <Grid container className={classes.certBody}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <div className={classes.downloadPos}>
                  <Tooltip title="Download">
                    <IconButton
                      onClick={() => {
                        window.location.href = `${API_URL}/jcb/crane-exam-pdf?score=${state.quizScore.score}&name=${state.info.name}&id=${state.info.empId}`;
                      }}
                    >
                      <SaveAltIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <img
                    style={{ height: 150 }}
                    src="https://storage.googleapis.com/pl-public-assets/jcb/crane-module/Screen%201%2C2%2C3.svg"
                    alt="crane-module"
                  />
                  <Typography
                    style={{
                      background: '#23323F',
                      color: '#fff',
                      borderRadius: 20,
                      padding: 8,
                      display: 'flex',
                      alignItems: 'center',
                      minWidth: 220,
                      justifyContent: 'center'
                    }}
                    align="center"
                  >
                    RESULT
                  </Typography>
                </div>
                <br />
                <FormControl>
                  <InputLabel
                    className={classes.inputLabel}
                    shrink
                    htmlFor="bootstrap-input-name"
                  >
                    Name
                  </InputLabel>
                  <GEInput
                    id="bootstrap-input-name"
                    name="name"
                    disabled
                    defaultValue={state.info.name}
                  />
                </FormControl>
                <br />
                <FormControl>
                  <InputLabel
                    className={classes.inputLabel}
                    shrink
                    htmlFor="bootstrap-input-badge"
                  >
                    Employee ID
                  </InputLabel>
                  <GEInput disabled name="id" defaultValue={state.info.empId} />
                </FormControl>
                <br />
                <br />
                <svg
                  width="140"
                  height="140"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="70" cy="70" r="70" fill="#9c9d9e" />
                  <path
                    id="arc1"
                    fill="none"
                    stroke="#F5AD23"
                    strokeWidth="20"
                    d={describeArc(70, 70, 60, 0, (score / 100) * 360)}
                  />
                  <circle cx="70" cy="70" r="55" fill="#fff" />
                  <circle cx="70" cy="70" r="40" fill="#F5AD23" />
                  <text
                    x="70"
                    y="70"
                    fill="#fff"
                    textAnchor="middle"
                    fontSize="30px"
                    dominantBaseline="middle"
                  >
                    {score.toFixed(0)}%
                  </text>
                </svg>
              </div>
            </Grid>
          </div>
        )}
      </Route>
      <Route path={`${path}/data`}>
        <LiftToolsData />
      </Route>
    </div>
  );
}
