import React, { useState } from 'react';
import {
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(0)
  }
}));

export function MultipleChoiceQuestionWithImage({
  preview,
  imgUrl,
  question,
  onSubmit,
  results,
  onNext
}: {
  preview?: boolean;
  imgUrl: string;
  question: {
    question: string;
    choices: { key: string; text: string; isCorrect: boolean }[];
  };
  onSubmit: (answers: any, score: number, cb: any) => void;
  onNext: () => void;
  results: any;
}) {
  const classes = useStyles({});
  const [state, setState] = React.useState(
    (results && results.answers) ||
      question.choices.reduce(
        (acc, choice) => ({ ...acc, [choice.key]: false }),
        {}
      )
  );
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (name: any) => (event: any) => {
    setState({ ...state, [name]: event.target.checked });
  };

  return (
    <Grid container style={{ padding: 16 }} spacing={3}>
      <Grid item sm={12} md={6}>
        <div>
          <img style={{ width: '100%' }} src={imgUrl} alt="reference" />
        </div>
      </Grid>
      <Grid item sm={12} md={6}>
        <FormControl
          disabled={!!results}
          component="fieldset"
          className={classes.formControl}
        >
          <FormLabel component="legend">{question.question}</FormLabel>
          <FormGroup>
            {question.choices.map(choice => (
              <FormControlLabel
                key={choice.key}
                control={
                  <Checkbox
                    checked={state[choice.key]}
                    onChange={handleChange(choice.key)}
                    value={choice.key}
                  />
                }
                label={choice.text}
              />
            ))}
          </FormGroup>
          <br />
          {results && <Typography>Your scored {results.score}%</Typography>}
          <br />
          {results || preview ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                onNext();
              }}
            >
              Next
            </Button>
          ) : (
            <Button
              disabled={submitting}
              variant="outlined"
              color="primary"
              onClick={() => {
                setSubmitting(true);
                let score = 0;
                question.choices.forEach(choice => {
                  if (choice.isCorrect === state[choice.key]) {
                    score = score + 1;
                  }
                });
                onSubmit(state, Math.floor((score / 6) * 100), () => {
                  setSubmitting(false);
                });
              }}
            >
              Submit
            </Button>
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
}

MultipleChoiceQuestionWithImage.defaultProps = {
  preview: false
};
