import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem, makeStyles } from '@material-ui/core';
import gql from 'graphql-tag';
import { USER_FRAGMENT } from 'components/PrivateRoute';
import { COMPANY_USERS_QUERY } from './CreateNewUser';
import { useMutation } from '@apollo/react-hooks';
import { AdminUpdateOneUser } from '__generated__/AdminUpdateOneUser';

export const UPDATE_USER = gql`
  mutation AdminUpdateOneUser($update: UserUpdateInput!, $id: String!) {
    updateOneUser(data: $update, where: { id: $id }) {
      ...UserInfo
    }
  }
  ${USER_FRAGMENT}
`;

const ITEM_HEIGHT = 48;

const useStyles = makeStyles(() => ({
  overflowBtn: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    height: 32,
    width: 32,
    outline: 'none',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      borderRadius: '50%'
    }
  }
}));

export function UserActions({
  user,
  companyId,
  setShowEditDialog
}: {
  user: any;
  companyId: string;
  setShowEditDialog: any;
}) {
  const [updateOneUser] = useMutation<AdminUpdateOneUser>(UPDATE_USER);
  const classes = useStyles({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option: string) => {
    setAnchorEl(null);
    let update: any = {
      blocked: user.blocked,
      deleted: user.deleted
    };
    if (option === 'Block') {
      update.blocked = true;
    } else if (option === 'Delete') {
      update.deleted = true;
    } else if (option === 'Unblock') {
      update.blocked = false;
    } else if (option === 'Set Active') {
      update.deleted = false;
    } else if (option === 'Remove License') {
      update['license'] = { disconnect: true };
    } else if (option === 'Edit') {
      return setShowEditDialog(user.id);
    }
    updateOneUser({
      variables: {
        update,
        id: user.id
      },
      update: (cache, { data: { updateOneUser } }: any) => {
        const { users } = cache.readQuery({
          query: COMPANY_USERS_QUERY,
          variables: { companyId }
        }) as any;
        cache.writeQuery({
          query: COMPANY_USERS_QUERY,
          variables: { companyId },
          data: {
            users: users.map((item: any) => {
              if (item.id === user.id) {
                return updateOneUser;
              }
              return item;
            })
          }
        });
      }
    });
  };

  const getMenuItems = () => {
    const license = user.license ? ['Remove License'] : [];
    if (user.blocked) {
      return ['Edit', 'Unblock', ...license].map(option => (
        <MenuItem key={option} onClick={() => handleClose(option)}>
          {option}
        </MenuItem>
      ));
    } else if (user.deleted) {
      return ['Edit', 'Set Active', ...license].map(option => (
        <MenuItem key={option} onClick={() => handleClose(option)}>
          {option}
        </MenuItem>
      ));
    } else {
      return ['Edit', 'Block', 'Delete', ...license].map(option => (
        <MenuItem key={option} onClick={() => handleClose(option)}>
          {option}
        </MenuItem>
      ));
    }
  };

  return (
    <>
      <button
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.overflowBtn}
      >
        <MoreVertIcon style={{ zIndex: 1 }} />
      </button>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 200
          }
        }}
      >
        {getMenuItems()}
      </Menu>
    </>
  );
}
