import React, { useContext } from 'react';
import { Formik } from 'formik';
import { TextField, Button } from '@material-ui/core';
import { useWindowSize } from 'hooks/useWindowSize';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { AlertContext } from 'App';

const CREATE_COMPANY_MUTATION = gql`
  mutation CreateOneCompany(
    $name: String!
    $info: String!
    $userName: String!
    $email: String!
  ) {
    createOneCompany(
      name: $name
      info: $info
      userName: $userName
      email: $email
    ) {
      id
      name
    }
  }
`;

export function NewCompany() {
  const [createOneCompany] = useMutation(CREATE_COMPANY_MUTATION);
  const { contentWidth } = useWindowSize();
  const { showAlert } = useContext(AlertContext);
  return (
    <div style={{ width: contentWidth, padding: 16 }}>
      <Formik
        initialValues={{ name: '', info: '', userName: '', email: '' }}
        validationSchema={() =>
          Yup.object().shape({
            name: Yup.string()
              .label('Name')
              .required(),
            info: Yup.string()
              .label('Info')
              .required(),
            userName: Yup.string()
              .label('Username')
              .required(),
            email: Yup.string()
              .label('Email')
              .email()
              .required()
          })
        }
        onSubmit={async (values, actions) => {
          try {
            await createOneCompany({ variables: values });
            showAlert(
              {
                message: 'Company created successfully!',
                type: 'Success'
              },
              () => actions.resetForm()
            );
          } catch {
            showAlert({
              message:
                'There was an error creating the company, please check if this user already exists in the system!',
              type: 'Error'
            });
          }
        }}
      >
        {formikProps => {
          return (
            <form onSubmit={formikProps.handleSubmit}>
              <TextField
                fullWidth
                name="name"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.name}
                label="Company name"
                error={formikProps.touched.name && !!formikProps.errors.name}
                helperText={formikProps.touched.name && formikProps.errors.name}
              />
              <br />
              <br />
              <TextField
                fullWidth
                name="info"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.info}
                label="Company info"
                error={formikProps.touched.info && !!formikProps.errors.info}
                helperText={formikProps.touched.info && formikProps.errors.info}
              />
              <br />
              <br />
              <TextField
                fullWidth
                name="userName"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.userName}
                label="Admin username"
                error={
                  formikProps.touched.userName && !!formikProps.errors.userName
                }
                helperText={
                  formikProps.touched.userName && formikProps.errors.userName
                }
              />
              <br />
              <br />
              <TextField
                fullWidth
                name="email"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.email}
                label="Admin email"
                error={formikProps.touched.email && !!formikProps.errors.email}
                helperText={
                  formikProps.touched.email && formikProps.errors.email
                }
              />
              <br />
              <br />
              <Button
                disabled={formikProps.isSubmitting}
                type="submit"
                variant="outlined"
                color="primary"
              >
                Submit
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}
