import React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useWindowSize } from 'hooks/useWindowSize';
import { CompanyWithLicenses } from '__generated__/CompanyWithLicenses';
import { AddLicensesToCompany } from '__generated__/AddLicensesToCompany';
import { useParams } from 'react-router-dom';
import { LoadingOrError } from 'components/LoadingOrError';
import { Formik } from 'formik';
import { DatePicker } from '@material-ui/pickers';
import { TextField, Button, Typography } from '@material-ui/core';
import { format, parseISO, addMonths } from 'date-fns';
import * as Yup from 'yup';

const COMPANY_LICENSES_QUERY = gql`
  query CompanyWithLicenses($id: String!) {
    company(where: { id: $id }) {
      id
      name
      licenses {
        id
        expires
        createdAt
      }
    }
  }
`;

const ADD_LICENSE_MUTATION = gql`
  mutation AddLicensesToCompany(
    $create: [LicenseCreateWithoutCompanyInput!]
    $id: String!
  ) {
    updateOneCompany(
      data: { licenses: { create: $create } }
      where: { id: $id }
    ) {
      id
      licenses {
        id
        expires
        createdAt
      }
    }
  }
`;

export function CompanyLicenses() {
  const { cid } = useParams();
  const { loading, error, data } = useQuery<CompanyWithLicenses>(
    COMPANY_LICENSES_QUERY,
    {
      variables: {
        id: cid
      }
    }
  );
  const { contentWidth: width } = useWindowSize();
  const [updateOneCompany] = useMutation<AddLicensesToCompany>(
    ADD_LICENSE_MUTATION
  );

  if (data && data.company) {
    return (
      <div style={{ width, padding: 16, boxSizing: 'border-box' }}>
        <Formik
          initialValues={{ expiryDate: addMonths(new Date(), 2), count: 0 }}
          validationSchema={() =>
            Yup.object().shape({
              expiryDate: Yup.date().required(),
              count: Yup.number()
                .min(1)
                .required()
            })
          }
          onSubmit={async (values, { resetForm }) => {
            if (!data.company) {
              return;
            }
            const licenses = Array.from(Array(values.count).keys()).map(() => ({
              expires: values.expiryDate
            }));
            await updateOneCompany({
              variables: {
                id: data.company.id,
                create: licenses
              },
              refetchQueries: [
                {
                  query: COMPANY_LICENSES_QUERY,
                  variables: {
                    id: cid
                  }
                }
              ]
            });
            resetForm();
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            isSubmitting
          }) => (
            <form
              onSubmit={handleSubmit}
              style={{ display: 'flex', alignItems: 'flex-bottom' }}
            >
              <DatePicker
                disablePast
                label="Expiry Date"
                value={values.expiryDate}
                onChange={(date: any) => setFieldValue('expiryDate', date)}
                animateYearScrolling
              />
              &nbsp;&nbsp;
              <TextField
                label="Count"
                value={values.count}
                onChange={handleChange}
                name="count"
                onBlur={handleBlur}
                type="number"
              />
              &nbsp;&nbsp;
              <div>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Add Licenses
                </Button>
              </div>
            </form>
          )}
        </Formik>
        <br />
        <Typography variant="h5">{data.company.name}</Typography>
        <ol>
          {data.company.licenses &&
            data.company.licenses.map((license, index) => {
              return (
                <li
                  key={license.id}
                  style={{ display: 'flex', alignItems: 'flex-end' }}
                >
                  <Typography>
                    {index + 1}.&nbsp;{license.id}&nbsp;&nbsp;
                  </Typography>
                  <Typography variant="caption">
                    Expires:&nbsp;
                    {format(parseISO(license.expires), 'dd MMM yyyy')}
                  </Typography>
                </li>
              );
            })}
        </ol>
      </div>
    );
  }

  return <LoadingOrError {...{ loading, error }} />;
}
