import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import {
  Paper,
  Typography,
  Button,
  makeStyles,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import { RadialChart } from 'react-vis';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import FullScreenExitIcon from '@material-ui/icons/FullscreenExit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import screenfull from 'screenfull';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Draggable } from './Draggable';
import 'react-vis/dist/style.css';
import { useWindowSize } from 'hooks/useWindowSize';
import { useTimer } from 'hooks/useTimer';
import useDeviceOrientation from 'hooks/useDeviceOrientation';
import { getComputedHeightWidth } from 'utils';

interface ICircle {
  x: number;
  y: number;
  r: number;
}

function area(A: ICircle, B: ICircle) {
  if (A.x === B.x && A.y === B.y && A.r === B.r) {
    return Math.PI * A.r * B.r;
  }
  let d = Math.hypot(B.x - A.x, B.y - A.y);

  if (d < A.r + B.r) {
    const a = A.r * A.r;
    const b = B.r * B.r;

    const x = (a - b + d * d) / (2 * d);
    const z = x * x;
    const y = Math.sqrt(a - z);

    if (d < Math.abs(B.r - A.r)) {
      return Math.PI * Math.min(a, b);
    }
    return (
      a * Math.asin(y / A.r) +
      b * Math.asin(y / B.r) -
      y * (x + Math.sqrt(z + b - a))
    );
  }
  return 0;
}

const useStyles = makeStyles(theme => ({
  '@keyframes pulse': {
    '0%': {
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.3)'
    },
    '100%': {
      boxShadow: '0 0 0 70px rgba(0, 0, 0, 0)'
    }
  },
  pulse: {
    animation: '$pulse 2.5s infinite',
    boxShadow: '0 0 0 rgba(204,169,44, 0.4)'
  },
  instructionStyle: {
    color: '#fff',
    marginBottom: 10,
    textAlign: 'justify'
  }
}));

type Captures = { x: number; y: number }[];

const duration = 180;

export default function SpotIdentification(props: {
  preview: boolean;
  imgUrl: string;
  results: Captures;
  dims: { height: number; width: number };
  onNext: () => void;
  onCaptures: (
    captures: Captures,
    matches: number,
    score: number,
    elapsedTime: number,
    cb: any
  ) => void;
  predefs: Captures;
  title: string;
  exitIfFullscreen: boolean;
  showTestHelper: boolean;
}) {
  const classes = useStyles({});
  const [submitting, setSubmitting] = useState(false);
  const [readInstructions, setReadInstructions] = useState(
    props.showTestHelper ? false : true
    // true
  );
  const [captureHash, setCaptureHash] = useState<{
    [key: number]: { x: number; y: number };
  }>({});
  const { width, height } = useWindowSize();
  const [fullscreen, setFullScreen] = useState(false);
  const deviceOrientation = useDeviceOrientation();
  const { elapsed, finished, resumeTimer } = useTimer({
    duration,
    startPaused: props.showTestHelper ? true : false
    // startPaused: true
  });
  const counter = duration - elapsed;
  const resultsAvailable = !!props.results.length;

  const captures = useMemo(() => {
    if (resultsAvailable) {
      return props.results;
    }
    return Object.keys(captureHash).map((key: any) => captureHash[key]);
  }, [captureHash, props.results, resultsAvailable]);

  const { height: imgHeight, width: imgWidth } = getComputedHeightWidth(
    width,
    height,
    props.dims.width,
    props.dims.height
  );

  const diameter = imgHeight / 10;

  const predefCopy = [...props.predefs];
  let matches = 0;
  for (let i = 0; i < captures.length; i++) {
    const capture = captures[i];
    for (let j = 0; j < predefCopy.length; j++) {
      const item = predefCopy[j];
      const intersection = area(
        {
          x: item.x * imgWidth,
          y: item.y * imgHeight,
          r: diameter / 2
        },
        {
          x: capture.x * imgWidth,
          y: capture.y * imgHeight,
          r: diameter / 2
        }
      );

      if (
        intersection / ((((Math.PI * diameter) / 2) * diameter) / 2) >=
        0.45
      ) {
        matches += 1;
        predefCopy.splice(j, 1);
        break;
      }
    }
  }

  const percent = Math.floor((matches / props.predefs.length) * 100);
  const chartAngles = [
    { angle: percent, radius: 20, innerRadius: 16 },
    { angle: 100 - percent, radius: 20, innerRadius: 16 }
  ];

  // console.log(matches, percent, captures);

  //   useEffect(()=>{
  //       setReadInstructions(false)
  //   },[props.imgUrl])

  useEffect(() => {
    if (finished && !resultsAvailable) {
      setSubmitting(true);
      props.onCaptures(captures, matches, percent, elapsed, () => {
        setSubmitting(false);
      });
    }
  }, [captures, elapsed, finished, matches, percent, props, resultsAvailable]);

  useEffect(() => {
    const callback = () => {
      setFullScreen((screenfull as any).isFullscreen);
    };
    if ((screenfull as any).isEnabled) {
      (screenfull as any).on('change', callback);
    }
    return () => {
      (screenfull as any).off('change', callback);
    };
  }, []);

  // console.log(JSON.stringify(captures));

  if (!deviceOrientation || deviceOrientation === 'portrait') {
    return (
      <div>
        <Paper style={{ padding: 16 }}>
          <Typography variant="h6">
            The device needs to be in landscape mode
          </Typography>
        </Paper>
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: height,
        width: width,
        padding: 0,
        margin: 0,
        overflow: 'hidden'
      }}
    >
      <div
        style={{
          width: imgWidth,
          height: imgHeight,
          position: 'relative',
          userSelect: 'none'
        }}
      >
        <img
          style={{
            height: imgHeight,
            width: imgWidth,
            userSelect: 'none'
          }}
          src={props.imgUrl}
          alt="refimg"
        />
        {resultsAvailable &&
          captures.map((cpt, i) => {
            return (
              <div
                key={i}
                style={{
                  height: diameter,
                  width: diameter,
                  opacity: 0.7,
                  backgroundColor: 'green',
                  position: 'absolute',
                  left: cpt.x * imgWidth,
                  top: cpt.y * imgHeight,
                  borderRadius: diameter,
                  transform: `translateY(${-diameter / 2}px)`
                }}
              />
            );
          })}
        {(resultsAvailable || props.preview) &&
          props.predefs.map((cpt, i) => {
            return (
              <div
                key={i}
                style={{
                  height: diameter,
                  width: diameter,
                  opacity: 0.5,
                  backgroundColor: 'red',
                  position: 'absolute',
                  left: cpt.x * imgWidth,
                  top: cpt.y * imgHeight,
                  borderRadius: diameter,
                  transform: `translateY(${-diameter / 2}px)`
                }}
              />
            );
          })}
        {!resultsAvailable &&
          Array.from(Array(40).keys()).map((_, i) => (
            <Draggable
              isLast={i === 0}
              diameter={diameter}
              key={i}
              initOffsetX={(width - imgWidth) / 2}
              imgHeight={imgHeight}
              imgWidth={imgWidth}
              onFinalPosition={(x, y) => {
                setCaptureHash(latest => ({ ...latest, [i]: { x, y } }));
              }}
            />
          ))}
        {!resultsAvailable && (
          <div
            style={{
              position: 'absolute',
              top: imgHeight / 2,
              borderRadius: '50%',
              left: 0,
              width: diameter * 3,
              height: diameter * 3,
              background: 'rgb(255, 255, 0, 0.2)',
              transform: `translateY(${(-diameter * 3) /
                2}px) translateX(${(-diameter * 3) / 2}px)`,
              zIndex: 0
            }}
            className={classes.pulse}
          ></div>
        )}
      </div>
      {!readInstructions ? (
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,0.7)',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 20,
            zIndex: 2,
            height,
            width,
            position: 'absolute'
          }}
        >
          <Typography
            variant="h4"
            style={{
              color: '#fff'
            }}
          >
            {props.title}
          </Typography>
          <ul>
            <li className={classes.instructionStyle}>
              <Typography>
                Use fullscreen toggle button if you are on a mobile device.
              </Typography>
            </li>
            <li className={classes.instructionStyle}>
              <Typography>
                Drag the circle around onto the correct spots. To test drag once
                before you begin.
              </Typography>
            </li>
            <li className={classes.instructionStyle}>
              <Typography>
                Once submitted you cannot edit your answer. Your answer will be
                automatically submitted when the timer stops.
              </Typography>
            </li>
            <li>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  resumeTimer();
                  setReadInstructions(true);
                }}
              >
                Let's Start
              </Button>
            </li>
          </ul>
        </div>
      ) : null}
      {!resultsAvailable && (
        <div
          style={{
            background: 'rgba(0,0,0,0.5)',
            color: '#fff',
            borderRadius: 4,
            padding: 6,
            display: 'flex',
            alignItems: 'center',
            minWidth: 80,
            justifyContent: 'center',
            position: 'absolute',
            bottom: 10,
            right: 80,
            fontSize: 'bold'
          }}
        >
          <AccessTimeIcon style={{ marginRight: 8 }} />
          <Typography variant="caption" align="center">
            {`${
              Math.floor(counter / 60) < 10
                ? '0' + Math.floor(counter / 60)
                : Math.floor(counter / 60)
            }:${counter % 60 < 10 ? '0' + (counter % 60) : counter % 60}`}
          </Typography>
        </div>
      )}

      {resultsAvailable || props.preview ? (
        <div
          style={{
            zIndex: 1,
            right: 0,
            bottom: 0,
            position: 'absolute',
            textAlign: 'center'
          }}
        >
          <IconButton
            style={{
              display: 'block'
            }}
            onClick={() => {
              if ((screenfull as any).isFullscreen && props.exitIfFullscreen) {
                (screenfull as any).toggle();
              }
              props.onNext();
            }}
          >
            <ArrowRightAltIcon style={{ color: 'green', fontSize: 50 }} />
          </IconButton>
          {/* <Typography variant="caption">Next</Typography> */}
        </div>
      ) : (
        <div
          style={{
            zIndex: 1,
            left: 0,
            bottom: 0,
            position: 'absolute',
            textAlign: 'center'
          }}
        >
          {submitting ? (
            <CircularProgress />
          ) : (
            <IconButton
              style={{
                display: 'block'
              }}
              onClick={() => {
                setSubmitting(true);
                props.onCaptures(captures, matches, percent, elapsed, () => {
                  setSubmitting(false);
                });
              }}
            >
              <CheckCircleIcon style={{ color: 'green', fontSize: 50 }} />
            </IconButton>
          )}
          {/* <Typography variant="caption">Submit</Typography> */}
        </div>
      )}

      <IconButton
        style={{
          zIndex: 1,
          left: 0,
          top: 0,
          position: 'absolute'
        }}
        onClick={() => {
          if ((screenfull as any).isEnabled) {
            (screenfull as any).toggle();
          }
        }}
      >
        {fullscreen ? (
          <FullScreenExitIcon style={{ color: 'red', fontSize: 50 }} />
        ) : (
          <FullScreenIcon style={{ color: 'red', fontSize: 50 }} />
        )}
      </IconButton>

      {resultsAvailable && (
        <div
          style={{
            position: 'absolute',
            top: imgHeight / 2,
            right: 0,
            transform: 'translateY(-75px)'
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <RadialChart data={chartAngles} width={150} height={150} />
            <Typography variant="h4" style={{ position: 'absolute' }}>
              {percent}%
            </Typography>
          </div>
          <Paper
            style={{
              opacity: 1,
              display: 'flex',
              alignItems: 'center',
              padding: 4,
              background: 'rgba(255,255,255,0.5)'
            }}
            square
          >
            <div
              style={{
                height: 20,
                width: 20,
                opacity: 0.7,
                backgroundColor: 'green',
                borderRadius: 20
              }}
            />
            <Typography variant="caption">
              &nbsp;Your Answer&nbsp;&nbsp;
            </Typography>
            <div
              style={{
                height: 20,
                width: 20,
                opacity: 0.5,
                backgroundColor: 'red',
                borderRadius: 20
              }}
            />
            <Typography variant="caption">&nbsp;Predefined</Typography>
          </Paper>
        </div>
      )}
    </div>
  );
}

SpotIdentification.defaultProps = {
  preview: false
};
