import React, { Suspense } from 'react';
import { useRouteMatch, Switch } from 'react-router-dom';
import { MicrolearningSessions } from './micro-learning/MicrolearningSessions';
import SideNavWrapper from 'components/WithSideNavWrapper';
import { PrivateRoute } from 'components/PrivateRoute';
import { FirebaseAuthRoute } from 'components/FirebaseAuth';

const MicrolearningSessionData = React.lazy(() =>
  import('./micro-learning/MicrolearningSessionData')
);

const CovidInitiation = React.lazy(() =>
  import('./covid-declaration/CovidInitiation')
);

export function ParallelLearning() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute exact path={`${path}/micro-learning-sessions`}>
        <SideNavWrapper>
          <MicrolearningSessions />
        </SideNavWrapper>
      </PrivateRoute>
      <PrivateRoute path={`${path}/micro-learning-sessions/:id`}>
        <Suspense fallback={<div>Loading...</div>}>
          <MicrolearningSessionData />
        </Suspense>
      </PrivateRoute>
      <FirebaseAuthRoute path={`${path}/covid-declaration`}>
        <Suspense fallback={<div>Loading...</div>}>
          <CovidInitiation />
        </Suspense>
      </FirebaseAuthRoute>
    </Switch>
  );
}
