import React from 'react';
import { FieldProps } from 'formik';
import { TextField } from '@material-ui/core';

export function FormikTextField(
  props: FieldProps & {
    label: string;
    placeholder: string;
    type?: string;
    size?: 'small' | 'medium' | undefined;
  }
) {
  const { field, form, ...rest } = props;
  const { label, placeholder, type, size } = rest;
  return (
    <TextField
      variant="outlined"
      fullWidth
      {...field}
      {...{ label, placeholder, type: type || 'text', size: size || 'medium' }}
      error={!!form.errors[field.name]}
      helperText={form.errors[field.name]}
    />
  );
}
