import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { Grid, Typography, Button } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { FormikTextField } from './FormFields/FormikTextField';

export function PhoneAuth() {
  const [
    confirmationResult,
    setConfirmationResult,
  ] = useState<firebase.auth.ConfirmationResult | null>(null);
  const history = useHistory();
  let location: any = useLocation();
  let { from } = location.state || { from: { pathname: '/' } };

  useEffect(() => {
    firebase.auth().useDeviceLanguage();
    (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: (response: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      }
    );
    // firebase.auth().signOut();
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        history.replace(from);
      }
    });
    return () => {
      unsubscribe();
      (window as any).recaptchaVerifier = null;
    };
  }, [from, history]);

  return (
    <div style={{ marginTop: 150 }}>
      <Formik
        initialValues={{
          mobileNumber: '+91',
          confirmCode: '',
        }}
        onSubmit={(values, actions) => {
          actions.setStatus(null);
          confirmationResult?.confirm(values.confirmCode).catch((error) => {
            actions.setStatus({
              type: 'Error',
              message: 'Confirmation code is either invalid or expired',
            });
          });
        }}
        validationSchema={() =>
          object({
            mobileNumber: string().label('Mobile number').required(),
          })
        }
      >
        {(formikProps) => (
          <Grid container justify="center">
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Typography align="center" variant="h3">
                Sign In
              </Typography>
              <br />
              <Form>
                {!confirmationResult && (
                  <>
                    <Field
                      label="Mobile number"
                      name="mobileNumber"
                      placeholder="Enter mobile number"
                      component={FormikTextField}
                    />
                    {formikProps.status && formikProps.status.type === 'Error' && (
                      <Typography color="error" variant="caption">
                        {(formikProps as any).status.message}
                      </Typography>
                    )}
                    <br />
                    <br />
                    <Button
                      id="sign-in-button"
                      style={{ width: '100%' }}
                      onClick={() => {
                        const appVerifier = (window as any).recaptchaVerifier;
                        firebase
                          .auth()
                          .signInWithPhoneNumber(
                            formikProps.values.mobileNumber,
                            appVerifier
                          )
                          .then((confirmationResult) => {
                            // SMS sent. Prompt user to type the code from the message, then sign the
                            // user in with confirmationResult.confirm(code).
                            formikProps.setStatus(null);
                            setConfirmationResult(confirmationResult);
                          })
                          .catch(function (error) {
                            formikProps.setStatus({
                              type: 'Error',
                              message: 'Please check your mobile number',
                            });
                          });
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Verify mobile number
                    </Button>
                  </>
                )}
                {confirmationResult && (
                  <>
                    <Field
                      label="Confirmation code"
                      name="confirmCode"
                      placeholder="Enter confirmation code"
                      component={FormikTextField}
                    />
                    {formikProps.status && formikProps.status.type === 'Error' && (
                      <Typography color="error" variant="caption">
                        {(formikProps as any).status.message}
                      </Typography>
                    )}
                    <br />
                    <br />
                    <Button
                      style={{ width: '100%' }}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Login
                    </Button>
                  </>
                )}
              </Form>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
}
