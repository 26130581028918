import { useState, useEffect } from 'react';

function useDeviceOrientation() {
  const [deviceOrientation, setDeviceOrientation] = useState<
    null | 'portrait' | 'landscape'
  >(null);

  useEffect(() => {
    function updateState() {
      const { height, width } = {
        height: window.innerHeight,
        width: window.innerWidth
      };
      if (height >= width) {
        setDeviceOrientation('portrait');
      } else {
        setDeviceOrientation('landscape');
      }
    }

    updateState(); // for initial render
    window.addEventListener('resize', updateState);
    return () => window.removeEventListener('resize', updateState);
  }, []);

  return deviceOrientation;
}

export default useDeviceOrientation;
