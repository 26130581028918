import React, { useState, useContext, useEffect } from 'react';
import {
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
  useHistory,
  useParams
} from 'react-router-dom';
import { MicroLearningVideo } from '../../micro-learning/MicroLearningVideo';
// import { QuizQuestions } from './QuizQuestions';
import { MicroLearningQuiz } from '../../micro-learning/MicroLearningQuiz';
import { AlertContext } from 'App';
import { MultipleChoiceQuestionWithImage } from '../../micro-learning/MultipleChoiceQuestionWithImage';
import { HazardSuggestions } from '../../micro-learning/HazardSuggestions';
import { ExperienceRating } from '../../micro-learning/ExperienceRating';
import { FeedbackFour } from '../../micro-learning/FeedbackFour';

type SectionType = 'VIDEO' | 'SPOT_IDENTIFICATION' | 'QUIZ';

const SpotIdentification = React.lazy(() =>
  import('../../micro-learning/SpotIdentification')
);

export function MicrolearningSessionPreview({ data }: any) {
  const { id } = useParams();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const [index, setIndex] = useState(0);
  const { showAlert } = useContext(AlertContext);

  useEffect(() => {
    if (index === 0 && pathname !== `${pathname}/${data.sections[0].id}`) {
      const location = path.replace(':id', id as string);
      history.replace(`${location}/${data.sections[0].id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, id, index, path, pathname]);

  const getSelection = (section: { type: SectionType; id: string }) => {
    if (section.type === 'VIDEO') {
      return (
        <MicroLearningVideo
          sectionInfo={data.details[section.id]}
          onNext={() => {
            if (index === data.sections.length - 1) {
              showAlert(
                {
                  message:
                    'Thank you for completing this session successfully. We will see you next week with a new session.',
                  type: 'Info'
                },
                () =>
                  history.push(
                    `/parallel-learning/micro-learning-sessions/${id}`
                  )
              );
            } else {
              history.push(
                pathname.replace(section.id, data.sections[index + 1].id)
              );
              setIndex(index + 1);
            }
          }}
        />
      );
    } else if (section.type === 'QUIZ') {
      return (
        <MicroLearningQuiz
          preview
          onSubmit={async (answers: any, score: number, cb: any) => {
            try {
              //
            } catch (err) {
              console.error(err);
            } finally {
              cb();
            }
          }}
          quiz={data.details[section.id].quiz}
          onNext={() => {
            if (index === data.sections.length - 1) {
              showAlert(
                {
                  message:
                    'Thank you for completing this session successfully. We will see you next week with a new session.',
                  type: 'Info'
                },
                () =>
                  history.push(
                    `/parallel-learning/micro-learning-sessions/${id}`
                  )
              );
            } else {
              history.push(
                pathname.replace(section.id, data.sections[index + 1].id)
              );
              setIndex(index + 1);
            }
          }}
          results={null}
        />
      );
    } else if (section.type === 'SPOT_IDENTIFICATION') {
      const info = data.details[section.id];
      return (
        <SpotIdentification
          preview
          key={1}
          predefs={info.predefs}
          imgUrl={info.img}
          dims={info.dimensions}
          onNext={() => {
            if (index === data.sections.length - 1) {
              showAlert(
                {
                  message:
                    'Thank you for completing this session successfully. We will see you next week with a new session.',
                  type: 'Info'
                },
                () =>
                  history.push(
                    `/parallel-learning/micro-learning-sessions/${id}`
                  )
              );
            } else {
              history.push(
                pathname.replace(section.id, data.sections[index + 1].id)
              );
              setIndex(index + 1);
            }
          }}
          exitIfFullscreen={false}
          showTestHelper={true}
          onCaptures={async (captures, matches, score, elapsedTime, cb) => {
            try {
              //
            } catch (err) {
              console.error(err);
            } finally {
              cb();
            }
          }}
          results={[]}
          title="Hazard Identification"
        />
      );
    } else if (section.type === 'IMG_QUIZ_ITEM') {
      const info = data.details[section.id];
      return (
        <MultipleChoiceQuestionWithImage
          preview
          imgUrl={info.image}
          question={info}
          onSubmit={async (answers, score, cb) => {
            try {
              //
            } catch (err) {
              console.error(err);
            } finally {
              cb();
            }
          }}
          onNext={() => {
            if (index === data.sections.length - 1) {
              showAlert(
                {
                  message:
                    'Thank you for completing this session successfully. We will see you next week with a new session.',
                  type: 'Info'
                },
                () =>
                  history.push(
                    `/parallel-learning/micro-learning-sessions/${id}`
                  )
              );
            } else {
              history.push(
                pathname.replace(section.id, data.sections[index + 1].id)
              );
              setIndex(index + 1);
            }
          }}
          results={null}
        />
      );
    } else if (section.type === 'HAZARD_SUGGESTION') {
      return (
        <HazardSuggestions
          preview
          onSubmit={async (answers, cb) => {
            try {
              //
            } catch (err) {
              console.error(err);
            } finally {
              cb();
            }
          }}
          onNext={() => {
            if (index === data.sections.length - 1) {
              showAlert(
                {
                  message:
                    'Thank you for completing this session successfully. We will see you next week with a new session.',
                  type: 'Info'
                },
                () =>
                  history.push(
                    `/parallel-learning/micro-learning-sessions/${id}`
                  )
              );
            } else {
              history.push(
                pathname.replace(section.id, data.sections[index + 1].id)
              );
              setIndex(index + 1);
            }
          }}
          results={null}
        />
      );
    } else if (section.type === 'EXPERIENCE_RATING') {
      return (
        <ExperienceRating
          preview
          onSubmit={async (answers, cb) => {
            try {
              //
            } catch (err) {
              console.error(err);
            } finally {
              cb();
            }
          }}
          onNext={() => {
            if (index === data.sections.length - 1) {
              showAlert(
                {
                  message:
                    'Thank you for completing this session successfully. We will see you next week with a new session.',
                  type: 'Info'
                },
                () =>
                  history.push(
                    `/parallel-learning/micro-learning-sessions/${id}`
                  )
              );
            } else {
              history.push(
                pathname.replace(section.id, data.sections[index + 1].id)
              );
              setIndex(index + 1);
            }
          }}
          results={null}
        />
      );
    } else if (section.type === 'FEEDBACK_FOUR') {
      return (
        <FeedbackFour
          preview
          onSubmit={async (answers, cb) => {
            try {
              //
            } catch (err) {
              console.error(err);
            } finally {
              cb();
            }
          }}
          onNext={() => {
            if (index === data.sections.length - 1) {
              showAlert(
                {
                  message: `Great! You have completed your program. You will get your certificate online soon. 
            Successful participants will receive a surprise gift. 
            Thank you for your participation and wish you safe day today and everyday. `,
                  type: 'Info'
                },
                () =>
                  history.push(
                    `/parallel-learning/micro-learning-sessions/${id}`
                  )
              );
            } else {
              history.push(
                pathname.replace(section.id, data.sections[index + 1].id)
              );
              setIndex(index + 1);
            }
          }}
          results={null}
        />
      );
    }
    return null;
  };

  return (
    <div>
      <Route exact path={path}>
        <Redirect to={`${pathname}/${data.sections[0].id}`} />
      </Route>
      {data.sections.map((section: any) => {
        return (
          <Route key={section.id} path={`${path}/${section.id}`}>
            {getSelection(section)}
          </Route>
        );
      })}
    </div>
  );
}
