import React, { Suspense } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

const RoadSafety2020Company = React.lazy(() => {
  return import('./RoadSafety2020Company');
});

export function RoadSafety2020() {
  const { path } = useRouteMatch();
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Route path={`${path}/:company`}>
          <RoadSafety2020Company />
        </Route>
      </Suspense>
    </div>
  );
}
