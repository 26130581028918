import React from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  CreateOneScromInstance,
  CreateOneScromInstanceVariables,
} from '__generated__/CreateOneScromInstance';
import {
  DeleteOneScromInstance,
  DeleteOneScromInstanceVariables,
} from '__generated__/DeleteOneScromInstance';
import {
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Paper,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import { ScromInstances } from '__generated__/ScromInstances';
import { LoadingOrError } from 'components/LoadingOrError';
import DeleteIcon from '@material-ui/icons/Delete';
import firebase from 'firebase/app';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { useHistory, useRouteMatch, Route, useParams } from 'react-router-dom';
import {
  ScromInstance,
  ScromInstanceVariables,
} from '__generated__/ScromInstance';

const CREATE_SCROM_INSTANCE = gql`
  mutation CreateOneScromInstance($name: String!, $s3Url: String!) {
    createOneScromInstance(data: { name: $name, s3Url: $s3Url }) {
      id
      name
    }
  }
`;

const DELETE_SCROM_INSTANCE = gql`
  mutation DeleteOneScromInstance($id: String!) {
    deleteOneScromInstance(where: { id: $id }) {
      id
      name
    }
  }
`;

const QUERY_SCROM_INSTANCES = gql`
  query ScromInstances {
    scromInstances {
      id
      name
      s3Url
    }
  }
`;

const QUERY_SCROM_INSTANCE_SINGLE = gql`
  query ScromInstance($id: String!) {
    scromInstance(where: { id: $id }) {
      id
      name
      s3Url
      entries {
        id
        commit
        user {
          id
          email
        }
      }
    }
  }
`;

function ScromMangerConsole() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [createOneScromInstance] = useMutation<
    CreateOneScromInstance,
    CreateOneScromInstanceVariables
  >(CREATE_SCROM_INSTANCE);
  const [deleteOneScromInstance] = useMutation<
    DeleteOneScromInstance,
    DeleteOneScromInstanceVariables
  >(DELETE_SCROM_INSTANCE);
  const { loading, data, error } = useQuery<ScromInstances>(
    QUERY_SCROM_INSTANCES
  );

  if (error || loading) {
    return <LoadingOrError {...{ error, loading }} />;
  }

  return (
    <div style={{ padding: 16 }}>
      <Formik
        initialValues={{ name: '', s3Url: '' }}
        validationSchema={() =>
          object({
            name: string().required(),
            s3Url: string().required(),
          })
        }
        onSubmit={async (values, actions) => {
          await createOneScromInstance({
            variables: values,
            refetchQueries: [{ query: QUERY_SCROM_INSTANCES }],
          });
          actions.resetForm();
        }}
      >
        {(formikProps) => {
          return (
            <Form>
              <TextField
                fullWidth
                label="Package name"
                variant="outlined"
                name={'name'}
                value={formikProps.values.name}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                size="small"
              />
              <br />
              <br />
              <TextField
                size="small"
                fullWidth
                label="S3 URL"
                variant="outlined"
                name={'s3Url'}
                value={formikProps.values.s3Url}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
              />
              <br />
              <br />
              <Button variant="outlined" type="submit">
                Submit
              </Button>
            </Form>
          );
        }}
      </Formik>
      <br />
      {data && data.scromInstances && (
        <List>
          {data.scromInstances.map((item) => (
            <ListItem
              key={item.id}
              button
              onClick={async () => {
                const idToken = await firebase.auth().currentUser?.getIdToken();
                window.location.href = `https://parallellearning-scrom-packages.s3.ap-south-1.amazonaws.com/scrom-runner/index.html?scromUrl=${item.s3Url}&idToken=${idToken}&id=${item.id}&referrer=${window.location.href}`;
              }}
            >
              <ListItemText primary={item.name} secondary={item.s3Url} />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => {
                    history.push(`${path}/${item.id}`);
                  }}
                >
                  <AssessmentIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    deleteOneScromInstance({
                      variables: { id: item.id },
                      refetchQueries: [{ query: QUERY_SCROM_INSTANCES }],
                    });
                  }}
                  edge="end"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
}

function ScromMangerSingle() {
  const { id }: any = useParams();
  const { loading, error, data } = useQuery<
    ScromInstance,
    ScromInstanceVariables
  >(QUERY_SCROM_INSTANCE_SINGLE, { variables: { id } });

  if (loading || error) {
    return <LoadingOrError {...{ loading, error }} />;
  }

  return data && data.scromInstance ? (
    <div style={{ padding: 16 }}>
      <Typography variant="h3">{data.scromInstance.name}</Typography>
      <Typography>{data.scromInstance.s3Url}</Typography>
      <br />
      {data.scromInstance.entries.map((item) => (
        <Paper square style={{ padding: 8, marginBottom: 8 }} key={item.id}>
          <Typography variant="h5">{item.user.email}</Typography>
          <Typography>
            Score:{' '}
            <strong>
              {item.commit.score.raw || 0}/{item.commit.score.max || 0}
            </strong>
          </Typography>
          <Typography>
            Current lesson location:{' '}
            <strong>{item.commit.lesson_location}</strong>
          </Typography>
        </Paper>
      ))}
    </div>
  ) : null;
}

export function ScromManagement() {
  const { path } = useRouteMatch();
  return (
    <div>
      <Route exact path={path}>
        <ScromMangerConsole />
      </Route>
      <Route exact path={`${path}/:id`}>
        <ScromMangerSingle />
      </Route>
    </div>
  );
}
