import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
  Typography,
  Hidden
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import ArrowForward from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(0)
  }
}));

type QuizItem = {
  id: string;
  image: string;
  background: string;
  multichoice: boolean;
  question: string;
  choices: { key: string; text: string; isCorrect: boolean }[];
};

export function MicroLearningQuiz({
  quiz,
  onSubmit,
  onNext,
  results,
  preview
}: {
  preview?: boolean;
  quiz: QuizItem[];
  onSubmit: (answers: any, score: number, cb: any) => void;
  onNext: () => void;
  results: any;
}) {
  const classes = useStyles({});
  const [qIndex, setQIndex] = useState(0);

  return (
    <Formik
      enableReinitialize
      initialValues={
        results
          ? results.answers
          : quiz.reduce((acc, item) => {
              return {
                ...acc,
                [item.id]: item.multichoice
                  ? item.choices.reduce(
                      (acc, choice) => ({ ...acc, [choice.key]: false }),
                      {}
                    )
                  : ''
              };
            }, {})
      }
      validationSchema={Yup.object().shape(
        quiz.reduce((acc, item) => {
          return {
            ...acc,
            [item.id]: item.multichoice
              ? Yup.object()
                  .label('Answer')
                  .test('at-least-one-checked', 'Answer is required', value => {
                    return Object.keys(value).reduce((acc: any, key: any) => {
                      return acc || value[key];
                    }, false);
                  })
              : Yup.string().required('Answer is required')
          };
        }, {})
      )}
      onSubmit={(values, { setSubmitting }) => {
        const score = Object.keys(values).reduce((scoreAcc: any, key: any) => {
          const question = quiz.find(item => {
            return item.id === key;
          }) as QuizItem;

          if (question.multichoice) {
            const isCorrect = question.choices.reduce((acc, choice) => {
              return acc && values[key][choice.key] === choice.isCorrect;
            }, true);
            if (isCorrect) {
              return scoreAcc + 1;
            }
            return scoreAcc;
          } else {
            const isCorrect = question.choices.reduce((acc, choice) => {
              if (!acc) {
                return choice.isCorrect && choice.key === values[key];
              }
              return acc;
            }, false);
            if (isCorrect) {
              return scoreAcc + 1;
            }
            return scoreAcc;
          }
        }, 0);
        onSubmit(values, Math.floor((score / quiz.length) * 100), () => {
          setSubmitting(false);
        });
      }}
    >
      {({
        handleSubmit,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        isSubmitting,
        isValid,
        errors,
        dirty
      }) => {
        const question = quiz[qIndex];
        // console.log(values);
        return (
          <form
            onSubmit={handleSubmit}
            style={{
              ...(question.background && {
                backgroundImage: `url(${encodeURI(question.background)})`
              }),
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              height: '100vh',
              width: '100vw'
            }}
          >
            <Grid container style={{ padding: 16 }} spacing={3}>
              {question.image && (
                <Grid item sm={12} md={6}>
                  <div style={{ textAlign: 'center' }}>
                    <Hidden xsDown>
                      <img
                        key={question.image}
                        style={{ maxWidth: 350, textAlign: 'center' }}
                        src={question.image}
                        alt="quiz-reference"
                      />
                    </Hidden>
                    <Hidden smUp>
                      <img
                        key={question.image}
                        style={{ width: 250 }}
                        src={question.image}
                        alt="quiz-reference"
                      />
                    </Hidden>
                  </div>
                </Grid>
              )}
              <Grid item sm={12} md={question.image ? 6 : 12}>
                {question.multichoice ? (
                  <FormControl
                    disabled={!!results}
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel component="legend">
                      {question.question}
                    </FormLabel>
                    <FormGroup>
                      {question.choices.map(choice => (
                        <FormControlLabel
                          key={choice.key}
                          control={
                            <Checkbox
                              checked={values[question.id][choice.key]}
                              onChange={(e: any) => {
                                setFieldValue(question.id, {
                                  ...values[question.id],
                                  [choice.key]: e.target.checked
                                });
                              }}
                              value={choice.key}
                            />
                          }
                          label={`${choice.text}${
                            !!results && choice.isCorrect
                              ? ' (Correct Answer)'
                              : ''
                          }`}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                ) : (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      {question.question}
                    </FormLabel>
                    <RadioGroup
                      aria-label="quiz-question"
                      name={'' + question.id}
                      value={values[question.id]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {question.choices.map(opt => (
                        <FormControlLabel
                          disabled={!!results}
                          key={opt.key}
                          value={opt.key}
                          control={<Radio />}
                          label={`${opt.text}${
                            !!results && opt.isCorrect
                              ? ' (Correct Answer)'
                              : ''
                          }`}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                )}
                <br />
                <br />
                <div>
                  {qIndex !== 0 && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setQIndex(qIndex - 1);
                      }}
                      startIcon={<ArrowBack />}
                    >
                      Back
                    </Button>
                  )}
                  &nbsp;&nbsp;
                  {qIndex !== quiz.length - 1 && (
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={(!!errors[question.id] || !dirty) && !results}
                      onClick={() => {
                        setQIndex(qIndex + 1);
                      }}
                      endIcon={<ArrowForward />}
                    >
                      Next
                    </Button>
                  )}
                  {(!!results && qIndex === quiz.length - 1) ||
                  (preview && qIndex === quiz.length - 1) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        onNext();
                      }}
                      endIcon={<ArrowForward />}
                    >
                      Next
                    </Button>
                  ) : null}
                  {!results && qIndex === quiz.length - 1 && (
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={!isValid || isSubmitting}
                    >
                      Submit
                    </Button>
                  )}
                  <br />
                  <br />
                  {!!results && qIndex === quiz.length - 1 && (
                    <Typography>Your scored {results.score}%</Typography>
                  )}
                </div>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
}

MicroLearningQuiz.defaultProps = {
  preview: false
};
