import React, { Suspense, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  Typography,
  Button,
  Hidden,
  Grid,
} from '@material-ui/core';
import { Route, useRouteMatch } from 'react-router-dom';
import { useWindowSize } from 'hooks/useWindowSize';

const HealthTravel = React.lazy(() => import('./HealthTravel'));
const CovidTraining = React.lazy(() => import('./CovidTraining'));

function LanguageChooser(props: any) {
  const [lng, setLng]: any = useState(() => localStorage.getItem('lng') || '');
  const { i18n } = useTranslation();
  const { width, height } = useWindowSize();

  useEffect(() => {
    if (lng) {
      localStorage.setItem('lng', lng);
      i18n.changeLanguage(lng);
    }
  }, [i18n, lng]);

  if (!lng) {
    return (
      <div>
        <Hidden smUp>
          <div
            style={{
              padding: 16,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* <img
              style={{ height: 80 }}
              src="https://storage.googleapis.com/pl-public-assets/companies/oerlikon/logo.svg"
              alt="logo"
            /> */}
            <img
              src="https://storage.googleapis.com/pl-public-assets/covid-schindler.svg"
              alt="covid-art"
            />
            <br />
            <Typography variant="h4" align="center">
              Travel and Fitness Declaration by Employees.
            </Typography>
            <Typography>Please select the language to continue.</Typography>
            <br />
            <Button
              style={{
                borderRadius: 20,
                width: 120,
                background:
                  'linear-gradient(90deg, rgba(122,144,213,1) 0%, rgba(84,107,197,1) 70%, rgba(59,81,163,1) 100%)',
                border: 'none',
                textTransform: 'capitalize',
              }}
              variant="outlined"
              onClick={() => setLng('en')}
            >
              English
            </Button>
            <br />
            <Button
              style={{
                borderRadius: 20,
                width: 120,
                background:
                  'linear-gradient(90deg, rgba(122,144,213,1) 0%, rgba(84,107,197,1) 70%, rgba(59,81,163,1) 100%)',
                border: 'none',
                textTransform: 'capitalize',
              }}
              variant="outlined"
              onClick={() => setLng('mr')}
            >
              मराठी
            </Button>
          </div>
        </Hidden>
        <Hidden xsDown>
          <div
            style={{
              width,
              height,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={5}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {/* <img
                  style={{ height: 80 }}
                  src="https://storage.googleapis.com/pl-public-assets/companies/oerlikon/logo.svg"
                  alt="logo"
                /> */}
                <br />
                <Typography variant="h4" align="center">
                  Travel and Fitness Declaration by Employees.
                </Typography>
                <Typography>Please select the language to continue.</Typography>
                <br />
                <div>
                  <Button
                    style={{
                      borderRadius: 20,
                      width: 120,
                      background:
                        'linear-gradient(90deg, rgba(122,144,213,1) 0%, rgba(84,107,197,1) 70%, rgba(59,81,163,1) 100%)',
                      border: 'none',
                      textTransform: 'capitalize',
                    }}
                    variant="outlined"
                    onClick={() => setLng('en')}
                  >
                    English
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    style={{
                      borderRadius: 20,
                      width: 120,
                      background:
                        'linear-gradient(90deg, rgba(122,144,213,1) 0%, rgba(84,107,197,1) 70%, rgba(59,81,163,1) 100%)',
                      border: 'none',
                      textTransform: 'capitalize',
                    }}
                    variant="outlined"
                    onClick={() => setLng('mr')}
                  >
                    मराठी
                  </Button>
                </div>
              </Grid>
              <Grid item xs={7} style={{ textAlign: 'center' }}>
                <img
                  style={{ maxWidth: 600 }}
                  src="https://storage.googleapis.com/pl-public-assets/covid-schindler.svg"
                  alt="covid-art"
                />
              </Grid>
            </Grid>
          </div>
        </Hidden>
      </div>
    );
  }

  return props.children;
}

function Oerlikon() {
  const { path } = useRouteMatch();

  return (
    <div>
      <Route path={`${path}/covid-training`}>
        <Suspense fallback={<CircularProgress size={20} />}>
          <CovidTraining />
        </Suspense>
      </Route>
      <Route path={`${path}/health-travel`}>
        <Suspense fallback={<CircularProgress size={20} />}>
          <LanguageChooser>
            <HealthTravel />
          </LanguageChooser>
        </Suspense>
      </Route>
    </div>
  );
}

export default Oerlikon;
