import React, { useEffect } from 'react';
import {
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
  Link as RouterLink,
  useParams
} from 'react-router-dom';
import { MLData } from './MLData';
import { MLSchedule } from './MLSchedule';
import { MLCourseStudents } from './MLCourseStudents';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { useWindowSize } from 'hooks/useWindowSize';

function a11yProps(index: number) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`
  };
}

export function MLCourse() {
  const { course, cid }: any = useParams();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { contentWidth } = useWindowSize();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const base = path.replace(':cid', cid).replace(':course', course);

  useEffect(() => {
    if (pathname.includes('/students')) {
      setValue(1);
    } else if (pathname.includes('/data')) {
      setValue(2);
    }
  }, [pathname]);

  return (
    <div>
      <AppBar position="static" style={{ width: contentWidth }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="nav tabs example"
        >
          <Tab
            label="Schedule"
            to={`${base}/schedule`}
            {...a11yProps(0)}
            component={RouterLink}
          />
          <Tab
            label="Students"
            to={`${base}/students`}
            component={RouterLink}
            {...a11yProps(1)}
          />
          <Tab
            label="Data"
            to={`${base}/data`}
            component={RouterLink}
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <Route exact path={path}>
        <Redirect to={`${pathname}/schedule`} />
      </Route>
      <Route path={`${path}/schedule`}>
        <MLSchedule />
      </Route>
      <Route path={`${path}/students`}>
        <MLCourseStudents />
      </Route>
      <Route path={`${path}/data`}>
        <MLData />
      </Route>
    </div>
  );
}
