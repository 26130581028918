import React, { useCallback } from 'react';
import { useRouteMatch, Route, Link as RouterLink } from 'react-router-dom';
import gql from 'graphql-tag';
import {
  CompaniesWithAdmins,
  CompaniesWithAdmins_companies,
} from '__generated__/CompaniesWithAdmins';
import { useQuery } from '@apollo/react-hooks';
import { LoadingOrError } from 'components/LoadingOrError';
import { useWindowSize } from 'hooks/useWindowSize';
import {
  Link,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import { NewCompany } from './NewCompany';
import { CompanyAdmins } from './CompanyAdmins';
import { CompanyLicenses } from './CompanyLicenses';
import { PermissionGroupsList } from './PermissionGroupsList';
import { DefaultColumnFilter } from 'components/company-admin/Users';
import { useSortBy, useFilters, useTable, usePagination } from 'react-table';
import ArrowUpwordIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwordIcon from '@material-ui/icons/ArrowDownward';
import { TablePaginationActions } from 'components/TablePaginationActions';
import { useSpring, animated } from 'react-spring';

const AnimatedTableRow = animated(TableRow);

const COMPANY_ADMINS = gql`
  query CompaniesWithAdmins {
    companies {
      id
      name
      admins {
        id
        userName
        email
      }
    }
  }
`;

function CompanyList({
  companies,
}: {
  companies: CompaniesWithAdmins_companies[];
}) {
  const [{ st }, set] = useSpring(() => ({ st: 0 }));
  const onScroll = useCallback(
    (e) => {
      set({ st: e.target.scrollTop });
    },
    [set]
  );
  const { contentWidth: width, contentHeight: height } = useWindowSize();
  const columns = React.useMemo(
    () => [
      {
        id: 'SrNo',
        Header: 'Sr. No',
        accessor: (_item: any, index: number) => {
          return index + 1;
        },
        disableFilters: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Admins',
        accessor: 'admins',
        Cell: (data: any) => {
          return (
            <ul>
              {data.cell.row.original.admins.map((admin: any) => {
                return (
                  <li key={admin.id}>
                    {admin.userName} | {admin.email}
                  </li>
                );
              })}
            </ul>
          );
        },
        disableSorting: true,
        disableFilters: true,
      },
      {
        id: 'action',
        Header: 'Action',
        accessor: (item: any, _index: any) => {
          return item.id;
        },
        Cell: (data: any) => {
          return (
            <div>
              <Link
                to={`/super-admin/company-admins/${data.cell.row.original.id}`}
                component={RouterLink}
              >
                Manage Admins
              </Link>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link
                to={`/super-admin/company-licenses/${data.cell.row.original.id}`}
                component={RouterLink}
              >
                Manage Licenses
              </Link>
            </div>
          );
        },
        disableSorting: true,
        disableFilters: true,
      },
    ],
    []
  );

  const defaultColumn: any = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    headerGroups,
    // rows,
    prepareRow,
    page,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: companies,
      initialState: { sortBy: [{ id: 'SrNo', desc: false }], pageSize: 25 },
      defaultColumn,
    } as any,
    useFilters,
    useSortBy,
    usePagination
  ) as any;

  function handleChangePage(_event: any, newPage: any) {
    gotoPage(newPage);
  }

  function handleChangeRowsPerPage(event: any) {
    setPageSize(+event.target.value);
    gotoPage(0);
  }

  const topRowStyle = st.interpolate((o) => `translateY(${o}px)`);

  return (
    <div>
      <div
        style={{ height: height - 52, overflowY: 'scroll', width }}
        onScroll={onScroll}
      >
        <TableContainer component={Paper}>
          <Table {...getTableProps()} size="small">
            <TableHead>
              {headerGroups.map((headerGroup: any) => (
                <AnimatedTableRow
                  {...headerGroup.getHeaderGroupProps()}
                  style={{
                    transform: topRowStyle,
                    backgroundColor: '#fff',
                    zIndex: 2000,
                    boxShadow:
                      'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)',
                  }}
                >
                  {headerGroup.headers.map((column: any) => (
                    <TableCell {...column.getHeaderProps()}>
                      <div
                        style={{ display: 'flex', alignItems: 'center' }}
                        {...column.getSortByToggleProps()}
                      >
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDownwordIcon style={{ fontSize: 14 }} />
                          ) : (
                            <ArrowUpwordIcon style={{ fontSize: 14 }} />
                          )
                        ) : (
                          ''
                        )}
                        {column.render('Header')}
                      </div>
                      <div>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </TableCell>
                  ))}
                </AnimatedTableRow>
              ))}
            </TableHead>
            <TableBody>
              {page.map(
                (row: any) =>
                  prepareRow(row) || (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return (
                          <TableCell {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div></div>
      <TablePagination
        rowsPerPageOptions={[25, 35, 50]}
        component="div"
        count={companies.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}

export function SuperAdmin() {
  const { path } = useRouteMatch();
  const { loading, error, data } = useQuery<CompaniesWithAdmins>(
    COMPANY_ADMINS
  );
  // const { contentWidth: width } = useWindowSize();

  if (data && data.companies) {
    return (
      <div>
        <Route exact path={path}>
          <CompanyList companies={data.companies} />
          {/* <ul style={{ width }}>
            <li style={{ listStyle: 'none', marginBottom: 12 }}>
              <Button
                component={RouterLink}
                to="/super-admin/new-company"
                size="small"
                variant="outlined"
              >
                New Company
              </Button>
              &nbsp;&nbsp;
              <Button
                component={RouterLink}
                to="/super-admin/permission-groups"
                size="small"
                variant="outlined"
              >
                Permission Groups
              </Button>
            </li>
            {data.companies
              .sort((a, b) => {
                return a.name.localeCompare(b.name);
              })
              .map(company => {
                return (
                  <li key={company.id}>
                    <Typography variant="body2">{company.name}</Typography>
                    <ul>
                      {company.admins &&
                        company.admins.map(admin => {
                          return (
                            <li key={admin.id}>
                              {admin.userName} | {admin.email}
                            </li>
                          );
                        })}
                    </ul>
                    <div>
                      <Link
                        to={`/super-admin/company-admins/${company.id}`}
                        component={RouterLink}
                      >
                        Manage Admins
                      </Link>
                      &nbsp;&nbsp;
                      <Link
                        to={`/super-admin/company-licenses/${company.id}`}
                        component={RouterLink}
                      >
                        Manage Licenses
                      </Link>
                    </div>
                    <br />
                  </li>
                );
              })}
          </ul> */}
        </Route>
        <Route path={`${path}/new-company`}>
          <NewCompany />
        </Route>
        <Route path={`${path}/company-admins/:cid`}>
          <CompanyAdmins />
        </Route>
        <Route path={`${path}/company-licenses/:cid`}>
          <CompanyLicenses />
        </Route>
        <Route path={`${path}/permission-groups`}>
          <PermissionGroupsList />
        </Route>
      </div>
    );
  }

  return <LoadingOrError {...{ loading, error }} />;
}
