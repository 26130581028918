import React, { useRef, useEffect, useState } from 'react';
import { ReactComponent as SpinWheelSVG } from './spinwheel.svg';
import { useWindowSize } from 'hooks/useWindowSize';
import { snap } from '@popmotion/popcorn';
import {
  Animated,
  PanResponder,
  View,
  Easing,
  Text,
  TouchableOpacity
} from 'react-native';
import Lottie from 'react-lottie';
import animationData from './1728-swipe-left-to-right.json';
import screenfull from 'screenfull';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import FullScreenExitIcon from '@material-ui/icons/FullscreenExit';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useHistory } from 'react-router-dom';

const questions = [
  {
    q: 'Thinner vapors can cause _____',
    options: [
      { key: 'a', text: 'Headache' },
      { key: 'b', text: 'Back Injury' },
      { key: 'c', text: 'Lung problems' },
      { key: 'd', text: 'Burn injuries' }
    ],
    correct: 'c'
  },
  {
    q: 'Fire triangle completes with',
    options: [
      { key: 'a', text: 'CO2+WATER + SPARK' },
      { key: 'b', text: 'LPG +HCL+ELECTRICITY' },
      { key: 'c', text: 'O2+ BURNING MATTER+ HEAT' },
      { key: 'd', text: 'All' }
    ],
    correct: 'c'
  },
  {
    q: 'LPG is ____ than air.',
    options: [
      { key: 'a', text: 'Lighter' },
      { key: 'b', text: 'Heavier' },
      { key: 'c', text: 'Hotter' },
      { key: 'd', text: 'Visible' }
    ],
    correct: 'b'
  },
  {
    q: 'Which type of lock used to control a hazardous energy ___________.',
    options: [
      { key: 'a', text: 'Green' },
      { key: 'b', text: 'Yellow' },
      { key: 'c', text: 'White' },
      { key: 'd', text: 'None of these' }
    ],
    correct: 'a'
  },
  {
    q: 'Full Body Safety Harness is used for  ________ work.',
    options: [
      { key: 'a', text: 'Height' },
      { key: 'b', text: 'Welding' },
      { key: 'c', text: 'Hot' },
      { key: 'd', text: 'None of these' }
    ],
    correct: 'a'
  },
  {
    q:
      'To protect from electrical shock, we should provide _____ in the Circuit.',
    options: [
      { key: 'a', text: 'Fuse' },
      { key: 'b', text: 'Switch' },
      { key: 'c', text: 'Earth Leakage Circuit Breaker' },
      { key: 'd', text: 'Millimeter' }
    ],
    correct: 'c'
  },
  {
    q: 'Workers representatives represents in   _________.',
    options: [
      { key: 'a', text: 'PC- Safety Council' },
      { key: 'b', text: 'Safety Committee' },
      { key: 'c', text: 'Core Team' },
      { key: 'd', text: 'Union – monthly meeting' }
    ],
    correct: 'b'
  },
  {
    q: 'Name the card  given to a visitor while entering the gate.',
    options: [
      { key: 'a', text: 'Visitor Protocol' },
      { key: 'b', text: 'Induction card' },
      { key: 'c', text: 'Safety card' },
      { key: 'd', text: 'SOT card' }
    ],
    correct: 'a'
  },
  {
    q: 'While using a lifting tackle first we should check?',
    options: [
      { key: 'a', text: 'Condition' },
      { key: 'b', text: 'Identification no.' },
      { key: 'c', text: 'SWL' },
      { key: 'd', text: 'All the points' }
    ],
    correct: 'd'
  },
  {
    q:
      'At elevated work platform / area railing height shall be of min _______',
    options: [
      { key: 'a', text: '1.1 meter' },
      { key: 'b', text: '1.5 meter' },
      { key: 'c', text: '0.6 meter' },
      { key: 'd', text: '2.0 meter' }
    ],
    correct: 'a'
  },
  {
    q: 'Thinner vapors can cause _____',
    options: [
      { key: 'a', text: 'Headache' },
      { key: 'b', text: 'Back Injury' },
      { key: 'c', text: 'Lung problems' },
      { key: 'd', text: 'Burn injuries' }
    ],
    correct: 'c'
  },
  {
    q: 'Fire triangle completes with',
    options: [
      { key: 'a', text: 'CO2+WATER + SPARK' },
      { key: 'b', text: 'LPG +HCL+ELECTRICITY' },
      { key: 'c', text: 'O2+ BURNING MATTER+ HEAT' },
      { key: 'd', text: 'All' }
    ],
    correct: 'c'
  },
  {
    q: 'LPG is ____ than air.',
    options: [
      { key: 'a', text: 'Lighter' },
      { key: 'b', text: 'Heavier' },
      { key: 'c', text: 'Hotter' },
      { key: 'd', text: 'Visible' }
    ],
    correct: 'b'
  },
  {
    q: 'Which type of lock used to control a hazardous energy ___________.',
    options: [
      { key: 'a', text: 'Green' },
      { key: 'b', text: 'Yellow' },
      { key: 'c', text: 'White' },
      { key: 'd', text: 'None of these' }
    ],
    correct: 'a'
  },
  {
    q: 'Full Body Safety Harness is used for  ________ work.',
    options: [
      { key: 'a', text: 'Height' },
      { key: 'b', text: 'Welding' },
      { key: 'c', text: 'Hot' },
      { key: 'd', text: 'None of these' }
    ],
    correct: 'a'
  },
  {
    q:
      'To protect from electrical shock, we should provide _____ in the Circuit.',
    options: [
      { key: 'a', text: 'Fuse' },
      { key: 'b', text: 'Switch' },
      { key: 'c', text: 'Earth Leakage Circuit Breaker' },
      { key: 'd', text: 'Millimeter' }
    ],
    correct: 'c'
  },
  {
    q: 'Workers representatives represents in   _________.',
    options: [
      { key: 'a', text: 'PC- Safety Council' },
      { key: 'b', text: 'Safety Committee' },
      { key: 'c', text: 'Core Team' },
      { key: 'd', text: 'Union – monthly meeting' }
    ],
    correct: 'b'
  },
  {
    q: 'Name the card  given to a visitor while entering the gate.',
    options: [
      { key: 'a', text: 'Visitor Protocol' },
      { key: 'b', text: 'Induction card' },
      { key: 'c', text: 'Safety card' },
      { key: 'd', text: 'SOT card' }
    ],
    correct: 'a'
  },
  {
    q: 'While using a lifting tackle first we should check?',
    options: [
      { key: 'a', text: 'Condition' },
      { key: 'b', text: 'Identification no.' },
      { key: 'c', text: 'SWL' },
      { key: 'd', text: 'All the points' }
    ],
    correct: 'd'
  },
  {
    q:
      'At elevated work platform / area railing height shall be of min _______',
    options: [
      { key: 'a', text: '1.1 meter' },
      { key: 'b', text: '1.5 meter' },
      { key: 'c', text: '0.6 meter' },
      { key: 'd', text: '2.0 meter' }
    ],
    correct: 'a'
  }
];

function Question(props: any) {
  const { width, height } = useWindowSize();
  const _translateY = useRef(new Animated.Value(-height));

  useEffect(() => {
    Animated.timing(_translateY.current, {
      toValue: 0,
      duration: 1500,
      easing: Easing.elastic(1)
    }).start();
  }, []);

  console.log(props.question);

  return (
    <Animated.View
      style={{
        height,
        width,
        transform: [{ translateY: _translateY.current }]
      }}
    >
      <Text
        style={{
          textAlign: 'center',
          marginTop: 32,
          fontSize: 16,
          fontWeight: 'bold'
        }}
      >
        {props.question.q}
      </Text>
      <View>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridTemplateRows: 'repeat(2, 1fr)',
            gridGap: 16,
            padding: 16,
            height: 250
          }}
        >
          {props.question.options.map((opt: any) => {
            return (
              <TouchableOpacity
                style={{
                  backgroundColor: '#d0b9a7',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 4,
                  borderRadius: 12
                }}
                key={opt.key}
                onPress={() => {
                  props.setQuestion(null);
                }}
              >
                <View>
                  <Text
                    style={{
                      fontSize: 22,
                      fontWeight: 'bold',
                      color: '#fff',
                      textAlign: 'center'
                    }}
                  >
                    {opt.text}
                  </Text>
                </View>
              </TouchableOpacity>
            );
          })}
        </div>
      </View>
    </Animated.View>
  );
}

export function SpinWheel() {
  const { width, height } = useWindowSize();
  const [fullscreen, setFullScreen] = useState(false);
  const numberOfSegments = 16;
  const oneTurn = 360;
  const angleBySegment = oneTurn / numberOfSegments;
  const history = useHistory();
  const [question, setQuestion] = useState<null | number>(null);

  const _getWinnerIndex = () => {
    const deg = Math.abs(Math.round(angle.current % oneTurn));
    // wheel turning counterclockwise
    if (angle.current < 0) {
      return Math.floor(deg / angleBySegment);
    }
    // wheel turning clockwise
    return (
      (numberOfSegments - Math.floor(deg / angleBySegment)) % numberOfSegments
    );
  };

  const _angle = useRef(new Animated.Value(0));
  const angle = useRef(0);
  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: evt => true,
      onPanResponderMove: () => {},
      onPanResponderRelease: (evt, gestureState) => {
        const { vy: velocityY } = gestureState;

        Animated.decay(_angle.current, {
          velocity: velocityY * 500,
          deceleration: 0.999,
          useNativeDriver: true
        }).start(() => {
          _angle.current.setValue(angle.current % oneTurn);
          const snapTo = snap(oneTurn / numberOfSegments);
          Animated.timing(_angle.current, {
            toValue: snapTo(angle.current),
            duration: 300,
            useNativeDriver: true
          }).start(() => {
            const winnerIndex = _getWinnerIndex();
            setTimeout(() => {
              setQuestion(winnerIndex + 1);
            }, 500);
          });
        });
      }
    })
  );

  useEffect(() => {
    const callback = () => {
      setFullScreen((screenfull as any).isFullscreen);
    };
    if ((screenfull as any).isEnabled) {
      (screenfull as any).on('change', callback);
    }
    return () => {
      (screenfull as any).off('change', callback);
    };
  }, []);

  useEffect(() => {
    _angle.current.addListener(event => {
      angle.current = event.value;
    });
  }, []);

  return (
    <View
      style={{
        flex: 1,
        height,
        width,
        position: 'relative',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {question === null && (
        <View style={{ position: 'absolute', left: 0, top: height / 2 - 25 }}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
            height={50}
            width={155}
          />
        </View>
      )}
      <div
        style={{
          position: 'absolute',
          right: 0,
          width: 100,
          textAlign: 'center',
          top: 0,
          padding: 8,
          zIndex: 5,
          borderRadius: 12,
          backgroundColor: 'rgba(0,0,0,0.5)',
          margin: 8
        }}
      >
        <IconButton
          size="small"
          onClick={() => {
            if ((screenfull as any).isEnabled) {
              (screenfull as any).toggle();
            }
          }}
        >
          {fullscreen ? (
            <FullScreenExitIcon style={{ color: 'red' }} />
          ) : (
            <FullScreenIcon style={{ color: 'red' }} />
          )}
        </IconButton>
        &nbsp;&nbsp;
        <IconButton
          size="small"
          onClick={() => {
            history.push('/safety-week-2020');
          }}
        >
          <ClearIcon style={{ color: 'red' }} />
        </IconButton>
      </div>
      {question === null && (
        <Animated.View
          {...panResponder.current.panHandlers}
          style={{
            height,
            width: height,
            borderRadius: height,
            transform: [
              {
                rotate: _angle.current.interpolate({
                  inputRange: [-oneTurn, 0, oneTurn],
                  outputRange: [`-${oneTurn}deg`, `0deg`, `${oneTurn}deg`]
                })
              }
            ]
          }}
        >
          <SpinWheelSVG />
        </Animated.View>
      )}
      {question && (
        <Question question={questions[question]} setQuestion={setQuestion} />
      )}
    </View>
  );
}
