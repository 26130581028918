import React from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  UpdateOnePermissionGroup,
  UpdateOnePermissionGroupVariables
} from '__generated__/UpdateOnePermissionGroup';
import { PermissionGroups } from '__generated__/PermissionGroups';
import {
  CreateOnePermissionGroupSuperAdmin,
  CreateOnePermissionGroupSuperAdminVariables
} from '__generated__/CreateOnePermissionGroupSuperAdmin';
import { LoadingOrError } from 'components/LoadingOrError';
import {
  Link,
  Typography,
  IconButton,
  TextField,
  Button
} from '@material-ui/core';
import {
  useLocation,
  Link as RouterLink,
  Route,
  useRouteMatch,
  useParams
} from 'react-router-dom';
import {
  PermissionGroupQuery,
  PermissionGroupQueryVariables
} from '__generated__/PermissionGroupQuery';
import DeleteIcon from '@material-ui/icons/Delete';
import { PeoplePicker } from 'components/PeoplePicker';
import { Formik } from 'formik';
import * as Yup from 'yup';

const CREATE_PERMISSION_GROUP = gql`
  mutation CreateOnePermissionGroupSuperAdmin($name: String!) {
    createOnePermissionGroup(data: { name: $name }) {
      id
      createdAt
      name
    }
  }
`;

const PERMISSION_GROUP_QUERY = gql`
  query PermissionGroups {
    permissionGroups {
      id
      createdAt
      name
    }
  }
`;

const UPDATE_GROUP_PERMISSION = gql`
  mutation UpdateOnePermissionGroup(
    $connect: [UserWhereUniqueInput!]
    $disconnect: [UserWhereUniqueInput!]
    $id: String!
  ) {
    updateOnePermissionGroup(
      data: { members: { connect: $connect, disconnect: $disconnect } }
      where: { id: $id }
    ) {
      id
      createdAt
      name
    }
  }
`;

const SINGLE_PERMISSION_QUERY = gql`
  query PermissionGroupQuery($id: String!) {
    permissionGroup(where: { id: $id }) {
      id
      createdAt
      name
      members {
        email
        id
        userName
      }
    }
  }
`;

function PermissionGroupMembers() {
  const { gid } = useParams();
  const [updateOnePermissionGroup] = useMutation<
    UpdateOnePermissionGroup,
    UpdateOnePermissionGroupVariables
  >(UPDATE_GROUP_PERMISSION);
  const { data, loading, error } = useQuery<
    PermissionGroupQuery,
    PermissionGroupQueryVariables
  >(SINGLE_PERMISSION_QUERY, { variables: { id: gid as string } });

  if (data && data.permissionGroup) {
    return (
      <div style={{ padding: 16 }}>
        <Typography variant="h3">{data.permissionGroup.name}</Typography>
        <br />
        <PeoplePicker
          variant="outlined"
          company={''}
          clearOnSelection
          onChange={user => {
            updateOnePermissionGroup({
              variables: {
                id: gid as string,
                connect: [{ id: user.id }]
              },
              refetchQueries: [
                {
                  query: SINGLE_PERMISSION_QUERY,
                  variables: { id: gid as string }
                }
              ]
            });
          }}
        />
        <ul>
          {data.permissionGroup.members.map(member => {
            return (
              <li key={member.id}>
                {member.email}&nbsp;&nbsp;
                <IconButton
                  size="small"
                  onClick={() => {
                    updateOnePermissionGroup({
                      variables: {
                        id: gid as string,
                        disconnect: [{ id: member.id }]
                      },
                      refetchQueries: [
                        {
                          query: SINGLE_PERMISSION_QUERY,
                          variables: { id: gid as string }
                        }
                      ]
                    });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return <LoadingOrError {...{ loading, error }} />;
}

export function PermissionGroupsList() {
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const [createOnePermissionGroup] = useMutation<
    CreateOnePermissionGroupSuperAdmin,
    CreateOnePermissionGroupSuperAdminVariables
  >(CREATE_PERMISSION_GROUP);
  const { data, loading, error } = useQuery<PermissionGroups>(
    PERMISSION_GROUP_QUERY
  );

  if (data && data.permissionGroups) {
    return (
      <div>
        <Route exact path={path}>
          <Formik
            initialValues={{ name: '' }}
            validationSchema={() =>
              Yup.object().shape({
                name: Yup.string()
                  .label('Name')
                  .min(5)
                  .required()
              })
            }
            onSubmit={async (values, actions) => {
              await createOnePermissionGroup({
                variables: values,
                update: (cache, { data }) => {
                  if (data && data.createOnePermissionGroup) {
                    const { permissionGroups } = cache.readQuery({
                      query: PERMISSION_GROUP_QUERY
                    }) as any;

                    cache.writeQuery({
                      query: PERMISSION_GROUP_QUERY,
                      data: {
                        permissionGroups: [
                          ...permissionGroups,
                          data.createOnePermissionGroup
                        ]
                      }
                    });
                  }
                }
              });
              actions.resetForm();
            }}
          >
            {formikProps => (
              <form
                onSubmit={formikProps.handleSubmit}
                style={{ display: 'flex', alignItems: 'center', padding: 16 }}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  label="New session"
                  fullWidth
                  value={formikProps.values.name}
                  name="name"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  error={formikProps.touched.name && !!formikProps.errors.name}
                  helperText={
                    formikProps.touched.name && formikProps.errors.name
                  }
                />
                &nbsp;&nbsp;
                <Button variant="contained" color="primary" type="submit">
                  Add
                </Button>
              </form>
            )}
          </Formik>
          <ul>
            {data.permissionGroups.map(item => {
              return (
                <li key={item.id}>
                  <Link to={`${pathname}/${item.id}`} component={RouterLink}>
                    {item.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </Route>
        <Route path={`${path}/:gid`}>
          <PermissionGroupMembers />
        </Route>
      </div>
    );
  }

  return <LoadingOrError {...{ loading, error }} />;
}
