import { useState, useEffect } from 'react';

const pauseInitial = {
  paused: false,
  pauses: []
};

export function useTimer(props: { duration: number; startPaused: boolean }) {
  const [start, setStart] = useState(new Date());
  const [elapsed, setElapsed] = useState(0);
  const [finished, setFinished] = useState(false);
  const [pause, setPause] = useState<{
    paused: boolean;
    pauses: { start: Date; end: Date | null }[];
  }>(
    props.startPaused
      ? {
          paused: true,
          pauses: [{ start: new Date(), end: null }]
        }
      : pauseInitial
  );
  const [ticks, setTicks] = useState(0);

  const resetTimer = () => {
    setStart(new Date());
    setFinished(false);
    setPause(pauseInitial);
  };

  const pauseTimer = () => {
    setPause({
      ...pause,
      paused: true,
      pauses: [...pause.pauses, { start: new Date(), end: null }]
    });
  };

  const resumeTimer = () => {
    setPause({
      paused: false,
      pauses: pause.pauses.map((item, i) => {
        if (i === pause.pauses.length - 1) {
          return {
            ...item,
            end: new Date()
          };
        }
        return item;
      })
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTicks(ticks => ticks + 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const totalPause = pause.pauses.reduce((acc, item) => {
      if (item.start && item.end) {
        return acc + item.end.getTime() - item.start.getTime();
      } else if (item.start && !item.end) {
        return acc + new Date().getTime() - item.start.getTime();
      }
      return acc;
    }, 0);
    const difference = new Date().getTime() - start.getTime() - totalPause;
    if (Math.ceil(difference / 1000) > props.duration) {
      setFinished(true);
      return;
    }
    setElapsed(Math.ceil(difference / 1000));
  }, [ticks, pause, start, props.duration]);

  return {
    elapsed: Math.abs(elapsed),
    paused: pause.paused,
    finished,
    pauseTimer,
    resumeTimer,
    resetTimer
  };
}
