import React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  MicroLearningCourseStudentsVariables,
  MicroLearningCourseStudents
} from '__generated__/MicroLearningCourseStudents';
import {
  UpdateOneMicroLearningCourseStudents,
  UpdateOneMicroLearningCourseStudentsVariables
} from '__generated__/UpdateOneMicroLearningCourseStudents';
import { PeoplePicker } from 'components/PeoplePicker';
import { useParams } from 'react-router-dom';
import { LoadingOrError } from 'components/LoadingOrError';
import { Typography, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const ML_COURSE_STUDENTS_QUERY = gql`
  query MicroLearningCourseStudents($id: String!) {
    microLearningCourse(where: { id: $id }) {
      id
      name
      students {
        id
        email
        userName
      }
    }
  }
`;

const ML_COURSE_UPDATE_STUDENTS = gql`
  mutation UpdateOneMicroLearningCourseStudents(
    $id: String!
    $connect: [UserWhereUniqueInput!]
    $disconnect: [UserWhereUniqueInput!]
  ) {
    updateOneMicroLearningCourse(
      data: { students: { connect: $connect, disconnect: $disconnect } }
      where: { id: $id }
    ) {
      id
    }
  }
`;

export function MLCourseStudents() {
  const { course, cid } = useParams();
  const { data, loading, error } = useQuery<
    MicroLearningCourseStudents,
    MicroLearningCourseStudentsVariables
  >(ML_COURSE_STUDENTS_QUERY, { variables: { id: course as string } });
  const [updateOneMicroLearningCourse] = useMutation<
    UpdateOneMicroLearningCourseStudents,
    UpdateOneMicroLearningCourseStudentsVariables
  >(ML_COURSE_UPDATE_STUDENTS);

  if (data && data.microLearningCourse) {
    return (
      <div style={{ padding: 16 }}>
        <PeoplePicker
          onChange={user => {
            updateOneMicroLearningCourse({
              variables: {
                id: course as string,
                connect: [{ id: user.id }]
              },
              refetchQueries: [
                {
                  query: ML_COURSE_STUDENTS_QUERY,
                  variables: { id: course as string }
                }
              ]
            });
          }}
          company={cid as string}
          clearOnSelection
          variant="outlined"
        />
        <br />
        <ul>
          {data.microLearningCourse &&
            data.microLearningCourse.students &&
            data.microLearningCourse.students.map(student => {
              return (
                <li key={student.id}>
                  <Typography>
                    {student.email} | {student.userName} &nbsp;&nbsp;
                    <IconButton
                      size="small"
                      onClick={() => {
                        updateOneMicroLearningCourse({
                          variables: {
                            id: course as string,
                            disconnect: [{ id: student.id }]
                          },
                          refetchQueries: [
                            {
                              query: ML_COURSE_STUDENTS_QUERY,
                              variables: { id: course as string }
                            }
                          ]
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Typography>
                </li>
              );
            })}
        </ul>
      </div>
    );
  }
  return <LoadingOrError {...{ loading, error }} />;
}
