import React from 'react';
import {
  Route,
  Link as RouterLink,
  useRouteMatch,
  useLocation,
  useParams
} from 'react-router-dom';
import { Link, Typography, TextField, Button } from '@material-ui/core';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Quizzes, QuizzesVariables } from '__generated__/Quizzes';
import { LoadingOrError } from 'components/LoadingOrError';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  CreateOneQuizVariables,
  CreateOneQuiz
} from '__generated__/CreateOneQuiz';
import { QuizConfiguration } from './QuizConfiguration';

const CREATE_QUIZ_MUTATION = gql`
  mutation CreateOneQuiz($name: String!, $config: String!, $id: String!) {
    createOneQuiz(
      data: { name: $name, config: $config, company: { connect: { id: $id } } }
    ) {
      id
      createdAt
      config
      name
    }
  }
`;

export const QUIZES_QUERY = gql`
  query Quizzes($id: String!) {
    quizzes(where: { company: { id: { equals: $id } } }) {
      id
      name
      config
      createdAt
    }
  }
`;

export function Quiz() {
  const [createOneQuiz] = useMutation<CreateOneQuiz, CreateOneQuizVariables>(
    CREATE_QUIZ_MUTATION
  );
  const { cid }: any = useParams();
  const { loading, error, data } = useQuery<Quizzes, QuizzesVariables>(
    QUIZES_QUERY,
    {
      variables: {
        id: cid
      }
    }
  );
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  if (data && data.quizzes) {
    return (
      <div>
        <Route exact path={path}>
          <Formik
            initialValues={{ name: '' }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required()
            })}
            onSubmit={async ({ name }, actions) => {
              await createOneQuiz({
                variables: { config: JSON.stringify([]), name, id: cid },
                refetchQueries: [
                  { query: QUIZES_QUERY, variables: { id: cid } }
                ]
              });
              actions.resetForm();
            }}
          >
            {formikProps => (
              <form
                onSubmit={formikProps.handleSubmit}
                style={{
                  padding: 16,
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end'
                }}
              >
                <TextField
                  style={{ minWidth: 400 }}
                  fullWidth
                  name="name"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  label="Name"
                />
                &nbsp;&nbsp;
                <Button variant="contained" color="primary" type="submit">
                  Add
                </Button>
              </form>
            )}
          </Formik>
          <ul>
            {data.quizzes.map(qz => (
              <li
                key={qz.id}
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                <Typography>{qz.name}</Typography>&nbsp;&nbsp;&nbsp;&nbsp;
                <Link
                  to={`${pathname}/${qz.id}/configure`}
                  component={RouterLink}
                >
                  Configure
                </Link>
                &nbsp;&nbsp;&nbsp;
                <Link to={`${pathname}/${qz.id}/data`} component={RouterLink}>
                  Data
                </Link>
              </li>
            ))}
          </ul>
        </Route>
        <Route path={`${path}/:qid/configure`}>
          <QuizConfiguration />
        </Route>
        <Route path={`${path}/:qid/data`}>
          <div>Data</div>
        </Route>
      </div>
    );
  }
  return <LoadingOrError {...{ loading, error }} />;
}
