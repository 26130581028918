import React, { Suspense } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

const HealthTravel = React.lazy(() => import('./HealthTravel'));
const HealthTravelChennai = React.lazy(() => import('./HealthTravelChennai'));
const HealthTravelLonand = React.lazy(() => import('./HealthTravelLonand'));
const HealthTravelVimanNagar = React.lazy(() =>
  import('./HealthTravelVimanNagar')
);
const CovidTraining = React.lazy(() => import('./CovidTraining'));
const ContractorPage = React.lazy(() => import('./ContractorPage'));
const UserManagementPage = React.lazy(() => import('./UserManagementPage'));

export function Bekaert() {
  const { path } = useRouteMatch();

  return (
    <div>
      <Route path={`${path}/covid-training`}>
        <Suspense fallback={<CircularProgress size={20} />}>
          <CovidTraining />
        </Suspense>
      </Route>
      <Route path={`${path}/health-travel`}>
        <Suspense fallback={<CircularProgress size={20} />}>
          <HealthTravel />
        </Suspense>
      </Route>
      <Route path={`${path}/health-travel-contractor`}>
        <Suspense fallback={<CircularProgress size={20} />}>
          <ContractorPage />
        </Suspense>
      </Route>
      <Route path={`${path}/user-management`}>
        <Suspense fallback={<CircularProgress size={20} />}>
          <UserManagementPage />
        </Suspense>
      </Route>
      <Route path={`${path}/health-travel-chennai`}>
        <Suspense fallback={<CircularProgress size={20} />}>
          <HealthTravelChennai />
        </Suspense>
      </Route>
      <Route path={`${path}/health-travel-lonand`}>
        <Suspense fallback={<CircularProgress size={20} />}>
          <HealthTravelLonand />
        </Suspense>
      </Route>
      <Route path={`${path}/health-travel-vimannagar`}>
        <Suspense fallback={<CircularProgress size={20} />}>
          <HealthTravelVimanNagar />
        </Suspense>
      </Route>
    </div>
  );
}
