import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import {
  SafetyObservations,
  SafetyObservationsVariables
} from '__generated__/SafetyObservations';
import { LoadingOrError } from 'components/LoadingOrError';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';

const SAFETY_OBSERVATIONS_QUERY = gql`
  query SafetyObservations($id: String!) {
    safetyObservations(where: { company: { id: { equals: $id } } }) {
      id
      safe
      date
      assignTo
      location
      barcode
      category
      date
      description
      highRisk
      immediateActions
      time
      photo {
        id
        height
        key
        name
        size
        type
        width
      }
    }
  }
`;

export function SafetyEyeData() {
  const { cid }: any = useParams();
  const { loading, error, data } = useQuery<
    SafetyObservations,
    SafetyObservationsVariables
  >(SAFETY_OBSERVATIONS_QUERY, {
    variables: {
      id: cid
    }
  });
  if (data && data.safetyObservations) {
    return (
      <div>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr. No.</TableCell>
                <TableCell>Safe</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Assigned To</TableCell>
                <TableCell>High Risk</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Immediate Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.safetyObservations.map((row, i) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {i + 1}
                  </TableCell>
                  <TableCell>{row.safe ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    {format(parseISO(row.date), 'dd/MM/yyyy')}
                  </TableCell>
                  <TableCell>{format(parseISO(row.time), 'hh:mm a')}</TableCell>
                  <TableCell>{row.location}</TableCell>
                  <TableCell>{row.category}</TableCell>
                  <TableCell>{row.assignTo}</TableCell>
                  <TableCell>{row.highRisk ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.immediateActions}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
  return <LoadingOrError {...{ loading, error }} />;
}
