import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { API_URL } from 'index';
import { Typography, TextField, Button, Link } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AlertContext } from 'App';
import { Link as RouterLink } from 'react-router-dom';

export function UserVerification() {
  const params: any = useParams();
  const [validity, setValidity] = useState<'fetching' | 'valid' | 'invalid'>(
    'fetching'
  );
  const { showAlert } = useContext(AlertContext);
  const [passwordSetComplete, setPasswordSetComplete] = useState(false);

  useEffect(() => {
    fetch(`${API_URL}/auth/is-token-valid/${params.token}`)
      .then(async res => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(data => {
        setValidity('valid');
      })
      .catch(async err => {
        setValidity('invalid');
      });
  }, [params.token]);

  if (passwordSetComplete) {
    return (
      <div style={{ padding: 16 }}>
        <Typography>Your password has been set</Typography>
        <Link component={RouterLink} to="/login">
          Go back to login
        </Link>
      </div>
    );
  }

  if (validity === 'invalid') {
    return (
      <div style={{ padding: 40 }}>
        <Typography>The link has expired</Typography>
      </div>
    );
  }

  if (validity === 'valid') {
    return (
      <div style={{ padding: 40 }}>
        <Formik
          initialValues={{ password: '', confirmPassword: '' }}
          validationSchema={() =>
            Yup.object().shape({
              password: Yup.string()
                .label('Password')
                .required(),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required()
            })
          }
          onSubmit={async (values, actions) => {
            await fetch(`${API_URL}/auth/set-password/${params.token}`, {
              method: 'post',
              body: JSON.stringify({ password: values.password }),
              headers: { 'Content-Type': 'application/json' }
            })
              .then(async res => {
                if (!res.ok) {
                  throw res;
                }
                return res.json();
              })
              .then(data => {
                showAlert(
                  {
                    message: 'Password set successfully!',
                    type: 'Success'
                  },
                  () => {
                    setPasswordSetComplete(true);
                  }
                );
              })
              .catch(async err => {
                showAlert({
                  message: 'We could not set your password!',
                  type: 'Error'
                });
              });
          }}
        >
          {formikProps => (
            <form onSubmit={formikProps.handleSubmit}>
              <TextField
                fullWidth
                type="password"
                name="password"
                value={formikProps.values.password}
                label="Password"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={
                  formikProps.touched.password && !!formikProps.errors.password
                }
                helperText={
                  formikProps.touched.password && formikProps.errors.password
                }
              />
              <br />
              <br />
              <TextField
                fullWidth
                type="password"
                name="confirmPassword"
                value={formikProps.values.confirmPassword}
                label="Confirm Password"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                error={
                  formikProps.touched.confirmPassword &&
                  !!formikProps.errors.confirmPassword
                }
                helperText={
                  formikProps.touched.confirmPassword &&
                  formikProps.errors.confirmPassword
                }
              />
              <br />
              <br />
              <Button
                type="submit"
                disabled={formikProps.isSubmitting}
                variant="outlined"
                color="primary"
              >
                Submit
              </Button>
            </form>
          )}
        </Formik>
      </div>
    );
  }

  return null;
}
