import React, { useEffect, useContext } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { UpsertOnePermissionGroup } from '__generated__/UpsertOnePermissionGroup';
import { AddMemberToPermissionGroup } from '__generated__/AddMemberToPermissionGroup';
import { PermissionGroup } from '__generated__/PermissionGroup';
import { LoadingOrError } from './LoadingOrError';
import { UserContext } from '../App';
import { Typography, IconButton, Link } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { PeoplePicker } from './PeoplePicker';
import { Link as RouterLink } from 'react-router-dom';

const CREATE_GROUP_IF_NOT_EXISTS = gql`
  mutation UpsertOnePermissionGroup($name: String!) {
    upsertOnePermissionGroup(
      where: { name: $name }
      create: { name: $name }
      update: { name: $name }
    ) {
      name
    }
  }
`;

const ADD_REMOVE_MEMBER_QUERY = gql`
  mutation AddMemberToPermissionGroup(
    $connect: [UserWhereUniqueInput!]
    $disconnect: [UserWhereUniqueInput!]
    $name: String!
  ) {
    updateOnePermissionGroup(
      data: { members: { connect: $connect, disconnect: $disconnect } }
      where: { name: $name }
    ) {
      id
    }
  }
`;

export const PERMISSION_GROUP_QUERY = gql`
  query PermissionGroup($name: String!) {
    permissionGroup(where: { name: $name }) {
      id
      name
      members {
        id
        userName
        email
      }
    }
  }
`;

function AccessList(props: { gcbucket: string }) {
  const [upsertOnePermissionGroup] = useMutation<UpsertOnePermissionGroup>(
    CREATE_GROUP_IF_NOT_EXISTS
  );
  const { data, loading, error } = useQuery<PermissionGroup>(
    PERMISSION_GROUP_QUERY,
    {
      variables: {
        name: props.gcbucket,
      },
    }
  );
  const [updateOnePermissionGroup] = useMutation<AddMemberToPermissionGroup>(
    ADD_REMOVE_MEMBER_QUERY
  );

  useEffect(() => {
    upsertOnePermissionGroup({
      variables: {
        name: props.gcbucket,
      },
    });
  }, [props.gcbucket, upsertOnePermissionGroup]);

  if (loading || error) {
    return <LoadingOrError {...{ loading, error }} />;
  }

  return (
    <div style={{ padding: 16 }}>
      <Typography variant="h5">Access</Typography>
      <PeoplePicker
        clearOnSelection={true}
        variant="standard"
        company={undefined}
        onChange={(user) => {
          updateOnePermissionGroup({
            variables: {
              connect: [{ id: user.id }],
              name: props.gcbucket,
            },
            refetchQueries: [
              {
                query: PERMISSION_GROUP_QUERY,
                variables: {
                  name: props.gcbucket,
                },
              },
            ],
          });
        }}
      />
      <ul>
        {data &&
          data.permissionGroup &&
          Array.isArray(data.permissionGroup.members) &&
          data.permissionGroup.members.map((member) => {
            return (
              <li
                key={member.id}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Typography>{member.email}</Typography>&nbsp;&nbsp;
                <IconButton
                  size="small"
                  onClick={() => {
                    updateOnePermissionGroup({
                      variables: {
                        disconnect: [{ id: member.id }],
                        name: props.gcbucket,
                      },
                      refetchQueries: [
                        {
                          query: PERMISSION_GROUP_QUERY,
                          variables: {
                            name: props.gcbucket,
                          },
                        },
                      ],
                    });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </li>
            );
          })}
      </ul>
      <Link to={`/gcloud/${props.gcbucket}`} component={RouterLink}>
        Back to Videos
      </Link>
    </div>
  );
}

export function GCBucketItemListUsers() {
  const params: any = useParams();
  const { user } = useContext(UserContext);

  const { data, loading, error } = useQuery<PermissionGroup>(
    PERMISSION_GROUP_QUERY,
    {
      variables: {
        name: 'gcloud_user_access_mgmt',
      },
    }
  );

  if (loading || error) {
    return <LoadingOrError {...{ loading, error }} />;
  }

  if (
    data &&
    data.permissionGroup &&
    data.permissionGroup.members.find((member) => member.email === user.email)
  ) {
    return <AccessList gcbucket={params.gcbucket} />;
  }
  return <Redirect to="/login" />;
}
