import React from 'react';
import { Typography, Button, Link } from '@material-ui/core';
import firebase from 'firebase/app';
import { useHistory, useLocation, Link as RouterLink } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';

export function NotRegisteredBekaertRanjangaon() {
  const history = useHistory();
  const user = firebase.auth().currentUser;
  let location: any = useLocation();
  let { from } = location.state || { from: { pathname: '/' } };
  const client = useApolloClient();

  return (
    <div style={{ padding: 32 }}>
      <Typography variant="h5">
        We could not find a registered Employee entry for your Phone
        Number/Email&nbsp; {user ? user.phoneNumber : null}
      </Typography>
      <br />
      <Typography>
        If you are an unregistered Company Employee please call on +91
        7887883247
      </Typography>
      <br />
      <Typography>Want to try with another number?</Typography>
      <Button
        onClick={() => {
          client.cache.reset();
          firebase.auth().signOut();
          history.push('/auth/firebase-login', { from });
        }}
        variant="outlined"
      >
        Sign Out
      </Button>
    </div>
  );
}
