import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { MicroLearningSessions } from '__generated__/MicroLearningSessions';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { LoadingOrError } from 'components/LoadingOrError';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  UpdateOneMicroLearningCourseSchedule,
  UpdateOneMicroLearningCourseScheduleVariables
} from '__generated__/UpdateOneMicroLearningCourseSchedule';
import { DatePicker } from '@material-ui/pickers';
import {
  UpdateOneMicroLearningSchedule,
  UpdateOneMicroLearningScheduleVariables
} from '__generated__/UpdateOneMicroLearningSchedule';
import {
  DeleteOneMicroLearningSchedule,
  DeleteOneMicroLearningScheduleVariables
} from '__generated__/DeleteOneMicroLearningSchedule';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  MicroLearningSchedules,
  MicroLearningSchedulesVariables
} from '__generated__/MicroLearningSchedules';

export const DELETE_SCHEDULE = gql`
  mutation DeleteOneMicroLearningSchedule($id: String!) {
    deleteOneMicroLearningSchedule(where: { id: $id }) {
      id
    }
  }
`;

export const ML_SESSIONS = gql`
  query MicroLearningSessions {
    microLearningSessions {
      id
      name
    }
  }
`;

export const ML_SCHEDULES_QUERY = gql`
  query MicroLearningSchedules($microLearningCourse: String!) {
    microLearningSchedules(
      where: { microLearningCourse: { id: { equals: $microLearningCourse } } }
      orderBy: { dateTime: asc }
    ) {
      id
      dateTime
      session {
        id
        name
      }
    }
  }
`;

export const UPDATE_COURSE_MUTATION = gql`
  mutation UpdateOneMicroLearningCourseSchedule(
    $dateTime: DateTime!
    $session: String!
    $id: String!
  ) {
    updateOneMicroLearningCourse(
      data: {
        microLearningSchedule: {
          create: {
            dateTime: $dateTime
            session: { connect: { id: $session } }
          }
        }
      }
      where: { id: $id }
    ) {
      id
    }
  }
`;

export const UPDATE_DATE_SCHEDULE_MUTATION = gql`
  mutation UpdateOneMicroLearningSchedule($dateTime: DateTime!, $id: String!) {
    updateOneMicroLearningSchedule(
      data: { dateTime: $dateTime }
      where: { id: $id }
    ) {
      id
    }
  }
`;

export function MLSchedule() {
  const { data, loading, error } = useQuery<MicroLearningSessions>(ML_SESSIONS);
  const { course } = useParams();
  const { data: courseData } = useQuery<
    MicroLearningSchedules,
    MicroLearningSchedulesVariables
  >(ML_SCHEDULES_QUERY, {
    variables: { microLearningCourse: course as string }
  });
  const [updateOneMicroLearningCourse] = useMutation<
    UpdateOneMicroLearningCourseSchedule,
    UpdateOneMicroLearningCourseScheduleVariables
  >(UPDATE_COURSE_MUTATION);
  const [updateOneMicroLearningSchedule] = useMutation<
    UpdateOneMicroLearningSchedule,
    UpdateOneMicroLearningScheduleVariables
  >(UPDATE_DATE_SCHEDULE_MUTATION);
  const [deleteOneMicroLearningSchedule] = useMutation<
    DeleteOneMicroLearningSchedule,
    DeleteOneMicroLearningScheduleVariables
  >(DELETE_SCHEDULE);

  if (data && data.microLearningSessions) {
    return (
      <div style={{ padding: 16 }}>
        <Formik
          initialValues={{ session: '' }}
          validationSchema={Yup.object().shape({
            session: Yup.string().required()
          })}
          onSubmit={async (values, actions) => {
            await updateOneMicroLearningCourse({
              variables: {
                session: values.session,
                dateTime: new Date(),
                id: course as string
              },
              refetchQueries: [
                {
                  query: ML_SCHEDULES_QUERY,
                  variables: { microLearningCourse: course as string }
                }
              ]
            });
            actions.resetForm();
          }}
        >
          {formikProps => (
            <form
              onSubmit={formikProps.handleSubmit}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <FormControl style={{ minWidth: 200 }}>
                <InputLabel id="demo-simple-select-label">
                  Choose session
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="session"
                  name="session"
                  value={formikProps.values.session}
                  onChange={formikProps.handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {data.microLearningSessions.map(sess => {
                    return (
                      <MenuItem key={sess.id} value={sess.id}>
                        {sess.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              &nbsp;&nbsp;
              <Button type="submit" variant="contained">
                Add
              </Button>
            </form>
          )}
        </Formik>
        <br />
        <br />
        {courseData && courseData.microLearningSchedules && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Session Name</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {courseData.microLearningSchedules.map(row => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.session.name}
                    </TableCell>
                    <TableCell>
                      <DatePicker
                        format="dd/MM/yyyy"
                        label="Date for session"
                        value={row.dateTime}
                        onChange={async (dateTime: any) => {
                          await updateOneMicroLearningSchedule({
                            variables: {
                              dateTime,
                              id: row.id
                            },
                            refetchQueries: [
                              {
                                query: ML_SCHEDULES_QUERY,
                                variables: {
                                  microLearningCourse: course as string
                                }
                              }
                            ]
                          });
                        }}
                        animateYearScrolling
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          deleteOneMicroLearningSchedule({
                            variables: {
                              id: row.id
                            },
                            refetchQueries: [
                              {
                                query: ML_SCHEDULES_QUERY,
                                variables: {
                                  microLearningCourse: course as string
                                }
                              }
                            ]
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    );
  }
  return <LoadingOrError {...{ loading, error }} />;
}
