import React from 'react';
import { useRouteMatch, Route } from 'react-router-dom';
import { LiftTools } from './LiftTools';
import RoadSafety2020 from './RoadSafety2020';
import RoadSafety2021 from './RoadSafety2021';

export function JCB() {
  const { path } = useRouteMatch();
  return (
    <div>
      <Route exact path={path}></Route>
      <Route path={`${path}/lift-tools`}>
        <LiftTools />
      </Route>
      <Route path={`${path}/road-safety-2020`}>
        <RoadSafety2020 />
      </Route>
      <Route path={`${path}/road-safety-2021`}>
        <RoadSafety2021 />
      </Route>
    </div>
  );
}
