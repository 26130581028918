import React, { useState, useCallback } from 'react';
import { animated } from 'react-spring';

const initial = Array.from(Array(10).keys()).map(() =>
  Math.round(Math.random() * 40)
);

const sleep = (waitTime: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, waitTime);
  });

export function TestNewAuth() {
  const [list, setList] = useState(initial);
  const [index, setIndex] = useState([0, 0]);
  const [swapping, setSwapping] = useState(true);

  const sort = async () => {
    const state = [...initial];
    let temp;
    for (let i = 0; i < state.length - 1; i++) {
      for (let j = 0; j < state.length - 1 - i; j++) {
        setIndex([i, j]);
        if (state[j] > state[j + 1]) {
          setSwapping(true);
          temp = state[j];
          state[j] = state[j + 1];
          state[j + 1] = temp;
          setList([...state]);
          sleep(500);
          setSwapping(false);
        }
        await sleep(1000);
      }
    }
  };

  const renderList = useCallback(
    (num, i) => {
      const backgroundColor =
        i === index[1] || i === index[1] + 1
          ? swapping
            ? 'yellow'
            : 'red'
          : 'green';
      return swapping ? (
        <animated.div
          style={{
            padding: 4,
            margin: 4,
            border: '1px solid black',
            width: 32,
            backgroundColor,
          }}
          key={`${i}-`}
        >
          {num}$
        </animated.div>
      ) : (
        <animated.div
          style={{
            padding: 4,
            margin: 4,
            border: '1px solid black',
            width: 32,
            backgroundColor: 'yellow',
          }}
          key={i}
        >
          {num}
        </animated.div>
      );
    },
    [index, swapping]
  );

  return (
    <div className="App">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {list.map(renderList)}
      </div>
      <br />
      <button onClick={sort}>Sort</button>
    </div>
  );
}
