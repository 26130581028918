import React, { useEffect, useRef } from 'react';
import { useSpring, animated, interpolate } from 'react-spring';
import { useGesture } from 'react-use-gesture';

export function Draggable({
  imgHeight,
  imgWidth,
  onFinalPosition,
  diameter,
  isLast,
  initOffsetX
}: {
  diameter: number;
  initOffsetX: number;
  imgHeight: number;
  imgWidth: number;
  onFinalPosition: (x: number, y: number) => void;
  isLast: boolean;
}) {
  const [{ x, y }, setXY] = useSpring(() => ({
    x: 0,
    y: 0,
    config: {
      tension: 70
    }
  }));
  const ref: any = useRef(null);
  const bindGesture: any = useGesture(
    {
      onDrag: ({ event, delta, last, direction, offset: [offX, offY] }) => {
        const setY = offY / imgHeight;
        const setX = offX / imgWidth;
        setXY({ x: setX, y: setY });

        if (last) {
          if (
            setX > imgWidth ||
            setX < 0 ||
            setY < -imgHeight / 2 ||
            setY > imgHeight / 2
          ) {
            return;
          }
          onFinalPosition(setX - diameter / 2 / imgWidth, 1 / 2 + setY);
        }
      }
    },
    { domTarget: ref }
  );
  useEffect(bindGesture, [bindGesture]);

  return (
    <animated.div
      {...bindGesture()}
      ref={ref}
      style={{
        zIndex: 5,
        height: diameter - 1,
        width: diameter - 1,
        cursor: 'grab',
        // background: isLast ? 'rgba(0,0,0,0.4)' : 'transparent',
        background: interpolate([x, y], (xNum, yNum) =>
          isLast ||
          xNum > diameter / imgWidth ||
          Math.abs(yNum) > diameter / imgHeight
            ? 'rgba(0,0,0,0.4)'
            : 'transparent'
        ),
        borderRadius: '50%',
        position: 'absolute',
        top: imgHeight / 2 - diameter / 2,
        border: '1px solid red',
        boxSizing: 'border-box',
        left: -diameter / 2,
        transform: interpolate(
          [x, y],
          (xNum, yNum) =>
            `translate3d(${xNum * imgWidth}px,${yNum * imgHeight}px,0) scale(${
              xNum > diameter / imgWidth ||
              Math.abs(yNum) > diameter / imgHeight
                ? 1
                : 2
            })`
          // `translate3d(${xNum * imgWidth}px,${yNum * imgHeight}px,0) scale(1)`
        )
      }}
    />
  );
}
