import React, { useContext } from 'react';
import gql from 'graphql-tag';
import {
  MyMicroLearningCourses,
  MyMicroLearningCoursesVariables,
} from '__generated__/MyMicroLearningCourses';
import { LoadingOrError } from 'components/LoadingOrError';
import { useQuery } from '@apollo/react-hooks';
import { Typography, Link } from '@material-ui/core';
import { UserContext } from 'App';
import { format, parseISO, isToday, isBefore } from 'date-fns';
import {
  Link as RouterLink,
  useLocation,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import { GET_SESSION_ANSWER } from './MLSessionLive';
import {
  GetMicroLearningSessionAnswers,
  GetMicroLearningSessionAnswersVariables,
} from '__generated__/GetMicroLearningSessionAnswers';

const MY_COURSES_QUERY = gql`
  query MyMicroLearningCourses($id: String!) {
    microLearningCourses(
      where: { students: { some: { id: { equals: $id } } } }
    ) {
      id
      name
      launchesOn
      microLearningSchedule {
        id
        dateTime
        session {
          id
          name
        }
      }
    }
  }
`;

function GetCompletionStatus({
  uid,
  session,
  courseId,
}: {
  uid: string;
  session: string;
  courseId: string;
}) {
  const { data } = useQuery<
    GetMicroLearningSessionAnswers,
    GetMicroLearningSessionAnswersVariables
  >(GET_SESSION_ANSWER, {
    variables: {
      uid,
      session,
      courseId,
    },
  });
  if (
    data &&
    data.microLearningSessionAnswers &&
    data.microLearningSessionAnswers.length
  ) {
    return <div>✓</div>;
  }
  return null;
}

export function MicroLearningUser() {
  const { user } = useContext(UserContext);
  const { data, loading, error } = useQuery<
    MyMicroLearningCourses,
    MyMicroLearningCoursesVariables
  >(MY_COURSES_QUERY, {
    variables: {
      id: user.id,
    },
  });
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  if (data && data.microLearningCourses) {
    return (
      <div>
        <Route exact path={path}>
          <div style={{ padding: 16 }}>
            <ul>
              {data.microLearningCourses.map((course) => {
                return (
                  <div key={course.id}>
                    <Typography variant="h5">{course.name}</Typography>
                    <Typography color="textSecondary">
                      Course starts on:&nbsp;&nbsp;
                      {format(parseISO(course.launchesOn), 'dd-MMM-yy')}
                    </Typography>
                    <ul>
                      {course.microLearningSchedule &&
                        course.microLearningSchedule.map((schedule) => {
                          return isToday(parseISO(schedule.dateTime)) ||
                            isBefore(
                              parseISO(schedule.dateTime),
                              new Date()
                            ) ? (
                            <li
                              key={schedule.id}
                              style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                              }}
                            >
                              <Link
                                to={`${pathname}/${course.id}/${schedule.session.id}`}
                                component={RouterLink}
                              >
                                <Typography>
                                  {schedule.session.name}&nbsp;&nbsp;
                                </Typography>
                              </Link>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                starts&nbsp;
                                {format(
                                  parseISO(schedule.dateTime),
                                  'dd-MMM-yy'
                                )}
                              </Typography>
                              &nbsp;&nbsp;
                              <GetCompletionStatus
                                {...{
                                  courseId: course.id,
                                  uid: user.id,
                                  session: schedule.session.id,
                                }}
                              />
                            </li>
                          ) : (
                            <li
                              key={schedule.id}
                              style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                              }}
                            >
                              <Typography>
                                {schedule.session.name}&nbsp;&nbsp;
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                starts&nbsp;
                                {format(
                                  parseISO(schedule.dateTime),
                                  'dd-MMM-yy'
                                )}
                              </Typography>
                            </li>
                          );
                        })}
                    </ul>
                    <br />
                  </div>
                );
              })}
            </ul>
          </div>
        </Route>
      </div>
    );
  }
  return <LoadingOrError {...{ loading, error }} />;
}
