import React, { useContext } from 'react';
import { USER_FRAGMENT, ME_QUERY } from './PrivateRoute';
import { UserContext } from '../App';
import { useWindowSize } from 'hooks/useWindowSize';
import { AlertContext } from 'App';
import { useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { TextField, Button } from '@material-ui/core';
import { isToday, parseISO } from 'date-fns';
import { DatePicker } from '@material-ui/pickers';

export const UPDATE_PROFILE = gql`
  mutation UpdateOneUser($userId: String!, $update: UserUpdateInput!) {
    updateOneUser(data: $update, where: { id: $userId }) {
      ...UserInfo
    }
  }
  ${USER_FRAGMENT}
`;

export function Profile() {
  const { contentWidth: width } = useWindowSize();
  const { showAlert } = useContext(AlertContext);
  const [updateUser] = useMutation(UPDATE_PROFILE);
  const { user, setUser } = useContext(UserContext);

  return (
    <div style={{ padding: 16, width }}>
      <Formik
        enableReinitialize
        initialValues={{
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          password: '',
          confirmPassword: '',
          userName: user.userName || '',
          birthDate: user.birthDate || new Date(),
          contactNumber: user.contactNumber || '',
        }}
        validationSchema={() =>
          Yup.object().shape({
            userName: Yup.string().required().min(4),
            password: Yup.string().oneOf([Yup.ref('confirmPassword'), null]),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref('password'), null], 'Passwords must match')
              .min(8, 'Password must be at least 8 character long'),
          })
        }
        onSubmit={async (
          { firstName, lastName, password, userName, birthDate, contactNumber },
          { resetForm }
        ) => {
          await updateUser({
            variables: {
              userId: user.id,
              update: {
                firstName,
                lastName,
                userName,
                contactNumber: contactNumber || null,
                ...(password && { password }),
                ...(!isToday(parseISO(birthDate)) && { birthDate }),
              },
            },
            update: (cache, { data: { updateOneUser } }) => {
              setUser(updateOneUser);
              cache.writeQuery({
                query: ME_QUERY,
                data: { me: updateOneUser },
              });
            },
          });
          resetForm();
          showAlert({
            message: 'Profile Saved Successfully',
            type: 'Success',
          });
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                value={values.firstName}
                name="firstName"
                onChange={handleChange}
                onBlur={handleBlur}
                label="First Name"
              />
              <br />
              <br />
              <TextField
                fullWidth
                value={values.lastName}
                name="lastName"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Last Name"
              />
              <br />
              <br />
              <TextField
                fullWidth
                value={values.userName}
                name="userName"
                onChange={handleChange}
                onBlur={handleBlur}
                label="User Name"
                error={!!errors.userName && touched.userName}
                helperText={touched.userName && errors.userName}
              />
              <br />
              <br />
              <TextField
                fullWidth
                value={values.password}
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Password"
                type="password"
                placeholder="Leave this blank if you do not wish to change your password"
              />
              <br />
              <br />
              <TextField
                fullWidth
                value={values.confirmPassword}
                name="confirmPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                label="Confirm Password"
                placeholder="Leave this blank if you do not wish to change your password"
                error={!!errors.confirmPassword && touched.confirmPassword}
                helperText={touched.confirmPassword && errors.confirmPassword}
              />
              <br />
              <br />
              <TextField
                fullWidth
                value={values.contactNumber}
                name="contactNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Contact Number"
              />
              <br />
              <br />
              <DatePicker
                format="dd/MM/yyyy"
                disableFuture
                label="Birth Date"
                value={values.birthDate}
                onChange={(date: any) => setFieldValue('birthDate', date)}
              />
              <br />
              <br />
              <Button type="submit" variant="outlined" color="primary">
                Save
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}
