import React from 'react';
import firebase from 'firebase/app';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';

const ForgotPassword = () => (
  <Formik
    initialValues={{
      email: '',
    }}
    validationSchema={() =>
      Yup.object().shape({
        email: Yup.string().required('Email is required!').email(),
      })
    }
    onSubmit={async (
      values,
      { setSubmitting, setStatus /* setValues and other goodies */ }
    ) => {
      try {
        await firebase.auth().sendPasswordResetEmail(values.email);
        setStatus({ message: 'password reset email sent', type: 'success' });
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        setStatus({ message: error.message, type: 'error' });
      }
    }}
  >
    {({
      values,
      errors,
      touched,
      setFieldValue,
      handleSubmit,
      isSubmitting,
      status,
    }) => (
      <div className="login-container">
        <div style={{ padding: 12, maxWidth: 400, margin: '0 auto' }}>
          <br />
          <Typography component="h1" variant="h5">
            Reset your password
          </Typography>
          <br />
          {status && (
            <div>
              <Alert severity={status.type}>{status.message}</Alert>
              <br />
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              fullWidth
              label="Email"
              required
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
              onChange={(e) => setFieldValue('email', e.target.value)}
              placeholder="Enter email"
              value={values.email}
            />
            <br />
            <br />
            <Button
              disabled={isSubmitting}
              variant="outlined"
              color="primary"
              type="submit"
            >
              Send reset email
            </Button>
          </form>
          <br />
        </div>
      </div>
    )}
  </Formik>
);

export default ForgotPassword;
