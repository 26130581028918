import React, {
  useReducer,
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from 'react';
import {
  View,
  ImageBackground,
  Animated,
  Text,
  TouchableOpacity,
  Easing,
} from 'react-native';
import { useWindowSize } from 'hooks/useWindowSize';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import ReplayIcon from '@material-ui/icons/Replay';
import PersonIcon from '@material-ui/icons/Person';
import ClearIcon from '@material-ui/icons/Clear';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import FullScreenExitIcon from '@material-ui/icons/FullscreenExit';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Button } from '@material-ui/core';
import screenfull from 'screenfull';
import { useHistory } from 'react-router-dom';

const questions = [
  {
    q: 'Thinner vapors can cause _____',
    options: [
      { key: 'a', text: 'Headache' },
      { key: 'b', text: 'Back Injury' },
      { key: 'c', text: 'Lung problems' },
      { key: 'd', text: 'Burn injuries' },
    ],
    correct: 'c',
  },
  {
    q: 'Fire triangle completes with',
    options: [
      { key: 'a', text: 'CO2+WATER + SPARK' },
      { key: 'b', text: 'LPG +HCL+ELECTRICITY' },
      { key: 'c', text: 'O2+ BURNING MATTER+ HEAT' },
      { key: 'd', text: 'All' },
    ],
    correct: 'c',
  },
  {
    q: 'LPG is ____ than air.',
    options: [
      { key: 'a', text: 'Lighter' },
      { key: 'b', text: 'Heavier' },
      { key: 'c', text: 'Hotter' },
      { key: 'd', text: 'Visible' },
    ],
    correct: 'b',
  },
  {
    q: 'Which type of lock used to control a hazardous energy ___________.',
    options: [
      { key: 'a', text: 'Green' },
      { key: 'b', text: 'Yellow' },
      { key: 'c', text: 'White' },
      { key: 'd', text: 'None of these' },
    ],
    correct: 'a',
  },
  {
    q: 'Full Body Safety Harness is used for  ________ work.',
    options: [
      { key: 'a', text: 'Height' },
      { key: 'b', text: 'Welding' },
      { key: 'c', text: 'Hot' },
      { key: 'd', text: 'None of these' },
    ],
    correct: 'a',
  },
  {
    q:
      'To protect from electrical shock, we should provide _____ in the Circuit.',
    options: [
      { key: 'a', text: 'Fuse' },
      { key: 'b', text: 'Switch' },
      { key: 'c', text: 'Earth Leakage Circuit Breaker' },
      { key: 'd', text: 'Millimeter' },
    ],
    correct: 'c',
  },
  {
    q: 'Workers representatives represents in   _________.',
    options: [
      { key: 'a', text: 'Safety Council' },
      { key: 'b', text: 'Safety Committee' },
      { key: 'c', text: 'Core Team' },
      { key: 'd', text: 'Union – monthly meeting' },
    ],
    correct: 'b',
  },
  {
    q: 'Name the card  given to a visitor while entering the gate.',
    options: [
      { key: 'a', text: 'Visitor Protocol' },
      { key: 'b', text: 'Induction card' },
      { key: 'c', text: 'Safety card' },
      { key: 'd', text: 'SOT card' },
    ],
    correct: 'a',
  },
  {
    q: 'While using a lifting tackle first we should check?',
    options: [
      { key: 'a', text: 'Condition' },
      { key: 'b', text: 'Identification no.' },
      { key: 'c', text: 'SWL' },
      { key: 'd', text: 'All the points' },
    ],
    correct: 'd',
  },
  {
    q:
      'At elevated work platform / area railing height shall be of min _______',
    options: [
      { key: 'a', text: '1.1 meter' },
      { key: 'b', text: '1.5 meter' },
      { key: 'c', text: '0.6 meter' },
      { key: 'd', text: '2.0 meter' },
    ],
    correct: 'a',
  },
];

type ScreenType = 'START' | 'QUESTION' | 'SUCCESS' | 'FAILURE';

type State = {
  currentTrack: string;
  mute: boolean;
  fullscreen: boolean;
  failed: boolean;
  success: boolean;
  screen: ScreenType;
};

type Action = {
  type:
    | 'CHANGE_TRACK'
    | 'TOGGLE_MUTE'
    | 'SET_FULL_SCREEN'
    | 'QUIZ_FAILED'
    | 'QUIZ_WON'
    | 'RESET'
    | 'CHANGE_SCREEN';
  payload: any;
};

const initialState = {
  currentTrack: 'UPGameStart.mp3',
  mute: false,
  fullscreen: false,
  failed: false,
  success: false,
  screen: 'START' as ScreenType,
};

export const KBCContext = React.createContext<{
  state: State;
  dispatch: (action: Action) => void;
}>({
  state: initialState,
  dispatch: (action) => {},
});

function StartAnimation() {
  const { width } = useWindowSize();
  const _translateY = useRef(new Animated.Value(-300));
  const _scale = useRef(new Animated.Value(0));
  const { dispatch } = useContext(KBCContext);

  useEffect(() => {
    Animated.timing(_translateY.current, {
      toValue: 0,
      duration: 1500,
      easing: Easing.bounce,
    }).start();

    Animated.loop(
      Animated.timing(_scale.current, {
        toValue: 1,
        duration: 3000,
      })
    ).start();
  }, []);

  return (
    <Animated.View
      style={[
        {
          width: width - 200,
          height: 250,
          position: 'absolute',
          backgroundColor: 'rgba(0,0,0,0.8)',
          borderColor: '#fff',
          borderRadius: 5,
          borderWidth: 6,
          justifyContent: 'center',
          alignItems: 'center',
        },
        { transform: [{ translateY: _translateY.current }] },
      ]}
    >
      <Text
        style={{
          color: '#fff',
          textAlign: 'center',
          fontSize: 18,
          padding: 20,
        }}
      >
        Can you take the pressure of the hot seat? You can win prizes on the way
        to the top prize. You have 3 lifelines so use them wisely. Take the hot
        seat and let the game begin!
      </Text>
      <Animated.View
        style={{
          transform: [
            {
              scale: _scale.current.interpolate({
                inputRange: [0, 0.5, 1],
                outputRange: [1, 1.5, 1],
              }),
            },
          ],
        }}
      >
        <TouchableOpacity
          onPress={() => {
            dispatch({ type: 'CHANGE_SCREEN', payload: 'QUESTION' });
          }}
        >
          <PlayCircleOutlineOutlinedIcon
            style={{ color: '#fff', fontSize: 50 }}
          />
        </TouchableOpacity>
      </Animated.View>
    </Animated.View>
  );
}

const allottedTime = 30000;

function Timer({ start, timeEnded }: any) {
  const { dispatch } = useContext(KBCContext);
  const [time, setTime] = useState(0);
  const _countdown = useRef(new Animated.Value(0));
  const timer = useRef(
    Animated.timing(_countdown.current, {
      toValue: allottedTime,
      duration: allottedTime,
      easing: Easing.linear,
    })
  );

  const currentTime = (time: any) => {
    setTime(Math.floor(time / 1000));
  };

  useEffect(() => {
    if (start) {
      _countdown.current.setValue(0);
      timer.current.start();
      dispatch({ type: 'CHANGE_TRACK', payload: 'UPClockCountdown.mp3' });
    } else {
      timer.current.stop();
      _countdown.current.setValue(0);
    }
  }, [dispatch, start]);

  useEffect(() => {
    _countdown.current.addListener((event) => {
      if (event.value === allottedTime) {
        timeEnded();
      }
      currentTime(event.value);
    });
  }, [timeEnded]);

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Animated.View
        style={{
          height: 150,
          width: 150,
          borderRadius: 200,
          borderWidth: 10,
          borderColor: '#112597',
          backgroundColor: 'rgba(255,255,255,0.8)',
          transform: [
            {
              rotate: _countdown.current.interpolate({
                inputRange: [0, allottedTime],
                outputRange: ['0deg', '360deg'],
              }),
            },
          ],
        }}
      >
        <svg height="100%" width="100%" viewBox="0 0 40 40">
          <line
            x1="20"
            y1="20"
            x2="20"
            y2="38"
            stroke="#FF4B3E"
            strokeWidth="1"
          />
          <circle cx="20" cy="20" r="1" />
          <polygon points="19,37 21,37 20,40" />
        </svg>
      </Animated.View>
      <Animated.Text
        style={{
          fontSize: 40,
          fontWeight: 'bold',
          marginTop: 38,
          position: 'absolute',
          color: '#112597',
        }}
      >
        {time === 0 ? '' : time}
      </Animated.Text>
    </View>
  );
}

function Questions() {
  const _translateLayout = useRef(new Animated.Value(0));
  const { width, height } = useWindowSize();
  const [timerStart, setTimerStart] = useState(false);
  const { state, dispatch } = useContext(KBCContext);
  const [current, setCurrent] = useState(0);
  const [, setLivesUsed] = useState(0);
  const [lifeStatus, setLifeStatus] = useState({
    one: false,
    two: false,
    three: false,
  });
  const [qClicked, setQClicked] = useState({
    a: false,
    b: false,
    c: false,
    d: false,
  });
  const history = useHistory();

  useEffect(() => {
    Animated.timing(_translateLayout.current, {
      toValue: 1,
      duration: 1500,
      easing: Easing.elastic(1),
    }).start(() => setTimerStart(true));
  }, []);

  const question = questions[current];

  const timeEnded = useCallback(
    (endValue) => {
      setTimerStart(false);
      dispatch({ type: 'CHANGE_TRACK', payload: 'UPNolifes.mp3' });
      dispatch({ type: 'QUIZ_FAILED', payload: null });
    },
    [dispatch]
  );

  const processClick = (id: 'a' | 'b' | 'c' | 'd') => {
    if (qClicked[id]) {
      return;
    }
    setQClicked({ ...qClicked, [id]: true });
    if (question.correct === id) {
      setTimerStart(false);
      if (current === questions.length - 1) {
        dispatch({
          type: 'CHANGE_TRACK',
          payload: 'UPQuizComplete.mp3',
        });
        return dispatch({ type: 'QUIZ_WON', payload: null });
      }
      dispatch({
        type: 'CHANGE_TRACK',
        payload: 'UPCorrect.mp3',
      });
      setTimeout(() => {
        setCurrent(current + 1);
        dispatch({
          type: 'CHANGE_TRACK',
          payload: 'UPClockCountdown.mp3',
        });
        setTimerStart(true);
        setQClicked({ a: false, b: false, c: false, d: false });
      }, 1000);
    } else {
      if (!lifeStatus.one) {
        setLifeStatus({
          ...lifeStatus,
          one: true,
        });
        setLivesUsed(1);
      } else if (!lifeStatus.two) {
        setLifeStatus({
          ...lifeStatus,
          two: true,
        });
        setLivesUsed(2);
      } else if (!lifeStatus.three) {
        setLifeStatus({
          ...lifeStatus,
          three: true,
        });
        setLivesUsed(3);
      } else {
        return timeEnded(30000);
      }
      dispatch({
        type: 'CHANGE_TRACK',
        payload: 'UPlifelines.mp3',
      });
      setTimeout(() => {
        dispatch({
          type: 'CHANGE_TRACK',
          payload: 'UPClockCountdown.mp3',
        });
      }, 1000);
    }
  };

  const replay = () => {
    setCurrent(0);
    dispatch({
      type: 'CHANGE_TRACK',
      payload: 'UPClockCountdown.mp3',
    });
    dispatch({
      type: 'RESET',
      payload: null,
    });
    setTimerStart(true);
    setQClicked({ a: false, b: false, c: false, d: false });
    setLivesUsed(0);
    setLifeStatus({ one: false, two: false, three: false });
  };

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        position: 'relative',
        justifyContent: 'space-between',
        width,
      }}
    >
      {state.failed && (
        <View
          style={{
            position: 'absolute',
            width,
            height,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <View
            style={{
              width: 250,
              height: 150,
              backgroundColor: 'rgba(0,0,0,0.8)',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 12,
            }}
          >
            <Text style={{ fontSize: 16, color: '#fff' }}>You lost!</Text>
            <ReplayIcon
              onClick={replay}
              style={{ fontSize: 64, color: '#fff' }}
            />
          </View>
        </View>
      )}
      {state.success && (
        <View
          style={{
            position: 'absolute',
            width,
            height,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <View
            style={{
              width: 250,
              height: 150,
              backgroundColor: 'rgba(0,0,0,0.8)',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 12,
            }}
          >
            <Text style={{ fontSize: 16, color: '#fff' }}>
              Congratulations you won!
            </Text>
            <ReplayIcon
              onClick={replay}
              style={{ fontSize: 64, color: '#fff' }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if ((screenfull as any).isFullscreen) {
                  (screenfull as any).toggle();
                }
                history.push('/safety-week-2020');
              }}
            >
              Finish
            </Button>
          </View>
        </View>
      )}
      <View style={{ flex: 2 }}>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            marginTop: 16,
          }}
        >
          <Animated.View
            style={{
              flex: 1.3,
              transform: [
                {
                  translateX: _translateLayout.current.interpolate({
                    inputRange: [0, 1],
                    outputRange: [-500, 0],
                  }),
                },
              ],
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <View>
              <View style={{ flexDirection: 'row' }}>
                <View
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 40,
                    padding: 4,
                    position: 'relative',
                  }}
                >
                  <PersonIcon style={{ fontSize: 32 }} />
                  {lifeStatus.one && (
                    <ClearIcon
                      style={{
                        color: 'red',
                        position: 'absolute',
                        fontSize: 32,
                      }}
                    />
                  )}
                </View>
                <View
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 40,
                    padding: 4,
                    marginLeft: 8,
                  }}
                >
                  <PersonIcon style={{ fontSize: 32 }} />
                  {lifeStatus.two && (
                    <ClearIcon
                      style={{
                        color: 'red',
                        position: 'absolute',
                        fontSize: 32,
                      }}
                    />
                  )}
                </View>
              </View>
              <View style={{ alignItems: 'center' }}>
                <View
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 40,
                    padding: 4,
                  }}
                >
                  <PersonIcon style={{ fontSize: 32 }} />
                  {lifeStatus.three && (
                    <ClearIcon
                      style={{
                        color: 'red',
                        position: 'absolute',
                        fontSize: 32,
                      }}
                    />
                  )}
                </View>
              </View>
            </View>
          </Animated.View>
          <Animated.View
            style={{
              flex: 1.5,
              transform: [
                {
                  translateY: _translateLayout.current.interpolate({
                    inputRange: [0, 1],
                    outputRange: [-500, 0],
                  }),
                },
              ],
            }}
          >
            <Timer start={timerStart} timeEnded={timeEnded} />
          </Animated.View>
          <View style={{ flex: 1 }}></View>
        </View>
        <Animated.View
          style={{
            flex: 1,
            padding: 32,
            transform: [
              {
                translateY: _translateLayout.current.interpolate({
                  inputRange: [0, 1],
                  outputRange: [500, 0],
                }),
              },
            ],
          }}
        >
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              backgroundColor: 'rgba(255,255,255,0.8)',
              padding: 8,
              borderRadius: 6,
            }}
          >
            <View
              style={{
                position: 'absolute',
                left: -16,
                top: -20,
                borderRadius: 80,
                height: 40,
                width: 40,
                backgroundColor: 'rgb(17, 37, 151)',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text style={{ fontSize: 25, fontWeight: 'bold', color: '#fff' }}>
                {current + 1}
              </Text>
            </View>
            <Text
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                color: '#000',
                paddingLeft: 30,
              }}
            >
              {question.q}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 8,
            }}
          >
            <TouchableOpacity
              style={[
                {
                  flex: 1,
                  padding: 8,
                  backgroundColor: 'rgba(17, 37, 151,0.7)',
                  borderColor: '#fff',
                  borderWidth: 2,
                  borderRadius: 20,
                  marginHorizontal: 16,
                  width: 140,
                  justifyContent: 'center',
                  alignItems: 'center',
                },
                qClicked.a
                  ? {
                      backgroundColor: 'transparent',
                      borderColor: 'transparent',
                    }
                  : {},
              ]}
              onPress={() => {
                processClick('a');
              }}
            >
              <Text
                style={[
                  { fontSize: 12, fontWeight: 'bold', color: '#fff' },
                  qClicked.a ? { color: 'transparent' } : {},
                ]}
              >
                {question.options[0].text}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                {
                  flex: 1,
                  padding: 8,
                  backgroundColor: 'rgba(17, 37, 151,0.7)',
                  borderColor: '#fff',
                  borderWidth: 2,
                  borderRadius: 20,
                  marginHorizontal: 16,
                  width: 140,
                  justifyContent: 'center',
                  alignItems: 'center',
                },
                qClicked.b
                  ? {
                      backgroundColor: 'transparent',
                      borderColor: 'transparent',
                    }
                  : {},
              ]}
              onPress={() => {
                processClick('b');
              }}
            >
              <Text
                style={[
                  { fontSize: 12, fontWeight: 'bold', color: '#fff' },
                  qClicked.b ? { color: 'transparent' } : {},
                ]}
              >
                {question.options[1].text}
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 16,
            }}
          >
            <TouchableOpacity
              style={[
                {
                  flex: 1,
                  padding: 8,
                  backgroundColor: 'rgba(17, 37, 151,0.7)',
                  borderColor: '#fff',
                  borderWidth: 2,
                  borderRadius: 20,
                  marginHorizontal: 16,
                  width: 140,
                  justifyContent: 'center',
                  alignItems: 'center',
                },
                qClicked.c
                  ? {
                      backgroundColor: 'transparent',
                      borderColor: 'transparent',
                    }
                  : {},
              ]}
              onPress={() => {
                processClick('c');
              }}
            >
              <Text
                style={[
                  { fontSize: 12, fontWeight: 'bold', color: '#fff' },
                  qClicked.c ? { color: 'transparent' } : {},
                ]}
              >
                {question.options[2].text}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                {
                  flex: 1,
                  padding: 8,
                  backgroundColor: 'rgba(17, 37, 151,0.7)',
                  borderColor: '#fff',
                  borderWidth: 2,
                  borderRadius: 20,
                  marginHorizontal: 16,
                  width: 140,
                  justifyContent: 'center',
                  alignItems: 'center',
                },
                qClicked.d
                  ? {
                      backgroundColor: 'transparent',
                      borderColor: 'transparent',
                    }
                  : {},
              ]}
              onPress={() => {
                processClick('d');
              }}
            >
              <Text
                style={[
                  { fontSize: 12, fontWeight: 'bold', color: '#fff' },
                  qClicked.d ? { color: 'transparent' } : {},
                ]}
              >
                {question.options[3].text}
              </Text>
            </TouchableOpacity>
          </View>
        </Animated.View>
      </View>
      <Animated.View
        style={{
          flex: 1,
          alignItems: 'center',
          transform: [
            {
              translateX: _translateLayout.current.interpolate({
                inputRange: [0, 1],
                outputRange: [500, 0],
              }),
            },
          ],
        }}
      >
        <View
          style={{
            marginTop: 16,
            flexDirection: 'row',
            width: 120,
            justifyContent: 'space-between',
          }}
        >
          {state.fullscreen ? (
            <FullScreenExitIcon
              style={{
                backgroundColor: 'gray',
                borderRadius: 40,
                padding: 4,
                fontSize: 28,
              }}
              onClick={() => {
                if ((screenfull as any).isEnabled) {
                  (screenfull as any).toggle();
                }
                dispatch({
                  type: 'SET_FULL_SCREEN',
                  payload: false,
                });
              }}
            />
          ) : (
            <FullScreenIcon
              onClick={() => {
                if ((screenfull as any).isEnabled) {
                  (screenfull as any).toggle();
                }
                dispatch({
                  type: 'SET_FULL_SCREEN',
                  payload: true,
                });
              }}
              style={{
                backgroundColor: 'gray',
                borderRadius: 40,
                padding: 4,
                fontSize: 28,
              }}
            />
          )}
          {state.mute ? (
            <VolumeOffIcon
              onClick={() => {
                dispatch({ type: 'TOGGLE_MUTE', payload: false });
              }}
              style={{
                backgroundColor: 'gray',
                borderRadius: 40,
                padding: 4,
                fontSize: 28,
              }}
            />
          ) : (
            <VolumeUpIcon
              onClick={() => {
                dispatch({ type: 'TOGGLE_MUTE', payload: true });
              }}
              style={{
                backgroundColor: 'gray',
                borderRadius: 40,
                padding: 4,
                fontSize: 28,
              }}
            />
          )}
          <ClearIcon
            style={{
              backgroundColor: 'gray',
              borderRadius: 40,
              padding: 4,
              fontSize: 28,
            }}
            onClick={() => {
              if ((screenfull as any).isFullscreen) {
                (screenfull as any).toggle();
              }
              history.push('/safety-week-2020');
            }}
          />
        </View>
        <View style={{ marginTop: 16, flexDirection: 'column-reverse' }}>
          {questions.map((q, i) => {
            return (
              <View
                key={q.q}
                style={{
                  backgroundColor:
                    i + 1 > current ? 'rgba(17, 37, 151,0.8)' : '#af108d',
                  width: 120,
                  height: 20,
                  margin: 2,
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  borderRadius: 20,
                }}
              >
                <ArrowDropDownIcon style={{ fontSize: 25 }} />
              </View>
            );
          })}
        </View>
      </Animated.View>
    </View>
  );
}

export function KBCQuiz() {
  const { height, width } = useWindowSize();
  const soundRef = useRef<HTMLAudioElement>(null);
  const [state, dispatch] = useReducer(
    (prevState: State, action: Action): State => {
      switch (action.type) {
        case 'CHANGE_TRACK':
          return {
            ...prevState,
            currentTrack: action.payload,
          };
        case 'TOGGLE_MUTE':
          return { ...prevState, mute: action.payload };
        case 'SET_FULL_SCREEN':
          return { ...prevState, fullscreen: action.payload };
        case 'QUIZ_FAILED':
          return { ...prevState, failed: true };
        case 'QUIZ_WON':
          return { ...prevState, success: true };
        case 'CHANGE_SCREEN':
          return { ...prevState, screen: action.payload };
        case 'RESET':
          return { ...prevState, failed: false, success: false };
        default:
          return state;
      }
    },
    initialState
  );

  useEffect(() => {
    if (soundRef.current) {
      soundRef.current.muted = state.mute;
    }
  }, [state.mute]);

  useEffect(() => {
    if (!state.currentTrack) {
      return;
    }
    if (soundRef.current) {
      soundRef.current.src = `https://storage.googleapis.com/pl-public-assets/kbc-sounds/${state.currentTrack}`;
      soundRef.current.play();
      if (state.currentTrack === 'UPClockCountdown.mp3') {
        soundRef.current.loop = true;
      } else {
        soundRef.current.loop = false;
      }
    }
  }, [state.currentTrack]);

  useEffect(() => {
    const $soundRef = soundRef.current;
    return () => {
      if ($soundRef) {
        $soundRef.pause();
      }
    };
  }, []);

  return (
    <View style={{ height, width }}>
      <KBCContext.Provider value={{ state, dispatch }}>
        <ImageBackground
          source={{
            uri:
              'https://storage.googleapis.com/pl-public-assets/kbc-background.jpg',
          }}
          style={{ height: null, width: null, flex: 1 } as any}
        >
          <View
            style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
          >
            <audio ref={soundRef}></audio>
            {state.screen === 'START' && <StartAnimation />}
            {state.screen === 'QUESTION' && <Questions />}
          </View>
        </ImageBackground>
      </KBCContext.Provider>
    </View>
  );
}
