import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import {
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { format } from 'date-fns';
import { LoadingOrError } from 'components/LoadingOrError';
import {
  unstructuredDataRoadSafety,
  unstructuredDataRoadSafetyVariables,
} from '__generated__/unstructuredDataRoadSafety';

export const UNSTRUCTUREDATA_ROAD_SAFETY_QUERY = gql`
  query unstructuredDataRoadSafety2($company: String!) {
    unstructuredData(
      where: {
        company: { equals: $company }
        program: { equals: "ROAD_SAFETY_2021" }
      }
    ) {
      id
      data
      createdAt
    }
  }
`;

const passwords: any = {
  jcb: 'eeleifohthaej0Uv',
};

export function RoadSafety2021Data() {
  const [password, setPassword] = useState('');
  const company = 'jcb';
  const { data, loading, error } = useQuery<
    unstructuredDataRoadSafety,
    unstructuredDataRoadSafetyVariables
  >(UNSTRUCTUREDATA_ROAD_SAFETY_QUERY, {
    variables: {
      company,
    },
  });

  if (password !== passwords[company]) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: 80,
        }}
      >
        <TextField
          label="Admin password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
    );
  }
  if (data && data.unstructuredData) {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sr. No</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Date &#38; Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.unstructuredData.map((rowItem: any, i: number) => {
              const row = { ...rowItem, data: JSON.parse(rowItem.data) };
              return (
                <TableRow key={rowItem.id}>
                  <TableCell component="th" scope="row">
                    {i + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.data.info.name}
                  </TableCell>
                  <TableCell>{row.data.info.email}</TableCell>
                  <TableCell>{row.data.score ? row.data.score : 0}%</TableCell>
                  <TableCell>
                    {format(new Date(row.createdAt), 'dd-MMM-yy hh:mm a')}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return <LoadingOrError {...{ error, loading }} />;
}
