import React, { useEffect, useState } from 'react';
import useDeviceOrientation from 'hooks/useDeviceOrientation';
import {
  Paper,
  Typography,
  makeStyles,
  Button,
  TextField,
} from '@material-ui/core';
import Lottie from 'react-lottie';
import animationData from './15195-smartphone-screen-orientation.json';
import { Route, useRouteMatch, useLocation, Link } from 'react-router-dom';
import { Flip } from './Flip';
import { SafetyWeekSpotIdentification } from './SafetyWeekSpotIdentification';
import { SpinWheel } from './SpinWheel';
import { KBCQuiz } from './KBCQuiz';
import { useWindowSize } from 'hooks/useWindowSize';
import { ShubhlifeDashboard } from './Dashboard';

const useStyles = makeStyles((theme) => ({
  container: (props: any) => ({
    display: 'grid',
    backgroundImage: `url(https://storage.googleapis.com/pl-public-assets/intro_screen_gamesSC_E-01.svg)`,
    height: props.height,
    width: props.width,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    gridTemplateRows: 'repeat(4, 1fr)',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.down('xs')]: {
      padding: 16,
    },
    [theme.breakpoints.up('sm')]: {
      padding: 64,
    },
  }),
}));

function MessageWrapper(props: any) {
  const [seen, setSeen] = useState(false);
  const { width, height } = useWindowSize();
  const classes = useStyles({ width, height });
  const [name, setName] = useState(localStorage.getItem('@Shubhlife_username'));

  useEffect(() => {
    if (name) {
      localStorage.setItem('@Shubhlife_username', name);
    }
  }, [name]);

  if (!seen) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div style={{ marginTop: 80 }}>
          <TextField
            label="Name"
            variant="outlined"
            placeholder="Please provide your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <br />
        <div>
          <Button
            size="small"
            onClick={() => setSeen(true)}
            variant="contained"
            style={{ backgroundColor: '#5378B7' }}
          >
            Get Started
          </Button>
        </div>
        {/* <Logo
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            height: window.innerHeight,
            zIndex: 0
          }}
        /> */}
        {/* <img
          src="/pl-logo.png"
          alt="parallel-learning log"
          style={{ height: '100%' }}
        />
        <div>
          <br />
          <Typography
            variant="h5"
            style={{ fontWeight: 300, color: '#5378B7' }}
          >
            We present Digital Games in Safety for the
          </Typography>
          <Typography variant="h6" style={{ color: '#5378B7' }}>
            49th National Safety Week 2020
          </Typography>
        </div>
        <div>
          <Typography variant="h4" style={{ color: '#5378B7' }}>
            ENJOY THE GAMES!
          </Typography>
          <Button
            onClick={() => setSeen(true)}
            variant="contained"
            style={{ backgroundColor: '#5378B7' }}
          >
            Get Started
          </Button>
        </div>

        <div>
          <Typography style={{ fontWeight: 300 }}>Contact:</Typography>
          <Typography
            variant="caption"
            style={{ display: 'block', fontWeight: 'bold' }}
          >
            Sadanand Vyas
          </Typography>
          <Typography
            variant="caption"
            style={{ display: 'block', fontWeight: 'bold' }}
          >
            sadanand.vyas@parallellearning.in
          </Typography>
          <Typography
            style={{ display: 'block', fontWeight: 'bold' }}
            variant="caption"
          >
            +91 9545554868
          </Typography>
        </div> */}
      </div>
    );
  }

  return props.children;
}

export function SafetyWeek2020() {
  const orientation = useDeviceOrientation();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (orientation === 'landscape' && process.env.NODE_ENV === 'production') {
      // make screen fullscreen automatically.
    }
  }, [orientation]);

  if (orientation !== 'landscape') {
    return (
      <div>
        <Paper
          style={{
            padding: 16,
            textAlign: 'center',
          }}
        >
          <Typography variant="h6">
            The device needs to be in landscape mode
          </Typography>
          <br />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={200}
              width={200}
            />
          </div>
        </Paper>
      </div>
    );
  }
  return (
    <div>
      <Route path={path} exact>
        <div style={{ padding: 16, boxSizing: 'border-box' }}>
          <br />
          <div>
            <Link
              to={`${pathname}/flip-image`}
              style={{
                fontSize: 32,
                fontWeight: 'bold',
                padding: 12,
                margin: 8,
                borderRadius: 15,
                border: '4px solid',
                textDecoration: 'none',
              }}
            >
              Flip
            </Link>
            <Link
              to={`${pathname}/spot-identification`}
              style={{
                fontSize: 32,
                fontWeight: 'bold',
                padding: 12,
                margin: 8,
                borderRadius: 15,
                border: '4px solid',
                textDecoration: 'none',
              }}
            >
              Spot
            </Link>
            <Link
              to={`${pathname}/spin-wheel`}
              style={{
                fontSize: 32,
                fontWeight: 'bold',
                padding: 12,
                margin: 8,
                borderRadius: 15,
                border: '4px solid',
                textDecoration: 'none',
              }}
            >
              Spin
            </Link>
            <Link
              to={`${pathname}/quiz`}
              style={{
                fontSize: 32,
                fontWeight: 'bold',
                padding: 12,
                margin: 8,
                borderRadius: 15,
                border: '4px solid',
                textDecoration: 'none',
              }}
            >
              Quiz
            </Link>
          </div>
        </div>
      </Route>
      <Route path={`${path}/flip-image`}>
        <MessageWrapper>
          <Flip />
        </MessageWrapper>
      </Route>
      <Route path={`${path}/data`}>
        <ShubhlifeDashboard />
      </Route>
      <Route path={`${path}/spot-identification`}>
        <MessageWrapper>
          <SafetyWeekSpotIdentification />
        </MessageWrapper>
      </Route>
      <Route path={`${path}/spin-wheel`}>
        <MessageWrapper>
          <SpinWheel />
        </MessageWrapper>
      </Route>
      <Route path={`${path}/quiz`}>
        <MessageWrapper>
          <KBCQuiz />
        </MessageWrapper>
      </Route>
    </div>
  );
}
