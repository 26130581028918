import React, { useCallback, useState, useMemo } from 'react';
import {
  Image,
  StyleSheet,
  View,
  useWindowDimensions,
  Platform,
  Animated
} from 'react-native';
import { getComputedHeightWidth } from 'utils';
import { Draggable } from './Draggable';

interface ICircle {
  x: number;
  y: number;
  r: number;
}

function area(A: ICircle, B: ICircle) {
  if (A.x === B.x && A.y === B.y && A.r === B.r) {
    return Math.PI * A.r * B.r;
  }
  let d = Math.hypot(B.x - A.x, B.y - A.y);

  if (d < A.r + B.r) {
    const a = A.r * A.r;
    const b = B.r * B.r;

    const x = (a - b + d * d) / (2 * d);
    const z = x * x;
    const y = Math.sqrt(a - z);

    if (d < Math.abs(B.r - A.r)) {
      return Math.PI * Math.min(a, b);
    }
    return (
      a * Math.asin(y / A.r) +
      b * Math.asin(y / B.r) -
      y * (x + Math.sqrt(z + b - a))
    );
  }
  return 0;
}

const data = {
  img:
    'https://storage.googleapis.com/pl-public-assets/shubhlife/campaign-1.jpg',
  dimensions: {
    height: 1358,
    width: 1980
  },
  predefs: [
    {
      x: 0.8277448115458015,
      y: 0.35566902716022664
    },
    {
      x: 0.887508945610687,
      y: 0.8452574254277732
    },
    {
      x: 0.7160961354961832,
      y: 0.5788662955738682
    },
    {
      x: 0.6505620825381679,
      y: 0.2925086021905811
    },
    {
      x: 0.5543147662213741,
      y: 0.4232966582325265
    },
    {
      x: 0.6977129055343512,
      y: 0.9096134530039685
    },
    {
      x: 0.5199114384541985,
      y: 0.5652255567445952
    },
    {
      x: 0.42705599952290074,
      y: 0.5
    },
    {
      x: 0.6173440481870229,
      y: 0.6809055434855928
    },
    {
      x: 0.48278715410305345,
      y: 0.7287405168621907
    },
    {
      x: 0.20451156965648856,
      y: 0.8336166036858199
    },
    {
      x: 0.24496809398854963,
      y: 0.9168957597893174
    },
    {
      x: 0.2100056655534351,
      y: 0.7287079095183756
    },
    {
      x: 0.28640863549618323,
      y: 0.8024331138840796
    },
    {
      x: 0.3029654699427481,
      y: 0.3411478900479488
    },
    {
      x: 0.49627266221374045,
      y: 0.08162604062440275
    },
    {
      x: 0.14998807251908397,
      y: 0.38750466383826687
    },
    {
      x: 0.02483152433206107,
      y: 0.24293457047718356
    },
    {
      x: 0.0318761927480916,
      y: 0.40803642132710877
    },
    {
      x: 0.05805701335877863,
      y: 0.8869730872817568
    },
    {
      x: 0.3960735628536675,
      y: 0.2557233072966867
    },
    {
      x: 0.03481701835104976,
      y: 0.583970113116764
    }
  ]
};

export function PanResponderDemo() {
  const { width, height } = useWindowDimensions();
  const [captureHash, setCaptureHash] = useState<{
    [key: number]: { x: number; y: number };
  }>({});

  const captures = useMemo(() => {
    // if (resultsAvailable) {
    //   return props.results;
    // }
    return Object.keys(captureHash).map((key: any) => captureHash[key]);
  }, [captureHash]);

  const { height: imgHeight, width: imgWidth } = getComputedHeightWidth(
    width,
    height,
    data.dimensions.width,
    data.dimensions.height
  );

  const diameter = imgHeight / 10;

  const onFinalPosition = useCallback((x, y, i) => {
    setCaptureHash(latest => ({ ...latest, [i]: { x, y } }));
  }, []);

  const predefCopy = [...data.predefs];
  let matches = 0;
  for (let i = 0; i < captures.length; i++) {
    const capture = captures[i];
    for (let j = 0; j < predefCopy.length; j++) {
      const item = predefCopy[j];
      const intersection = area(
        {
          x: item.x * imgWidth,
          y: item.y * imgHeight,
          r: diameter / 2
        },
        {
          x: capture.x * imgWidth,
          y: capture.y * imgHeight,
          r: diameter / 2
        }
      );

      if (
        intersection / ((((Math.PI * diameter) / 2) * diameter) / 2) >=
        0.45
      ) {
        matches += 1;
        predefCopy.splice(j, 1);
        break;
      }
    }
  }

  console.log(matches);

  return (
    <View style={[styles.app, { width, height }]}>
      <View
        style={{
          width: imgWidth,
          height: imgHeight,
          position: 'relative',
          ...(Platform.OS === 'web' && { userSelect: 'none' })
        }}
      >
        <Image
          height={imgHeight}
          width={imgWidth}
          style={{
            height: imgHeight,
            width: imgWidth,
            ...(Platform.OS === 'web' && { userSelect: 'none' })
          }}
          source={{ uri: data.img }}
        />
        {data.predefs.map((cpt, i) => {
          return (
            <div
              key={i}
              style={{
                height: diameter,
                width: diameter,
                opacity: 0.5,
                backgroundColor: 'red',
                position: 'absolute',
                left: cpt.x * imgWidth,
                top: cpt.y * imgHeight,
                borderRadius: diameter,
                transform: `translateY(${-diameter / 2}px)`
              }}
            />
          );
        })}
        {Array.from(Array(40).keys()).map((_, i) => (
          <Draggable
            isLast={i === 0}
            diameter={diameter}
            key={i}
            initOffsetX={(width - imgWidth) / 2}
            imgHeight={imgHeight}
            imgWidth={imgWidth}
            onFinalPosition={onFinalPosition}
            index={i}
          />
        ))}
        {captures.map((cpt, i) => {
          return (
            <Animated.View
              key={i}
              style={{
                height: diameter,
                width: diameter,
                opacity: 0.7,
                backgroundColor: 'green',
                position: 'absolute',
                left: cpt.x * imgWidth,
                top: cpt.y * imgHeight,
                borderRadius: diameter,
                transform: [
                  {
                    translateY: -diameter / 2
                  }
                ]
              }}
            />
          );
        })}
      </View>
    </View>
  );
}

const styles: any = StyleSheet.create({
  app: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    overflow: 'hidden'
  },
  box: {
    width: 100,
    height: 100,
    backgroundColor: 'red',
    position: 'absolute',
    zIndex: 1
  }
});
