import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import {
  TableRow,
  makeStyles,
  TextField,
  Fab,
  TablePagination,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';
import { useDebounce } from 'hooks/useDebounce';
import { UserContext } from 'App';
import { useQuery } from '@apollo/react-hooks';
import { companyUsers, companyUsers_users } from '__generated__/companyUsers';
import AddIcon from '@material-ui/icons/Add';
import { LoadingOrError } from 'components/LoadingOrError';
import { TablePaginationActions } from 'components/TablePaginationActions';
import { useWindowSize } from 'hooks/useWindowSize';
import { format, differenceInCalendarDays } from 'date-fns';
import { useTable, useFilters, useSortBy, usePagination } from 'react-table';
import ArrowUpwordIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwordIcon from '@material-ui/icons/ArrowDownward';
import { UpdateUser } from './UpdateUser';
import { CreateNewUser, COMPANY_USERS_QUERY } from './CreateNewUser';
import {
  warningIconColor,
  successIconColor,
  errorIconColor,
} from 'components/MessageBox';
import { UserActions } from './UserActions';

const AnimatedTableRow = animated(TableRow);

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  paper: {
    // marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    // marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 650,
    position: 'relative',
    zIndex: 5,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  overflowBtn: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    height: 32,
    width: 32,
    outline: 'none',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      borderRadius: '50%',
    },
  },
}));

export function DefaultColumnFilter({
  column: { preFilteredRows, setFilter },
}: any) {
  const count = preFilteredRows.length;
  const [search, setSearch] = useState<string | undefined>('');
  const debouncedFilter = useDebounce(search, 100);

  useEffect(() => {
    setFilter(debouncedFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilter]);

  return (
    <TextField
      value={search || ''}
      onChange={(e) => {
        setSearch(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function UserList({
  users,
  companyId,
}: {
  users: companyUsers_users[];
  loading: boolean;
  companyId: string;
}) {
  const [{ st }, set] = useSpring(() => ({ st: 0 }));
  const onScroll = useCallback((e) => set({ st: e.target.scrollTop }), [set]);
  const classes = useStyles({});
  const { contentWidth: width, contentHeight: height } = useWindowSize();
  const [showCreateNewUserModal, setShowCreateNewUserModal] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState<any>(null);

  const columns = React.useMemo(
    () => [
      {
        id: 'SrNo',
        Header: 'Sr. No',
        accessor: (_item: any, index: number) => {
          return index + 1;
        },
        disableFilters: true,
      },
      {
        Header: 'Name',
        accessor: 'userName',
        filter: 'fuzzyText',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Since',
        accessor: (item: any) => new Date(item.createdAt),
        sortType: 'datetime',
        Cell: (data: any) => {
          return format(data.cell.value, 'dd MMM yyyy');
        },
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: (item: any) =>
          item.blocked ? 'Blocked' : item.deleted ? 'Deleted' : 'Active',
        Cell: (data: any) => {
          return (
            <Typography
              variant="caption"
              style={{
                fontWeight: 'bold',
                color:
                  data.cell.value === 'Blocked'
                    ? warningIconColor
                    : data.cell.value === 'Deleted'
                    ? errorIconColor
                    : successIconColor,
              }}
            >
              {data.cell.value}
            </Typography>
          );
        },
        disableSorting: true,
        disableFilters: true,
      },
      {
        Header: 'License Validity',
        accessor: 'license',
        Cell: (data: any) => {
          return data.cell.row.original.license ? (
            <Typography variant="caption" color="primary">
              {`${differenceInCalendarDays(
                new Date(data.cell.row.original.license.expires),
                new Date()
              )}Days`}
            </Typography>
          ) : (
            <Typography variant="caption" color="textSecondary">
              Not available
            </Typography>
          );
        },
        disableSorting: true,
        disableFilters: true,
      },
      {
        id: 'action',
        Header: 'Action',
        accessor: (item: any, _index: any) => {
          return item._id;
        },
        Cell: (data: any) => {
          return (
            <UserActions
              user={data.row.original}
              companyId={companyId}
              setShowEditDialog={setShowEditDialog}
            />
          );
        },
        disableSorting: true,
        disableFilters: true,
      },
    ],
    [companyId]
  );

  const defaultColumn: any = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    headerGroups,
    // rows,
    prepareRow,
    page,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: users,
      initialState: { sortBy: [{ id: 'SrNo', desc: false }], pageSize: 25 },
      defaultColumn,
    } as any,
    useFilters,
    useSortBy,
    usePagination
  ) as any;

  const topRowStyle = st.interpolate((o) => `translateY(${o}px)`);

  function handleChangePage(_event: any, newPage: any) {
    gotoPage(newPage);
  }

  function handleChangeRowsPerPage(event: any) {
    setPageSize(+event.target.value);
    gotoPage(0);
  }

  return (
    <div>
      {showCreateNewUserModal && (
        <CreateNewUser
          setShowCreateNewUserModal={setShowCreateNewUserModal}
          companyId={companyId}
        />
      )}
      {showEditDialog && (
        <UpdateUser
          id={showEditDialog}
          setShowEditDialog={setShowEditDialog}
          companyId={companyId}
        />
      )}
      <div
        className={classes.root}
        style={{ height: height - 52, overflowY: 'scroll', width }}
        onScroll={onScroll}
      >
        <Paper className={classes.paper}>
          <Table {...getTableProps()} className={classes.table} size="small">
            <TableHead>
              {headerGroups.map((headerGroup: any) => (
                <AnimatedTableRow
                  {...headerGroup.getHeaderGroupProps()}
                  style={{
                    transform: topRowStyle,
                    backgroundColor: '#fff',
                    zIndex: 2000,
                    boxShadow:
                      'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)',
                  }}
                >
                  {headerGroup.headers.map((column: any) => (
                    <TableCell {...column.getHeaderProps()}>
                      <div
                        style={{ display: 'flex', alignItems: 'center' }}
                        {...column.getSortByToggleProps()}
                      >
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDownwordIcon style={{ fontSize: 14 }} />
                          ) : (
                            <ArrowUpwordIcon style={{ fontSize: 14 }} />
                          )
                        ) : (
                          ''
                        )}
                        {column.render('Header')}
                      </div>
                      <div>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </TableCell>
                  ))}
                </AnimatedTableRow>
              ))}
            </TableHead>
            <TableBody>
              {page.map(
                (row: any) =>
                  prepareRow(row) || (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return (
                          <TableCell {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </Paper>
        <div style={{ height: 60 }}></div>
      </div>
      <TablePagination
        rowsPerPageOptions={[25, 35, 50]}
        component="div"
        count={users.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
      <Fab
        style={{ position: 'absolute', right: 25, bottom: 48, zIndex: 100 }}
        color="primary"
        aria-label="add"
        onClick={() => setShowCreateNewUserModal(true)}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}

export function Users() {
  const { user } = useContext(UserContext as any);
  const { data, loading, error } = useQuery<companyUsers>(COMPANY_USERS_QUERY, {
    variables: {
      companyId: user.adminFor.id,
    },
  });

  if (data && data.users) {
    return (
      <UserList
        companyId={user.adminFor.id}
        users={data.users}
        loading={loading}
      />
    );
  }
  return <LoadingOrError {...{ error, loading }} />;
}
