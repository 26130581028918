import React, { useState, useEffect, useContext } from 'react';
import { Typography, IconButton, Button, Link } from '@material-ui/core';
import NavigateNext from '@material-ui/icons/NavigateNext';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import { useParams, Redirect } from 'react-router-dom';
import { API_URL } from 'index';
import { Link as RouterLink } from 'react-router-dom';
import { UserContext } from '../App';

export function GCBucketItemList(props: any) {
  const params: any = useParams();
  const [cloudfiles, setCloudFiles] = useState<
    {
      contentType: string;
      name: string;
      size: number;
      timeCreated: string;
    }[]
  >([]);
  const { user } = useContext(UserContext);

  let isAllowed = false;
  if (user && user.permissionGroups) {
    isAllowed = user.permissionGroups.reduce(
      (accumulator: boolean, group: any) => {
        return group.name === params.gcbucket || accumulator;
      },
      false
    );
  }

  useEffect(() => {
    if (isAllowed) {
      fetch(`${API_URL}/bucket-file-list?gcbucket=${params.gcbucket}`)
        .then((res) => res.json())
        .then((data) => {
          setCloudFiles(data);
        });
    }
  }, [params.gcbucket, isAllowed]);
  const [level, setLevel] = useState<string[]>([]);

  const files = cloudfiles
    .filter((file: any) => {
      return file && file.contentType !== 'text/plain';
    })
    .map((file: any) => {
      const parts = file.name.split('/');
      return {
        key: parts.slice(0, parts.length - 1).join('.'),
        ...file,
        tags: parts.slice(0, parts.length - 1),
      };
    });
  const levelSet = new Set();
  const fileSet = new Set();
  files.forEach((file: any) => {
    if (level.length === 0) {
      levelSet.add(file.tags[0]);
    } else {
      if (file.key.includes(level.join('.'))) {
        if (file.tags[level.length]) {
          levelSet.add(file.tags[level.length]);
        } else {
          fileSet.add(file.name);
        }
      }
    }
  });

  if (!isAllowed) {
    return <Redirect to="/" />;
  }

  return (
    <div style={{ padding: 16 }}>
      {!!level.length && <Button onClick={() => setLevel([])}>Home</Button>}
      <ul>
        {levelSet.size > 0
          ? Array.from(levelSet).map((name: any) => (
              <li
                key={name}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography>{name}&nbsp;&nbsp;</Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    setLevel([...level, name]);
                  }}
                >
                  <NavigateNext />
                </IconButton>
              </li>
            ))
          : Array.from(fileSet)
              .sort((a: any, b: any) => {
                const matchA = a.replace(level.join('/'), '').match(/\d+/);
                const matchB = b.replace(level.join('/'), '').match(/\d+/);
                if (matchA && matchA[0] && matchB && matchB[0]) {
                  const numA = parseInt(
                    matchA[0].replace('.', '').replace(')', ''),
                    10
                  );
                  const numB = parseInt(
                    matchB[0].replace('.', '').replace(')', ''),
                    10
                  );
                  return numA > numB ? 1 : numB > numA ? -1 : 0;
                }
                return 0;
              })
              .map((name: any) => (
                <li style={{ listStyle: 'none' }} key={name}>
                  <Link
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    to={`/gcloud/${params.gcbucket}/preview?key=${name}`}
                    component={RouterLink}
                  >
                    <IconButton size="small">
                      <PlayCircleOutline />
                    </IconButton>
                    <Typography>
                      {name.replace(level.join('/'), '').replace('/', '')}
                      &nbsp;&nbsp;
                    </Typography>
                  </Link>
                </li>
              ))}
      </ul>
      <Button to="/login" component={RouterLink}>
        Logout
      </Button>
    </div>
  );
}

// DemoVideos.getInitialProps = ({ req, query }: any) => {
//   if (req) {
//     const bucket = req.storage.bucket(query.gcbucket);
//     const callback = function(
//       _err: any,
//       files: any,
//       nextQuery: any,
//       _apiResponse: any
//     ) {
//       if (nextQuery) {
//         bucket.getFiles(nextQuery, callback);
//       }

//       // The `metadata` property is populated for you with the metadata at the
//       // time of fetching.
//       files[0].metadata;

//       // However, in cases where you are concerned the metadata could have
//       // changed, use the `getMetadata` method.
//       //   files[0].getMetadata(function(err, metadata) {});
//     };

//     bucket.getFiles(
//       {
//         autoPaginate: false
//       },
//       callback
//     );

//     //-
//     // If the callback is omitted, we'll return a Promise.
//     //-
//     return bucket.getFiles().then(function(data: any) {
//       const files = data[0];
//       return {
//         files: files.map((file: any) => {
//           return {
//             contentType: file.metadata.contentType,
//             name: file.name,
//             size: file.size,
//             timeCreated: file.metadata.timeCreated
//           };
//         }),
//         query
//       };
//     });
// //   }
//   return {
//     files: [],
//     query
//   };
// };

// const withAuthHOC = (WrappedComponent: any) => {
//   class Wrapper extends React.Component<any, any> {
//     static displayName = 'DemoVideos';
//     static async getInitialProps(ctx: any) {
//       const componentProps =
//         WrappedComponent.getInitialProps &&
//         (await WrappedComponent.getInitialProps(ctx));
//       const { user } = componentProps;
//       if (!user) {
//         Router.push(`/login`);
//         return {};
//       }
//       let isAllowed = false;
//       if (user && user.permissionGroups) {
//         isAllowed = user.permissionGroups.reduce(
//           (accumulator: boolean, group: any) => {
//             return group.name === ctx.query.gcbucket || accumulator;
//           },
//           false
//         );
//       }
//       if (ctx.req && !isAllowed) {
//         ctx.res.writeHead(302, { Location: `/` });
//         ctx.res.end();
//         return {};
//       }
//       if (!isAllowed) {
//         Router.push(`/`);
//         return {};
//       }
//       return {
//         ...componentProps
//       } as any;
//     }

//     render() {
//       return <WrappedComponent {...this.props} {...this.state} />;
//     }
//   }

//   return Wrapper;
// };

// export default DemoVideos;
