import React, { useReducer, useEffect, useCallback } from 'react';
import {
  Route,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import {
  withStyles,
  Button,
  Theme,
  createStyles,
  makeStyles,
  InputBase,
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Hidden,
} from '@material-ui/core';
import { useWindowSize } from 'hooks/useWindowSize';
import RoadSafetyLoginBackground from '../road-safety-assets/Login_Background.svg';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import { VideoPlayer } from 'components/VideoPlayer';
import { useMutation } from '@apollo/react-hooks';
import { API_URL } from 'index';
import { CreateOneUnstructuredData_Mutation } from './LiftTools';
import {
  CreateOneUnstructuredDataMutation,
  CreateOneUnstructuredDataMutationVariables,
} from '__generated__/CreateOneUnstructuredDataMutation';
import { RoadSafety2020Data } from './RoadSafety2020Data';
import { describeArc } from 'utils';

const ThemedButton = withStyles({
  root: {
    borderRadius: 40,
    width: 120,
    boxShadow: 'none',
    color: '#fff',
    textTransform: 'none',
    padding: '6px 12px',
    lineHeight: 1.5,
    fontWeight: 'bold',
    borderColor: 'transparent',
    backgroundColor: 'rgb(113,42,143)',
    background:
      'linear-gradient(90deg, rgba(113,42,143,1) 0%, rgba(249,94,156,1) 100%)',
    border: 'none',
    '&:disabled': {
      opacity: 0.8,
      color: '#fff',
    },
    // '&:active': {
    //   boxShadow: 'none',
    //   backgroundColor: '#0062cc',
    //   borderColor: '#005cbf'
    // },
    // '&:focus': {
    //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
    // }
  },
})(Button);

export const GEInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#A6B7C0',
      border: '1px solid #A6B7C0',
      fontSize: 16,
      width: 300,
      padding: '10px 12px',
      color: '#fff',
      fontWeight: 700,
      marginBottom: 20,
      // transition: theme.transitions.create(['border-color', 'box-shadow']),
      // // Use the system font instead of the default Roboto font.
      // fontFamily: [
      //   '-apple-system',
      //   'BlinkMacSystemFont',
      //   '"Segoe UI"',
      //   'Roboto',
      //   '"Helvetica Neue"',
      //   'Arial',
      //   'sans-serif',
      //   '"Apple Color Emoji"',
      //   '"Segoe UI Emoji"',
      //   '"Segoe UI Symbol"'
      // ].join(','),
      // '&:focus': {
      //   boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      //   borderColor: theme.palette.primary.main
      // },
    },
  })
)(InputBase);

export const useStyles = makeStyles((theme) =>
  createStyles({
    loginContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    loginBox: {
      backgroundColor: 'rgba(255,255,255,0.8)',
      margin: '0 auto',
      borderRadius: 20,
    },
    inputCtn: {
      margin: '0 auto',
      position: 'relative',
      width: 250,
    },
    input: {
      border: 'none',
      padding: 12,
      borderRadius: 24,
      width: '100%',
      marginBottom: 20,
      paddingLeft: 50,
      outline: 'none',
      fontSize: 18,
      fontWeight: 'lighter',
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
    sbmt: {
      backgroundColor: '#0071BD',
      color: '#fff',
      padding: 12,
      width: 250,
      borderRadius: 24,
      fontWeight: 'bold',
    },
    icon: { position: 'absolute', top: 8, left: 16 },
    inputLabel: {
      color: '#223165',
      textAlign: 'center',
      fontSize: 22,
      marginLeft: 12,
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    questionContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    qstnImageContainer: {
      width: 180,
      paddingLeft: 20,
      paddingRight: 20,
    },
    question: {},
    optionImageCtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 4,

      borderRadius: '50%',
      margin: 4,
      '&:hover': {
        background: 'rgba(0,0,0,0.2)',
      },
    },
    optionImage: {
      width: 160,
      height: 160,
    },
    optionImage2: {
      width: 180,
      height: 180,
    },
    active: {
      background: 'rgba(0,0,0,0.2)',
    },
    hover: {
      padding: 4,
      '&:hover': {
        background: 'rgba(0,0,0,0.2)',
      },
    },
    fullPageBg: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    pageCtn: (props: any) => ({
      [theme.breakpoints.up('sm')]: {
        width: props.width - 40,
        margin: '0 auto',
      },
      [theme.breakpoints.down('sm')]: {
        padding: 20,
      },
    }),
    quizPageRoot: ({ width, height }: any) => ({
      width,
      height,
      padding: 8,
      boxSizing: 'border-box' as any,
    }),
    quizInnerContainer: ({ width, height }: any) => ({
      border: '1px solid #6E298F',
      width: width - 16,
      height: height - 16,
      padding: 16,
      paddingLeft: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      overflowY: 'scroll' as any,
    }),
  })
);

type State = {
  info: {
    name: string;
    email: string;
    company: string;
  };
  quiz: {
    [key: string]: string;
  };
  pledge: boolean;
  datetime: Date;
  freeze: boolean;
};

type Action = {
  type: 'REHYDRATE' | 'FREEZE';
  value: any;
};

const quizdata = [
  {
    route: 'q1',
    question: 'How many fatal injuries occur every day in India?',
    answers: [
      { key: 'a', text: 'Around 40' },
      { key: 'b', text: 'Around 400' },
      { key: 'c', text: 'Around 1000' },
    ],
    correctAnswer: 'b',
    image:
      'https://storage.googleapis.com/pl-public-assets/reroadsafetyweek/Question%201.svg',
  },
  {
    route: 'q2',
    question:
      'You have a valid learner’s licence to ride a two wheeler, you are allowed to',
    answers: [
      {
        key: 'a',
        text: 'Drive only at daytime',
      },
      {
        key: 'b',
        text: 'Practice driving on roads with little traffic',
      },
      {
        key: 'c',
        text:
          'Drive at any time & place provided there is a pillion rider with you',
      },
      {
        key: 'd',
        text:
          'Drive at any time and place but cannot carry any person on the pillion seat except a permanent licence holder for a two wheeler',
      },
    ],
    correctAnswer: 'd',
    image:
      'https://storage.googleapis.com/pl-public-assets/reroadsafetyweek/Question%202.svg',
  },
  {
    route: 'q3',
    question: 'While driving , you must observe “TWO SECOND RULE“ to avoid',
    answers: [
      { key: 'a', text: 'Head on collision' },
      {
        key: 'b',
        text: 'Rear end collision',
      },
      {
        key: 'c',
        text: 'Side swipe collision',
      },
    ],
    correctAnswer: 'b',
    image:
      'https://storage.googleapis.com/pl-public-assets/reroadsafetyweek/Question%203.svg',
  },
  {
    route: 'q4',
    question: 'If another car tries to over-take yours, you should',
    answers: [
      { key: 'a', text: 'Increase your speed' },
      {
        key: 'b',
        text: 'Maintain a steady speed',
      },
    ],
    correctAnswer: 'b',
    image:
      'https://storage.googleapis.com/pl-public-assets/reroadsafetyweek/Question%204.svg',
  },
  {
    route: 'q5',
    question:
      'What is the blood alcohol content legal limit for drinking and driving?',
    answers: [
      {
        key: 'a',
        text: '30 mg of alcohol in every 100 ml of blood',
      },
      {
        key: 'b',
        text: '60 mg of alcohol in every 100 ml of blood',
      },
      {
        key: 'c',
        text: '90 mg of alcohol in every 100 ml of blood',
      },
      {
        key: 'd',
        text:
          'Consuming any amount of alcohol just before driving is an offence',
      },
    ],
    correctAnswer: 'd',
    image:
      'https://storage.googleapis.com/pl-public-assets/reroadsafetyweek/Question%205.svg',
  },
  {
    route: 'q6',
    question: 'Pedestrians can cross the road ..........',
    answers: [
      {
        key: 'a',
        text: 'Anywhere',
      },
      {
        key: 'b',
        text: 'At Zebra crossing',
      },
      {
        key: 'c',
        text: 'Near the signals',
      },
    ],
    correctAnswer: 'b',
    image:
      'https://storage.googleapis.com/pl-public-assets/reroadsafetyweek/Question%206.svg',
  },
  {
    route: 'q7',
    question:
      'Every year, first week of........is celebrated as Road Safety Week',
    answers: [
      {
        key: 'a',
        text: 'December',
      },
      {
        key: 'b',
        text: 'January',
      },
      {
        key: 'c',
        text: 'March',
      },
      {
        key: 'd',
        text: 'February',
      },
    ],
    correctAnswer: 'b',
    image:
      'https://storage.googleapis.com/pl-public-assets/reroadsafetyweek/Question%207.svg',
  },
  {
    route: 'q8',
    question:
      'Drivers shall driver their vehicles on the ....... side of the road?',
    answers: [
      {
        key: 'a',
        text: 'Left',
      },
      {
        key: 'b',
        text: 'Right',
      },
      {
        key: 'c',
        text: 'Back',
      },
    ],
    correctAnswer: 'a',
    image:
      'https://storage.googleapis.com/pl-public-assets/reroadsafetyweek/Question%208.svg',
  },
  {
    route: 'q9',
    question: 'The major cause of the accidents is ........',
    answers: [
      {
        key: 'a',
        text: 'Nature of road',
      },
      {
        key: 'b',
        text: 'Nature of driver',
      },
      {
        key: 'c',
        text: 'Speed',
      },
    ],
    correctAnswer: 'c',
    image:
      'https://storage.googleapis.com/pl-public-assets/reroadsafetyweek/Question%209.svg',
  },
  {
    route: 'q10',
    question: "In everyone's life ......... is more important",
    answers: [
      {
        key: 'a',
        text: 'Money',
      },
      {
        key: 'b',
        text: 'Beauty',
      },
      {
        key: 'c',
        text: 'Safety',
      },
    ],
    correctAnswer: 'c',
    image:
      'https://storage.googleapis.com/pl-public-assets/reroadsafetyweek/Question%2010.svg',
  },
];

const initialState: State = {
  info: {
    name: '',
    email: '',
    company: '',
  },
  quiz: quizdata.reduce((acc, item) => {
    return { ...acc, [item.route]: '' };
  }, {}),
  freeze: false,
  pledge: false,
  datetime: new Date(),
};

function RoadSafety2020() {
  const company = 'jcb';
  const [createOneUnstructuredData] = useMutation<
    CreateOneUnstructuredDataMutation,
    CreateOneUnstructuredDataMutationVariables
  >(CreateOneUnstructuredData_Mutation);
  const { path } = useRouteMatch();
  const [state, dispatch] = useReducer(
    (state: State, action: Action) => {
      switch (action.type) {
        case 'REHYDRATE':
          return action.value;
        case 'FREEZE':
          return {
            ...state,
            freeze: true,
          };
        default:
          return state;
      }
    },
    {
      ...initialState,
      info: {
        ...initialState.info,
        company,
      },
    }
  );
  const { height, width } = useWindowSize();
  const classes = useStyles({ width, height });
  const { pathname } = useLocation();
  const history = useHistory();
  const baseUrl = '/jcb/road-safety-2020';

  const rehydrate = useCallback(() => {
    const stored = localStorage.getItem(company);
    if (stored) {
      dispatch({
        type: 'REHYDRATE',
        value: JSON.parse(stored),
      });
    }
  }, [company]);

  useEffect(() => {
    rehydrate();
  }, [rehydrate]);

  useEffect(() => {
    localStorage.setItem(company, JSON.stringify(state));
  }, [company, state]);

  const correctAnswers = quizdata.filter((q) => {
    return state.quiz[q.route] === q.correctAnswer;
  });
  const score = Math.floor((correctAnswers.length / 10) * 100);

  return (
    <div>
      <Route exact path={path}>
        <div
          className={classes.fullPageBg}
          style={{
            height,
            width,
          }}
        >
          <div
            className={classes.pageCtn}
            style={{
              height: height,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundImage: `url(${RoadSafetyLoginBackground})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <Grid container>
              <Hidden smDown>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{ maxHeight: 500 }}
                    src={
                      'https://storage.googleapis.com/pl-public-assets/reroadsafetyweek/Girl-Boy.svg'
                    }
                    alt="girl-boy"
                  />
                </Grid>
              </Hidden>
              <Grid item xs={12} md={6}>
                <div
                  className={classes.loginContainer}
                  style={{ height: height, overflowY: 'scroll' }}
                >
                  <div className={classes.loginBox}>
                    <br />
                    <br />
                    <Typography
                      style={{
                        color: '#6E298F',
                        borderRadius: 20,
                        minWidth: 320,
                        padding: 8,
                        fontWeight: 'bold',
                      }}
                      variant="h3"
                      align="center"
                    >
                      ROAD SAFETY 2020
                    </Typography>
                    <br />
                    <br />
                    <div className={classes.inputCtn}>
                      <PersonIcon
                        style={{ color: '#fff' }}
                        className={classes.icon}
                      />
                      <input
                        type="text"
                        className={classes.input}
                        placeholder="Name"
                        value={state.info.name}
                        onChange={(e) => {
                          dispatch({
                            type: 'REHYDRATE',
                            value: {
                              ...state,
                              info: {
                                ...state.info,
                                name: e.target.value,
                              },
                            },
                          });
                        }}
                      />
                    </div>
                    <div className={classes.inputCtn}>
                      <EmailIcon
                        style={{ color: '#fff' }}
                        className={classes.icon}
                      />
                      <input
                        type="email"
                        className={classes.input}
                        placeholder="Email ID"
                        value={state.info.email}
                        onChange={(e) => {
                          dispatch({
                            type: 'REHYDRATE',
                            value: {
                              ...state,
                              info: {
                                ...state.info,
                                email: e.target.value,
                              },
                            },
                          });
                        }}
                      />
                    </div>
                    <br />
                    <div style={{ textAlign: 'center' }}>
                      <ThemedButton
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          history.push(`${pathname}/video`);
                        }}
                        disabled={
                          !state.info.name ||
                          !state.info.email ||
                          !state.info.company
                        }
                      >
                        NEXT
                      </ThemedButton>
                    </div>
                    <br />
                    <br />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Route>
      <Route path={`${path}/video`}>
        <VideoPlayer
          url={`https://storage.googleapis.com/pl-public-assets/reroadsafetyweek/${company}.mp4`}
          onEnded={() => {
            history.push(`${baseUrl}/q1`);
          }}
          height={height}
          width={width}
        />
      </Route>
      {quizdata.map((item, i) => {
        return (
          <Route key={item.route} path={`${baseUrl}/${item.route}`}>
            <div className={classes.quizPageRoot}>
              <div className={classes.quizInnerContainer}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="h4"
                      style={{ color: '#6E298F', paddingLeft: 30 }}
                    >
                      QUESTIONNAIRE
                    </Typography>
                    <br />
                    <br />
                    <Typography
                      style={{
                        paddingLeft: 30,
                        background:
                          'linear-gradient(90deg, rgba(113,42,143,1) 0%, rgba(249,94,156,1) 100%)',
                        width: 150,
                        padding: 8,
                        color: '#fff',
                        fontWeight: 'bold',
                      }}
                    >
                      {`Question ${i + 1}`}
                    </Typography>
                    <br />
                    <br />
                    <FormControl
                      style={{ paddingLeft: 30 }}
                      component="fieldset"
                    >
                      <Typography>{item.question}</Typography>
                      <RadioGroup
                        aria-label={item.route}
                        name={item.route}
                        value={state.quiz[item.route]}
                        onChange={(e) => {
                          dispatch({
                            type: 'REHYDRATE',
                            value: {
                              ...state,
                              quiz: {
                                ...state.quiz,
                                [item.route]: e.target.value,
                              },
                            },
                          });
                        }}
                      >
                        {item.answers.map((opt) => (
                          <FormControlLabel
                            disabled={state.freeze}
                            key={opt.key}
                            value={opt.key}
                            control={<Radio />}
                            label={`${opt.text}`}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                    <br />
                    <br />
                    {state.freeze && i === quizdata.length - 1 ? null : (
                      <ThemedButton
                        //   disabled={
                        //     !state.quiz[item.route] ||
                        //     state.quiz[item.route] !== item.correctAnswer
                        //   }
                        style={{ marginLeft: 30 }}
                        onClick={() => {
                          if (i === quizdata.length - 1) {
                            dispatch({
                              type: 'FREEZE',
                              value: null,
                            });
                            //   history.push(`${baseUrl}/pledge`);
                          } else {
                            history.push(`${baseUrl}/${quizdata[i + 1].route}`);
                          }
                        }}
                      >
                        {i === quizdata.length - 1 ? 'Submit' : 'Next'}
                      </ThemedButton>
                    )}

                    {state.freeze && i === quizdata.length - 1 && (
                      <div>
                        <Typography style={{ marginLeft: 30 }} color="primary">
                          You scored {score}%
                        </Typography>
                        <br />
                        <ThemedButton
                          style={{ marginLeft: 30 }}
                          onClick={() => {
                            history.push(`${baseUrl}/pledge`);
                          }}
                        >
                          Next
                        </ThemedButton>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: 20 }}>
                    <img
                      style={{ width: '100%' }}
                      src={item.image}
                      alt={item.route}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Route>
        );
      })}
      <Route path={`${path}/pledge`}>
        <div
          style={{
            width,
            height,
            padding: 8,
            boxSizing: 'border-box',
          }}
        >
          <div
            style={{
              border: '1px solid #6E298F',
              width: width - 16,
              height: height - 16,
              padding: 16,
              paddingLeft: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  variant="h3"
                  style={{
                    color: '#6E298F',
                    paddingLeft: 30,
                    alignSelf: 'flex-start',
                    fontWeight: 'bold',
                  }}
                >
                  I PLEDGE TO...
                </Typography>
                <div style={{ maxWidth: 600, margin: '0 autom' }}>
                  <img
                    style={{ width: '100%' }}
                    src={
                      'https://storage.googleapis.com/pl-public-assets/reroadsafetyweek/I%20PLEDGE%20TO-01.svg'
                    }
                    alt="pledge"
                  />
                </div>
                <br />
                <ThemedButton
                  onClick={async () => {
                    if (!state.pledge) {
                      await createOneUnstructuredData({
                        variables: {
                          company,
                          program: 'ROAD_SAFETY_2020',
                          data: JSON.stringify({ ...state, score }),
                        },
                      });
                    }
                    dispatch({
                      type: 'REHYDRATE',
                      value: {
                        ...state,
                        pledge: true,
                      },
                    });
                    history.push(`${baseUrl}/thank-you`);
                  }}
                >
                  COMMIT
                </ThemedButton>
              </Grid>
            </Grid>
          </div>
        </div>
      </Route>
      <Route path={`${path}/thank-you`}>
        <div
          style={{
            width,
            height,
            padding: 8,
            boxSizing: 'border-box',
          }}
        >
          <div
            style={{
              border: '1px solid #6E298F',
              width: width - 16,
              height: height - 16,
              padding: 16,
              paddingLeft: 0,
              display: 'flex',
              overflowY: 'scroll',
            }}
          >
            <Grid container>
              <Grid
                xs={12}
                md={12}
                item
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 200,
                }}
              >
                <img
                  src={`https://storage.googleapis.com/pl-public-assets/companies/${company}/logo.svg`}
                  alt={`${company} logo`}
                  style={{
                    height: 180,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <svg
                  width="140"
                  height="140"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="70" cy="70" r="70" fill="#9c9d9e" />
                  <path
                    id="arc1"
                    fill="none"
                    stroke="#F5AD23"
                    strokeWidth="20"
                    d={describeArc(70, 70, 60, 0, (score / 100) * 360)}
                  />
                  <circle cx="70" cy="70" r="55" fill="#fff" />
                  <circle cx="70" cy="70" r="40" fill="#F5AD23" />
                  <text
                    x="70"
                    y="70"
                    fill="#fff"
                    textAnchor="middle"
                    fontSize="30px"
                    dominantBaseline="middle"
                  >
                    {score.toFixed(0)}%
                  </text>
                </svg>
                <br />
                <Typography style={{ textTransform: 'uppercase' }} variant="h4">
                  {state.info.name}
                </Typography>
                <br />
                <br />
                <Typography
                  variant="h3"
                  style={{
                    color: '#6E298F',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  THANK YOU FOR TAKING SAFETY PLEDGE
                </Typography>
                <br />
                <br />
                <ThemedButton
                  onClick={() => {
                    let link = document.createElement('a');
                    link.download = 'name';
                    link.href = `${API_URL}/jcb-road-safety-certificate?name=${state.info.name}&company=${company}&score=${score}`;
                    link.click();
                  }}
                  style={{
                    marginRight: 30,
                    alignSelf: 'flex-end',
                  }}
                >
                  DOWNLOAD
                </ThemedButton>
              </Grid>
            </Grid>
          </div>
        </div>
      </Route>
      <Route path={`${path}/data`}>
        {' '}
        <RoadSafety2020Data />
      </Route>
    </div>
  );
}

export default RoadSafety2020;
