import React, { Suspense } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Route, useRouteMatch } from 'react-router-dom';

const CovidTraining = React.lazy(() => import('./CovidTraining'));
const CovidTrainingUS = React.lazy(() => import('./CovidTrainingUS'));

function Sigma() {
  const { path } = useRouteMatch();

  return (
    <div>
      <Route path={`${path}/covid-training`}>
        <Suspense fallback={<CircularProgress size={20} />}>
          <CovidTraining />
        </Suspense>
      </Route>
      <Route path={`${path}/us/covid-training`}>
        <Suspense fallback={<CircularProgress size={20} />}>
          <CovidTrainingUS />
        </Suspense>
      </Route>
    </div>
  );
}

export default Sigma;
