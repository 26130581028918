import React, { useRef, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from '@material-ui/core';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { DatePicker } from '@material-ui/pickers';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { parseISO, differenceInDays, isToday } from 'date-fns';
import { Licenses, LicensesVariables } from '__generated__/Licenses';
import { USER_FRAGMENT } from 'components/PrivateRoute';
import { COMPANY_USERS_QUERY, LICENSES_QUERY } from './CreateNewUser';
import { UPDATE_PROFILE } from 'components/ProfilePage';
import { LoadingOrError } from 'components/LoadingOrError';
import gql from 'graphql-tag';
import { GetUser, GetUserVariables } from '__generated__/GetUser';
import {
  UpdateOneUser,
  UpdateOneUserVariables
} from '__generated__/UpdateOneUser';

const GET_USER_QUERY = gql`
  query GetUser($id: String!) {
    user(where: { id: $id }) {
      ...UserInfo
    }
  }
  ${USER_FRAGMENT}
`;

function UpdateUserComponent({
  setShowEditDialog,
  companyId,
  id
}: {
  setShowEditDialog: any;
  companyId: string;
  id: string;
}) {
  const now = useRef(new Date());
  const { data } = useQuery<Licenses, LicensesVariables>(LICENSES_QUERY, {
    variables: {
      companyId,
      expires: now.current
    }
  });
  const [updateOneUser] = useMutation<UpdateOneUser, UpdateOneUserVariables>(
    UPDATE_PROFILE
  );
  const { data: userdata, loading, error } = useQuery<
    GetUser,
    GetUserVariables
  >(GET_USER_QUERY, {
    variables: { id }
  });
  const [status, setStatus] = useState<any>(null);

  if (userdata && userdata.user) {
    const user = userdata.user;
    return (
      <div>
        <Formik
          initialValues={{
            email: user.email,
            userName: user.userName,
            contactNumber: user.contactNumber || '',
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            birthDate: user.birthDate || new Date(),
            license: '',
            password: ''
          }}
          validationSchema={() =>
            object().shape({
              userName: string()
                .label('User Name')
                .required()
                .min(4),
              email: string()
                .email()
                .label('Email')
                .required(),
              password: string().min(8)
            })
          }
          onSubmit={async ({ birthDate, license, ...others }) => {
            try {
              await updateOneUser({
                variables: {
                  update: {
                    ...others,
                    ...(!isToday(birthDate) && { birthDate }),
                    ...(license && { license: { connect: { id: license } } })
                  },
                  userId: user.id
                },
                refetchQueries: [
                  {
                    query: LICENSES_QUERY,
                    variables: {
                      companyId,
                      expires: now.current
                    }
                  },
                  {
                    query: GET_USER_QUERY,
                    variables: { id }
                  }
                ],
                update: (cache, { data }) => {
                  if (data && data.updateOneUser) {
                    const { users } = cache.readQuery({
                      query: COMPANY_USERS_QUERY,
                      variables: { companyId }
                    }) as any;

                    cache.writeQuery({
                      query: COMPANY_USERS_QUERY,
                      variables: { companyId },
                      data: {
                        users: users.map((item: any) => {
                          if (item.id === user.id) {
                            return updateOneUser;
                          }
                          return item;
                        })
                      }
                    });
                  }
                }
              });
              setStatus({
                message: { success: true, text: 'User updated successfully.' }
              });
            } catch {
              setStatus({
                message: {
                  success: false,
                  text: 'There was a problem updating this user.'
                }
              });
            }
          }}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              <Dialog
                maxWidth={'xl'}
                fullWidth={true}
                open={true}
                onClose={() => setShowEditDialog(false)}
                aria-labelledby="form-dialog-title"
                disableBackdropClick
              >
                <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
                <DialogContent>
                  <div>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          autoFocus
                          value={props.values.userName}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          margin="dense"
                          name="userName"
                          label="User Name"
                          type="text"
                          fullWidth
                          required
                          error={!!props.errors.userName}
                          helperText={
                            props.touched.userName && props.errors.userName
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          value={props.values.firstName}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          margin="dense"
                          name="firstName"
                          label="First Name"
                          fullWidth
                          error={!!props.errors.firstName}
                          helperText={
                            props.touched.firstName && props.errors.firstName
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          value={props.values.lastName}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          margin="dense"
                          name="lastName"
                          label="Last Name"
                          fullWidth
                          error={!!props.errors.lastName}
                          helperText={
                            props.touched.lastName && props.errors.lastName
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          value={props.values.email}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          margin="dense"
                          name="email"
                          label="Email"
                          type="email"
                          fullWidth
                          required
                          error={!!props.errors.email}
                          helperText={props.touched.email && props.errors.email}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <TextField
                          value={props.values.contactNumber}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          margin="dense"
                          name="contactNumber"
                          label="Contact Number"
                          fullWidth
                          error={!!props.errors.contactNumber}
                          helperText={
                            props.touched.contactNumber &&
                            props.errors.contactNumber
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <TextField
                          value={props.values.password}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          margin="dense"
                          name="password"
                          label="Password"
                          fullWidth
                          error={!!props.errors.password}
                          helperText={
                            props.touched.password && props.errors.password
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <DatePicker
                          fullWidth
                          disableFuture
                          label="Birth Date"
                          format="dd/MM/yyyy"
                          value={props.values.birthDate}
                          onChange={date =>
                            props.setFieldValue('birthDate', date)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        {data && data.licenses && !user.license && (
                          <FormControl>
                            <InputLabel id="demo-simple-select-helper-label">
                              License
                            </InputLabel>
                            <Select
                              style={{ minWidth: 120 }}
                              name="license"
                              labelId="demo-simple-select-helper-label"
                              id="license"
                              value={props.values.license}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {data.licenses.map(license => (
                                <MenuItem key={license.id} value={license.id}>
                                  <Typography
                                    style={{ fontWeight: 'bold' }}
                                    variant="body2"
                                  >
                                    {license.id}
                                  </Typography>
                                  <Typography variant="caption">
                                    &nbsp;Expires in:&nbsp;
                                    {differenceInDays(
                                      parseISO(license.expires),
                                      new Date()
                                    )}
                                    &nbsp;Days
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Grid>
                    </Grid>
                    {status && status.message && (
                      <Typography
                        variant="caption"
                        style={{
                          color: status.message.success ? '#107c10' : '#a80000',
                          marginTop: 12,
                          display: 'block'
                        }}
                      >
                        {status.message.text}
                      </Typography>
                    )}
                    <br />
                    <br />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setShowEditDialog(false)}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      props.submitForm();
                    }}
                    color="primary"
                    variant="contained"
                  >
                    Update User
                  </Button>
                </DialogActions>
              </Dialog>
            </form>
          )}
        </Formik>
      </div>
    );
  }
  return <LoadingOrError {...{ error, loading }} />;
}

export const UpdateUser = React.memo(UpdateUserComponent);
