import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useWindowSize } from 'hooks/useWindowSize';
import { UpdateCompanyAddRemoveAdmin } from '__generated__/UpdateCompanyAddRemoveAdmin';
import { CompanyWithAdmins } from '__generated__/CompanyWithAdmins';
import { useParams } from 'react-router-dom';
import { LoadingOrError } from 'components/LoadingOrError';
import { Typography, IconButton, Button } from '@material-ui/core';
import { PeoplePicker } from 'components/PeoplePicker';
import DeleteIcon from '@material-ui/icons/Delete';
import { CreateNewAdmin } from './CreateNewAdmin';

const COMPANY_ADMIN_QUERY = gql`
  query CompanyWithAdmins($id: String!) {
    company(where: { id: $id }) {
      id
      name
      admins {
        id
        userName
        email
      }
    }
  }
`;

const ADD_REMOVE_ADMIN = gql`
  mutation UpdateCompanyAddRemoveAdmin(
    $connect: [UserWhereUniqueInput!]
    $disconnect: [UserWhereUniqueInput!]
    $companyId: String!
  ) {
    updateOneCompany(
      data: { admins: { connect: $connect, disconnect: $disconnect } }
      where: { id: $companyId }
    ) {
      id
    }
  }
`;

export function CompanyAdmins() {
  const [addUserDialog, setAddUserDialog] = useState(false);
  const { cid } = useParams();
  const { loading, error, data } = useQuery<CompanyWithAdmins>(
    COMPANY_ADMIN_QUERY,
    {
      variables: {
        id: cid,
      },
    }
  );
  const { contentWidth: width } = useWindowSize();
  const [updateCompany] = useMutation<UpdateCompanyAddRemoveAdmin>(
    ADD_REMOVE_ADMIN
  );
  if (data && data.company) {
    return (
      <div style={{ width, padding: 16, boxSizing: 'border-box' }}>
        <Typography>{data.company.name}</Typography>
        <ul>
          {data.company.admins &&
            data.company.admins.map((admin) => {
              return (
                <li
                  key={admin.id}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {admin.userName} | {admin.email}&nbsp;&nbsp;
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (!data.company) {
                        return;
                      }
                      updateCompany({
                        variables: {
                          disconnect: [{ id: admin.id }],
                          companyId: data.company.id,
                        },
                        refetchQueries: [
                          {
                            query: COMPANY_ADMIN_QUERY,
                            variables: {
                              id: cid,
                            },
                          },
                        ],
                      });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </li>
              );
            })}
        </ul>
        <PeoplePicker
          clearOnSelection
          onChange={(user) => {
            if (!data.company) {
              return;
            }
            updateCompany({
              variables: {
                connect: [{ id: user.id }],
                companyId: data.company.id,
              },
              refetchQueries: [
                {
                  query: COMPANY_ADMIN_QUERY,
                  variables: {
                    id: cid,
                  },
                },
              ],
            });
          }}
          variant="standard"
          company={data.company.id}
        />
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAddUserDialog(true)}
        >
          Add User
        </Button>
        {addUserDialog && (
          <CreateNewAdmin
            companyId={cid as string}
            setShowCreateNewUserModal={setAddUserDialog}
          />
        )}
      </div>
    );
  }

  return <LoadingOrError {...{ loading, error }} />;
}
