import React from 'react';
import {
  Route,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { LoadingOrError } from 'components/LoadingOrError';
import { ML_SCHEDULES_QUERY } from './MLSchedule';
import {
  MicroLearningSchedules,
  MicroLearningSchedulesVariables
} from '__generated__/MicroLearningSchedules';
import {
  Link,
  Typography,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  TableContainer
} from '@material-ui/core';
import {
  MicroLearningSessionAnswers,
  MicroLearningSessionAnswersVariables
} from '__generated__/MicroLearningSessionAnswers';
import {
  MLSessionInfo,
  MLSessionInfoVariables
} from '__generated__/MLSessionInfo';

const SESSION_QUERY = gql`
  query MLSessionInfo($sessID: String!) {
    microLearningSession(where: { id: $sessID }) {
      id
      name
      data
    }
  }
`;

const COURSE_SESSION_ANSWER_DATA = gql`
  query MicroLearningSessionAnswers($course: String!, $session: String!) {
    microLearningSessionAnswers(
      where: {
        course: { id: { equals: $course } }
        session: { id: { equals: $session } }
      }
    ) {
      id
      user {
        id
        email
      }
      createdAt
      data
    }
  }
`;

function SessionAnswers() {
  const { course, session }: any = useParams();
  const { loading, error, data } = useQuery<
    MicroLearningSessionAnswers,
    MicroLearningSessionAnswersVariables
  >(COURSE_SESSION_ANSWER_DATA, {
    variables: { course, session }
  });
  const { data: sessionData } = useQuery<MLSessionInfo, MLSessionInfoVariables>(
    SESSION_QUERY,
    {
      variables: { sessID: session }
    }
  );

  if (
    data &&
    data.microLearningSessionAnswers &&
    sessionData &&
    sessionData.microLearningSession
  ) {
    const sessionInfo = JSON.parse(sessionData.microLearningSession.data);
    return (
      <div style={{ padding: 16 }}>
        <Typography variant="h4">
          {sessionData.microLearningSession.name}
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sr.No.</TableCell>
                <TableCell>User</TableCell>
                {sessionInfo.sections
                  .filter(
                    (sec: any) =>
                      sec.type === 'IMG_QUIZ_ITEM' ||
                      sec.type === 'QUIZ' ||
                      sec.type === 'SPOT_IDENTIFICATION'
                  )
                  .map((section: any) => {
                    return (
                      <TableCell key={section.id}>
                        {section.type === 'IMG_QUIZ_ITEM' &&
                          sessionInfo.details[section.id].question.substring(
                            0,
                            15
                          ) + ' ...'}
                        {section.type === 'QUIZ' && `Quiz: ${section.id}`}
                        {section.type === 'SPOT_IDENTIFICATION' &&
                          `SPOT IDENTIFICATION: ${section.id}`}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.microLearningSessionAnswers.map((answer, i) => {
                const ansData = JSON.parse(answer.data);
                return (
                  <TableRow key={answer.id}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{answer.user.email}</TableCell>
                    {sessionInfo.sections
                      .filter(
                        (sec: any) =>
                          sec.type === 'IMG_QUIZ_ITEM' ||
                          sec.type === 'QUIZ' ||
                          sec.type === 'SPOT_IDENTIFICATION'
                      )
                      .map((section: any) => {
                        return (
                          <TableCell key={section.id}>
                            {`${ansData[section.id].score}%`}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
  return <LoadingOrError {...{ loading, error }} />;
}

export function MLData() {
  const { path } = useRouteMatch();
  const { course } = useParams();
  const { data, loading, error } = useQuery<
    MicroLearningSchedules,
    MicroLearningSchedulesVariables
  >(ML_SCHEDULES_QUERY, {
    variables: { microLearningCourse: course as string }
  });
  if (data && data.microLearningSchedules) {
    return (
      <div>
        <Route exact path={path}>
          <ul>
            {data.microLearningSchedules.map(schedule => {
              return (
                <li key={schedule.id}>
                  <Link
                    to={`data/${schedule.session.id}`}
                    component={RouterLink}
                  >
                    {schedule.session.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </Route>
        <Route path={`${path}/:session`}>
          <SessionAnswers />
        </Route>
      </div>
    );
  }
  return <LoadingOrError {...{ loading, error }} />;
}
