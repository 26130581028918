import React, { useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Quiz, QuizVariables } from '__generated__/Quiz';
import { LoadingOrError } from './LoadingOrError';
import { QUIZ_QUERY } from './company-admin/QuizConfiguration';
import { MicroLearningQuiz } from './micro-learning/MicroLearningQuiz';
import gql from 'graphql-tag';
import { UserContext } from '../App';
import { QuizAnswers, QuizAnswersVariables } from '__generated__/QuizAnswers';
import {
  CreateOneQuizAnswer,
  CreateOneQuizAnswerVariables,
} from '__generated__/CreateOneQuizAnswer';

const QUIZ_ANSWER_QUERY = gql`
  query QuizAnswers($uid: String!, $id: String!) {
    quizAnswers(
      where: { user: { id: { equals: $uid } }, quiz: { id: { equals: $id } } }
    ) {
      id
      data
      createdAt
    }
  }
`;

const CREATE_ANSWER_QUERY = gql`
  mutation CreateOneQuizAnswer($data: String!, $id: String!, $uid: String!) {
    createOneQuizAnswer(
      data: {
        data: $data
        quiz: { connect: { id: $id } }
        user: { connect: { id: $uid } }
      }
    ) {
      id
      data
      createdAt
    }
  }
`;

export function TakeUserQuiz() {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { qid }: any = useParams();
  const { loading, error, data } = useQuery<Quiz, QuizVariables>(QUIZ_QUERY, {
    variables: { id: qid },
  });
  const { data: answer, refetch } = useQuery<QuizAnswers, QuizAnswersVariables>(
    QUIZ_ANSWER_QUERY,
    { variables: { uid: user.id, id: qid } }
  );
  const [createOneQuizAnswer] = useMutation<
    CreateOneQuizAnswer,
    CreateOneQuizAnswerVariables
  >(CREATE_ANSWER_QUERY);

  //   if (answer && answer.quizAnswers.length) {
  //     console.log(JSON.parse(answer.quizAnswers[0].data));
  //   }

  if (data && data.quiz) {
    const config = JSON.parse(data.quiz.config);

    return (
      <div>
        <MicroLearningQuiz
          onSubmit={async (answers, score, cb) => {
            await createOneQuizAnswer({
              variables: {
                data: JSON.stringify({ answers, score }),
                id: qid,
                uid: user.id,
              },
            });
            await refetch();
            cb();
          }}
          results={
            answer && answer.quizAnswers.length
              ? JSON.parse(answer.quizAnswers[0].data)
              : null
          }
          quiz={config}
          onNext={() => {
            history.push('/quiz');
          }}
          preview={false}
        />
      </div>
    );
  }
  return <LoadingOrError {...{ error, loading }} />;
}
