import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { TextField } from '@material-ui/core';

export function ShubhlifeDashboard() {
  const [pass, setPass] = useState('');
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    firebase
      .firestore()
      .collection('shubhlife-session')
      .get()
      .then((snapshot) => {
        setData(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      });
  }, []);

  if (pass !== 'Ahsee1Kuuloh7Ali') {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: 80,
        }}
      >
        <TextField
          label="Admin password"
          fullWidth
          value={pass}
          onChange={(e) => setPass(e.target.value)}
        />
      </div>
    );
  }

  data.sort((a: any, b: any) => {
    return a.time - b.time;
  });

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <th>Name</th>
            <th>Time</th>
          </tr>
          {data.map((item: any, idx: any) => (
            <tr key={idx}>
              <th>{item.id}</th>
              <th>{item.time / 1000}</th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
