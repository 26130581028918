import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Quiz, QuizVariables } from '__generated__/Quiz';
import { LoadingOrError } from 'components/LoadingOrError';
import {
  Button,
  TextField,
  Grid,
  Checkbox,
  IconButton
} from '@material-ui/core';
import { useWindowSize } from 'hooks/useWindowSize';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  UpdateOneQuiz,
  UpdateOneQuizVariables
} from '__generated__/UpdateOneQuiz';

export const QUIZ_QUERY = gql`
  query Quiz($id: String!) {
    quiz(where: { id: $id }) {
      id
      config
      name
      createdAt
    }
  }
`;

const QUIZ_UPDATE_MUTATION = gql`
  mutation UpdateOneQuiz($config: String!, $id: String!) {
    updateOneQuiz(data: { config: $config }, where: { id: $id }) {
      id
      name
      config
      createdAt
    }
  }
`;

type QItems = {
  id: string;
  question: string;
  choices: {
    key: string;
    text: string;
    isCorrect: boolean;
  }[];
  multichoice: boolean;
};

var ID = function() {
  return (
    '_' +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
};

export function QuizConfiguration() {
  const { contentWidth } = useWindowSize();
  const { qid }: any = useParams();
  const { loading, error, data } = useQuery<Quiz, QuizVariables>(QUIZ_QUERY, {
    variables: { id: qid }
  });
  const [qdata, setQdata] = useState<QItems[]>([]);
  const [updateOneQuiz] = useMutation<UpdateOneQuiz, UpdateOneQuizVariables>(
    QUIZ_UPDATE_MUTATION
  );

  useEffect(() => {
    console.log('data changed');
    if (data && data.quiz) {
      const config = JSON.parse(data.quiz.config);
      if (config.length) {
        setQdata(config);
      }
    }
  }, [data]);

  const addQuestion = useCallback(() => {
    setQdata([
      ...qdata,
      { id: ID(), question: '', choices: [], multichoice: false }
    ]);
  }, [qdata]);

  if (data && data.quiz) {
    return (
      <div
        style={{ width: contentWidth, boxSizing: 'border-box', padding: 16 }}
      >
        <div style={{ display: 'flex' }}>
          <Button size="small" variant="contained" onClick={addQuestion}>
            Add Question
          </Button>
          &nbsp;&nbsp;
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              updateOneQuiz({
                variables: {
                  id: qid,
                  config: JSON.stringify(qdata)
                },
                refetchQueries: [{ query: QUIZ_QUERY, variables: { id: qid } }]
              });
            }}
          >
            Save
          </Button>
        </div>
        <br />
        <Grid container>
          {qdata.map(question => {
            return (
              <Grid item xs={12} key={question.id}>
                <div style={{ display: 'flex' }}>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Question"
                    value={question.question}
                    onChange={e => {
                      setQdata(
                        qdata.map(q => {
                          if (q.id === question.id) {
                            return { ...q, question: e.target.value };
                          }
                          return q;
                        })
                      );
                    }}
                  ></TextField>
                  &nbsp;&nbsp;
                  <IconButton
                    size="small"
                    onClick={() => {
                      setQdata(
                        qdata.filter(q => {
                          return q.id !== question.id;
                        })
                      );
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
                <ul>
                  {question.choices.map(choice => {
                    return (
                      <li key={choice.key} style={{ display: 'flex' }}>
                        <TextField
                          fullWidth
                          size="small"
                          value={choice.text}
                          onChange={e => {
                            setQdata(
                              qdata.map(q => {
                                if (q.id === question.id) {
                                  return {
                                    ...q,
                                    choices: q.choices.map(ch => {
                                      if (ch.key === choice.key) {
                                        return {
                                          ...choice,
                                          text: e.target.value
                                        };
                                      }
                                      return ch;
                                    })
                                  };
                                }
                                return q;
                              })
                            );
                          }}
                        ></TextField>
                        &nbsp;&nbsp;
                        <Checkbox
                          checked={choice.isCorrect}
                          value={choice.key}
                          onChange={e => {
                            setQdata(
                              qdata.map(q => {
                                if (q.id === question.id) {
                                  return {
                                    ...q,
                                    choices: q.choices.map(ch => {
                                      if (ch.key === choice.key) {
                                        return {
                                          ...choice,
                                          isCorrect: e.target.checked
                                        };
                                      }
                                      return ch;
                                    })
                                  };
                                }
                                return q;
                              })
                            );
                          }}
                        />
                        &nbsp;&nbsp;
                        <IconButton
                          size="small"
                          onClick={() => {
                            setQdata(
                              qdata.map(q => {
                                if (q.id === question.id) {
                                  return {
                                    ...q,
                                    choices: q.choices.filter(ch => {
                                      return ch.key !== choice.key;
                                    })
                                  };
                                }
                                return q;
                              })
                            );
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </li>
                    );
                  })}
                  <li style={{ marginTop: 8 }}>
                    <Button
                      size="small"
                      onClick={() => {
                        setQdata(
                          qdata.map(q => {
                            if (q.id === question.id) {
                              return {
                                ...q,
                                choices: [
                                  ...q.choices,
                                  {
                                    key: ID(),
                                    text: `Option ${q.choices.length + 1}`,
                                    isCorrect: false
                                  }
                                ]
                              };
                            }
                            return q;
                          })
                        );
                      }}
                    >
                      Add Choice
                    </Button>
                  </li>
                </ul>
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }

  return <LoadingOrError {...{ error, loading }} />;
}
