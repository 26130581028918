import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { Grid, Typography, Button } from '@material-ui/core';
import { useHistory, useLocation, Link as RouterLink } from 'react-router-dom';
import { FormikTextField } from '../FormFields/FormikTextField';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Alert from '@material-ui/lab/Alert';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const $window: any = window;

function PhoneAuth({ setAuthType }: { setAuthType: any }) {
  const classes = useStyles();
  const [
    confirmationResult,
    setConfirmationResult,
  ] = useState<firebase.auth.ConfirmationResult | null>(null);

  return (
    <Formik
      initialValues={{
        mobileNumber: '+91',
        confirmCode: '',
      }}
      onSubmit={(values, actions) => {
        actions.setStatus(null);
        confirmationResult?.confirm(values.confirmCode).catch((error) => {
          actions.setStatus({
            type: 'Error',
            message: 'Confirmation code is either invalid or expired',
          });
        });
      }}
      validationSchema={() =>
        object({
          mobileNumber: string().label('Mobile number').min(10).required(),
        })
      }
    >
      {(formikProps) => (
        <Form className={classes.form}>
          {!confirmationResult && (
            <>
              <Field
                label="Mobile number"
                name="mobileNumber"
                placeholder="Enter mobile number"
                component={FormikTextField}
              />
              {formikProps.status && formikProps.status.type === 'Error' && (
                <Typography color="error" variant="caption">
                  {(formikProps as any).status.message}
                </Typography>
              )}
              <br />
              <br />
              <Button
                id="sign-in-button"
                style={{ width: '100%' }}
                onClick={() => {
                  if (!formikProps.isValid || !formikProps.dirty) {
                    return;
                  }
                  const appVerifier = $window.recaptchaVerifier;
                  firebase
                    .auth()
                    .signInWithPhoneNumber(
                      formikProps.values.mobileNumber,
                      appVerifier
                    )
                    .then((confirmationResult) => {
                      // SMS sent. Prompt user to type the code from the message, then sign the
                      // user in with confirmationResult.confirm(code).
                      formikProps.setStatus(null);
                      setConfirmationResult(confirmationResult);
                    })
                    .catch(function (error) {
                      formikProps.setStatus({
                        type: 'Error',
                        message: 'Please check your mobile number',
                      });
                      $window.recaptchaVerifier
                        .render()
                        .then(function (widgetId: any) {
                          $window.grecaptcha.reset(widgetId);
                        });
                    });
                }}
                variant="contained"
                color="primary"
              >
                Verify mobile number
              </Button>
              <br />
              <br />
              <Button
                style={{ width: '100%' }}
                onClick={() => {
                  setAuthType('Email');
                }}
                variant="outlined"
                color="secondary"
              >
                Sign in with Email
              </Button>
            </>
          )}
          {confirmationResult && (
            <>
              <Field
                label="Confirmation code"
                name="confirmCode"
                placeholder="Enter confirmation code"
                component={FormikTextField}
              />
              {formikProps.status && formikProps.status.type === 'Error' && (
                <Typography color="error" variant="caption">
                  {(formikProps as any).status.message}
                </Typography>
              )}
              <br />
              <br />
              <Button
                style={{ width: '100%' }}
                type="submit"
                variant="contained"
                color="primary"
              >
                Login
              </Button>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

function EmailAuth({ setAuthType }: { setAuthType: any }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={() =>
        object({
          email: string().email().required('Email is required'),
          password: string().required('Password is required'),
        })
      }
      onSubmit={async (
        values,
        { setSubmitting, setStatus /* setValues and other goodies */ }
      ) => {
        try {
          await firebase
            .auth()
            .signInWithEmailAndPassword(values.email, values.password);
        } catch (error) {
          console.log(error);
          setSubmitting(false);
          if (error.code === 'auth/user-not-found') {
            setStatus({
              message: 'We could not find this user!',
              type: 'error',
            });
          } else if (error.code === 'auth/wrong-password') {
            setStatus({
              message: 'Seems like you entered wrong password!',
              type: 'error',
            });
          } else {
            setStatus({
              message: 'We failed to authenticate you!',
              type: 'error',
            });
          }
        }
      }}
    >
      {(formikProps) => (
        <Form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formikProps.values.email}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            error={formikProps.touched.email && !!formikProps.errors.email}
            helperText={formikProps.touched.email && formikProps.errors.email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formikProps.values.password}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            error={
              formikProps.touched.password && !!formikProps.errors.password
            }
            helperText={
              formikProps.touched.password && formikProps.errors.password
            }
          />
          {/* <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          {formikProps.status && (
            <Alert severity={formikProps.status.type}>
              {formikProps.status.message}
            </Alert>
          )}
          {}
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to="forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to="sign-up" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export function FirebaseLogin() {
  const classes = useStyles();
  const history = useHistory();
  let location: any = useLocation();
  let { from } = location.state || { from: { pathname: '/' } };
  const [authType, setAuthType] = useState('phone');

  useEffect(() => {
    firebase.auth().useDeviceLanguage();
    if (authType === 'phone') {
      $window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'sign-in-button',
        {
          size: 'invisible',
          callback: (response: any) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
        }
      );
    }
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        history.replace(from);
      }
    });
    return () => {
      unsubscribe();
      $window.recaptchaVerifier = null;
    };
  }, [from, history, authType]);

  return (
    <div style={{ marginTop: 150 }}>
      <div>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            {authType === 'phone' ? (
              <PhoneAuth setAuthType={setAuthType} />
            ) : (
              <EmailAuth setAuthType={setAuthType} />
            )}
          </div>
        </Container>
      </div>
    </div>
  );
}
