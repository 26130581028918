import React from 'react';
import Downshift from 'downshift';
import {
  makeStyles,
  TextField,
  Popper,
  Paper,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { useRef, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useDebounce } from 'hooks/useDebounce';
import { SearchUsers } from '__generated__/SearchUsers';

const SEARCH_USERS_QUERY = gql`
  query SearchUsers($email_contains: String!, $company: CompanyWhereInput) {
    users(where: { email: { contains: $email_contains }, company: $company }) {
      id
      email
      userName
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing(1 / 2)}px ${theme.spacing(1 / 4)}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

function renderInput(inputProps: any) {
  const { InputProps, classes, ref, variant, ...other } = inputProps;

  return (
    <TextField
      variant={variant}
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
}: any) {
  const isHighlighted = highlightedIndex === index;
  const isSelected =
    (selectedItem || { userName: '' }).userName.indexOf(suggestion.userName) >
    -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.userName}{' '}
      <Typography variant="caption">&nbsp;&nbsp;{suggestion.email}</Typography>
    </MenuItem>
  );
}

export function PeoplePicker({
  onChange,
  variant,
  company,
  clearOnSelection,
}: {
  onChange: (user: { id: string; userName: string; email: string }) => void;
  variant: 'filled' | 'outlined' | 'standard';
  company: string | undefined;
  clearOnSelection?: boolean;
}) {
  const classes = useStyles({});
  const ref: any = useRef(null);
  const [search, setSearch] = useState('***');
  const debouncedSearchTerm = useDebounce(search, 500);
  const { data } = useQuery<SearchUsers>(SEARCH_USERS_QUERY, {
    variables: {
      email_contains: debouncedSearchTerm,
      ...(company && { company: { id: { equals: company } } }),
    },
  });
  const { users } = data && data.users ? data : { users: [] };

  return (
    <Downshift
      id="downshift-popper"
      onChange={(selection, { clearSelection }) => {
        if (selection) {
          onChange(selection);
        }
        if (clearOnSelection) {
          clearSelection();
        }
      }}
      itemToString={(item) => (item ? item.userName : '')}
      onInputValueChange={(text) => setSearch(text)}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        selectedItem,
        // clearSelection
      }) => (
        <div className={classes.container}>
          {renderInput({
            fullWidth: true,
            classes,
            InputProps: getInputProps({
              placeholder: 'Enter user email',
            }),
            ref,
            name: 'user',
            label: 'Search User',
            id: 'user',
            variant,
          })}
          <Popper open={isOpen} anchorEl={ref.current} placement="bottom">
            <div
              {...(isOpen ? getMenuProps({}, { suppressRefError: true }) : {})}
            >
              <Paper
                square
                style={{
                  marginTop: 8,
                  width: ref.current ? (ref as any).current.clientWidth : null,
                  maxHeight: 400,
                  overflowY: 'scroll',
                }}
              >
                {users
                  .filter((user) =>
                    inputValue
                      ? user.email
                        ? user.email.toLowerCase().includes(inputValue)
                        : false
                      : false
                  )
                  .map((suggestion: any, index: any) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem,
                    })
                  )}
              </Paper>
            </div>
          </Popper>
        </div>
      )}
    </Downshift>
  );
}
