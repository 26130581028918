import React from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import { useScript } from 'hooks/useScript';

const $window: any = window;

$window.API = {
  LMSInitialize: () => {
    console.log('LMS is initialized...');
    return true;
  },
  LMSGetValue: (event: any) => {
    console.log('LMS get value', event);
    if (event === 'cmi.core.lesson_location') {
      return 'Slide_21';
    }
    if (event === 'cmi.core.lesson_mode') {
      return 'normal';
    }
    if (event === 'cmi.core.lesson_status') {
      return 'incomplete';
    }
    if (event === 'cmi.suspend_data') {
      // return 'U1A%24nP1A%24nP1A%24nP1A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP1A%24nP1A%24nP1A%24nP1A%24nP1A%24nP1A%24nP1A%24nP1A%24nP1A%24nP0A%24nP0A%24nP001BA0001C19INA%7E%24NX*LAc40%7E%24NX*f1d401010B00KCAD0%3B1BAB1B1BBB0B0OB%7E%24NX*lTj40%7E%24NX*5sl401010B00KEAH0%3B1%3B2%3B3BAB0B0BBB0B0BCB0B0BDB1B1PC%7E%24NX*GBn40%7E%24NX*Qbo401010B00KEAH0%3B1%3B2%3B3BAB1B1BBB0B0BCB0B0BDB0B0QD%7E%24NX*-Kp40%7E%24NX*Unr401010B00KEAH0%3B1%3B2%3B3BAB0B0BBB0B0BCB0B0BDB1B1RE%7E%24NX*bWs40%7E%24NX*UFt401010B00KEAH0%3B1%3B2%3B3BAB0B0BBB1B1BCB0B0BDB0B0SF%7E%24NX*9vt40%7E%24NX*0Iv401010B00KCAD0%3B1BAB1B1BBB0B0TG%7E%24NX*Kxv40%7E%24NX*-_w401000B00AEAH0%3B1%3B2%3B3BAB0B0BBB1B0BCB0B1BDB0B0UH%7E%24NX*FRy40A0001A00AAAA0DTcpQuizInfoStudentIDBAVcpQuizInfoStudentNameAJAjay%20MoreQcpQuizHandledAllBA$_#-#_$';
      return '';
    }
    if (event === 'cmi.core.student_name') {
      return 'Ajay More';
    }
  },
  LMSSetValue: (element: any, value: any) => {
    console.log('set val', element, value);
  },
  LMSGetLastError: (err: any) => {
    if (err) {
      console.log('Error', err);
    }
  },
  LMSGetErrorString: (err: any) => {
    if (err) {
      console.log('Error', err);
    }
  },
  LMSGetDiagnostic: () => {
    return '';
  },
  LMSFinish: (data: any) => {
    console.log('Finish', data);
  },
  LMSCommit: (commit: any) => {
    console.log('Finish', commit);
    return true;
  },
};

export function ScromTest() {
  const { width, height } = useWindowSize();
  const [loaded] = useScript('scormAPI.min.js');

  if (loaded) {
    return (
      <div>
        <iframe
          title="scrom"
          id="iframe"
          name="myIframe"
          width={width}
          height={height}
          src="scrom-loader/scrom-test-app/index_scorm.html"
        ></iframe>
      </div>
    );
  }

  return <div>Loading...</div>;
}
