import React, { useState } from 'react';
import SpotIdentification from 'components/micro-learning/SpotIdentification';
import { useHistory } from 'react-router-dom';

const info = {
  showTestHelper: true,
  img:
    'https://storage.googleapis.com/pl-public-assets/shubhlife/campaign-1.jpg',
  dimensions: {
    height: 1358,
    width: 1980
  },
  predefs: [
    {
      x: 0.8277448115458015,
      y: 0.35566902716022664
    },
    {
      x: 0.887508945610687,
      y: 0.8452574254277732
    },
    {
      x: 0.7160961354961832,
      y: 0.5788662955738682
    },
    {
      x: 0.6505620825381679,
      y: 0.2925086021905811
    },
    {
      x: 0.5543147662213741,
      y: 0.4232966582325265
    },
    {
      x: 0.6977129055343512,
      y: 0.9096134530039685
    },
    {
      x: 0.5199114384541985,
      y: 0.5652255567445952
    },
    {
      x: 0.42705599952290074,
      y: 0.5
    },
    {
      x: 0.6173440481870229,
      y: 0.6809055434855928
    },
    {
      x: 0.48278715410305345,
      y: 0.7287405168621907
    },
    {
      x: 0.20451156965648856,
      y: 0.8336166036858199
    },
    {
      x: 0.24496809398854963,
      y: 0.9168957597893174
    },
    {
      x: 0.2100056655534351,
      y: 0.7287079095183756
    },
    {
      x: 0.28640863549618323,
      y: 0.8024331138840796
    },
    {
      x: 0.3029654699427481,
      y: 0.3411478900479488
    },
    {
      x: 0.49627266221374045,
      y: 0.08162604062440275
    },
    {
      x: 0.14998807251908397,
      y: 0.38750466383826687
    },
    {
      x: 0.02483152433206107,
      y: 0.24293457047718356
    },
    {
      x: 0.0318761927480916,
      y: 0.40803642132710877
    },
    {
      x: 0.05805701335877863,
      y: 0.8869730872817568
    },
    {
      x: 0.3960735628536675,
      y: 0.2557233072966867
    },
    {
      x: 0.03481701835104976,
      y: 0.583970113116764
    }
  ]
};

export function SafetyWeekSpotIdentification() {
  const [results, setResults] = useState<any>([]);
  const history = useHistory();
  return (
    <div>
      <SpotIdentification
        dims={info.dimensions}
        exitIfFullscreen={true}
        imgUrl={info.img}
        onCaptures={data => {
          setResults(data);
        }}
        results={results}
        onNext={() => {
          history.push('/safety-week-2020');
        }}
        predefs={info.predefs}
        showTestHelper={true}
        title="Hazard Identification"
      />
    </div>
  );
}
