import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from 'theme';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { getMainDefinition } from 'apollo-utilities';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import Button from 'antd/es/button/button';
import 'antd/dist/antd.css';
import { notification } from 'antd';
import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://e56922691f9f4a38bc5aa31b66bc804b@o295469.ingest.sentry.io/5281417',
  });
}

const firebaseConfig = {
  apiKey: 'AIzaSyCgrtmBItNE92mH9zWRWbqa7Vq7QCHDACQ',
  authDomain: 'parallel-customer.firebaseapp.com',
  databaseURL: 'https://parallel-customer.firebaseio.com',
  projectId: 'parallel-customer',
  storageBucket: 'parallel-customer.appspot.com',
  messagingSenderId: '861628498423',
  appId: '1:861628498423:web:a5a39808dd43e1fc',
};

firebase.initializeApp(firebaseConfig);

export const API_DOMAIN =
  process.env.NODE_ENV === 'production'
    ? 'api.i4pt0.parallellearning.in'
    : 'localhost:3001';
export const API_URL =
  process.env.NODE_ENV === 'production'
    ? `https://${API_DOMAIN}`
    : `http://${API_DOMAIN}`;

const wsLink = new WebSocketLink({
  uri:
    process.env.NODE_ENV === 'production'
      ? `wss://${API_DOMAIN}/graphql`
      : `ws://${API_DOMAIN}/graphql`,
  options: {
    reconnect: true,
    connectionParams: async () => {
      return {
        authToken: sessionStorage.getItem('token') || '',
      };
    },
  },
});

const httpLink = createHttpLink({
  uri: `${API_URL}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await firebase.auth().currentUser?.getIdToken(true);
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  // link,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.error(`[GraphQL error]: Message: ${message}`, path)
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    link,
  ]),
  cache: new InMemoryCache(),
});

function WithServiceWorker() {
  const close = useCallback(() => {
    console.log(
      'Notification was closed. Either the close button was clicked or duration time elapsed.'
    );
  }, []);

  const openNotification = useCallback(() => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          notification.close(key);
        }}
      >
        Ok
      </Button>
    );
    notification.open({
      duration: 0,
      message: 'Update available',
      description:
        'New content is available and will be used when all tabs for this page are closed.',
      btn,
      key,
      onClose: close,
    });
  }, [close]);

  React.useEffect(() => {
    serviceWorker.register({
      onUpdate: async (registration) => {
        openNotification();
      },
    });
  }, [openNotification]);

  return (
    <I18nextProvider i18n={i18n}>
      <ApolloProvider client={client}>
        <Router>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <App />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Router>
      </ApolloProvider>
    </I18nextProvider>
  );
}

ReactDOM.render(<WithServiceWorker />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register({
//   onUpdate: async (registration) => {
//     // We want to run this code only if we detect a new service worker is
//     // waiting to be activated.
//     // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
//     if (registration && registration.waiting) {
//       await registration.unregister();
//       // Once the service worker is unregistered, we can reload the page to let
//       // the browser download a fresh copy of our app (invalidating the cache)
//       window.location.reload();
//     }
//   },
// });
// serviceWorker.unregister();
