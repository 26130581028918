import React, { useEffect, useState, useContext } from 'react';
import {
  useParams,
  Route,
  useRouteMatch,
  Link as RouterLink,
  useLocation
} from 'react-router-dom';
import { useWindowSize } from 'hooks/useWindowSize';
import { Button } from '@material-ui/core';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/monokai';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { AlertContext } from 'App';
import { MicrolearningSessionPreview } from './MicrolearningSessionPreview';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { LoadingOrError } from 'components/LoadingOrError';
import {
  MicroLearningSessionGET,
  MicroLearningSessionGETVariables
} from '__generated__/MicroLearningSessionGET';
import {
  UpdateOneMicroLearningSessionData,
  UpdateOneMicroLearningSessionDataVariables
} from '__generated__/UpdateOneMicroLearningSessionData';

export const GET_SESSION_QUERY = gql`
  query MicroLearningSessionGET($id: String!) {
    microLearningSession(where: { id: $id }) {
      id
      name
      data
    }
  }
`;

const UDPATE_SESSION_DATA = gql`
  mutation UpdateOneMicroLearningSessionData($data: String!, $id: String!) {
    updateOneMicroLearningSession(data: { data: $data }, where: { id: $id }) {
      id
      name
      data
    }
  }
`;

export default function MicrolearningSessionData() {
  const { id }: any = useParams();
  const [info, setInfo] = useState(null);
  const { width, height } = useWindowSize();
  const { showAlert } = useContext(AlertContext);
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const [updateOneMicroLearningSession] = useMutation<
    UpdateOneMicroLearningSessionData,
    UpdateOneMicroLearningSessionDataVariables
  >(UDPATE_SESSION_DATA);
  const { loading, error, data } = useQuery<
    MicroLearningSessionGET,
    MicroLearningSessionGETVariables
  >(GET_SESSION_QUERY, {
    variables: {
      id
    }
  });

  useEffect(() => {
    if (data && data.microLearningSession) {
      setInfo(JSON.parse(data.microLearningSession.data));
    }
  }, [data]);

  const handleSave = async () => {
    try {
      await updateOneMicroLearningSession({
        variables: {
          data: JSON.stringify(info as any),
          id
        }
      });
      showAlert({ message: 'Data saved successfully!', type: 'Info' });
    } catch (err) {
      console.error(err);
    }
  };

  if (data && data.microLearningSession && info) {
    return (
      <div>
        <Route exact path={path}>
          <div style={{ width: width, height: height }}>
            <Button
              size="small"
              component={RouterLink}
              to={`/parallel-learning/micro-learning-sessions`}
            >
              Back
            </Button>
            <Button size="small" onClick={handleSave}>
              Save
            </Button>
            &nbsp;&nbsp;
            <Button
              size="small"
              component={RouterLink}
              to={`${pathname}/preview`}
            >
              Preview
            </Button>
            <Editor
              htmlElementProps={{
                style: { width: width, height: height - 30 }
              }}
              value={info}
              onChange={setInfo}
              ace={ace}
              theme="ace/theme/monokai"
              mode={'code'}
            />
          </div>
        </Route>
        <Route path={`${path}/preview`}>
          <MicrolearningSessionPreview
            data={JSON.parse(data.microLearningSession.data)}
          />
        </Route>
      </div>
    );
  }

  return <LoadingOrError {...{ loading, error }} />;
}
