import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Locations_Categories_AssignToes,
  Locations_Categories_AssignToesVariables
} from '__generated__/Locations_Categories_AssignToes';
import { useParams } from 'react-router-dom';
import { LoadingOrError } from 'components/LoadingOrError';
import {
  Grid,
  Typography,
  IconButton,
  TextField,
  Button
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  CreateOneAssignTo,
  CreateOneAssignToVariables
} from '__generated__/CreateOneAssignTo';
import {
  CreateOneLocation,
  CreateOneLocationVariables
} from '__generated__/CreateOneLocation';
import {
  DeleteOneLocation,
  DeleteOneLocationVariables
} from '__generated__/DeleteOneLocation';
import {
  CreateOneCategoryVariables,
  CreateOneCategory
} from '__generated__/CreateOneCategory';
import { DeleteOneCategory } from '__generated__/DeleteOneCategory';
import {
  DeleteOneAssignTo,
  DeleteOneAssignToVariables
} from '__generated__/DeleteOneAssignTo';

const Locations_Categories_AssignToes_QUERY = gql`
  query Locations_Categories_AssignToes($company: String!) {
    locations(where: { company: { id: { equals: $company } } }) {
      id
      name
    }
    assignTos(where: { company: { id: { equals: $company } } }) {
      id
      name
    }
    categories(where: { company: { id: { equals: $company } } }) {
      id
      name
    }
  }
`;

const CREATE_LOCATION_MUTATION = gql`
  mutation CreateOneLocation($name: String!, $company: String!) {
    createOneLocation(
      data: { name: $name, company: { connect: { id: $company } } }
    ) {
      id
      name
    }
  }
`;

const DELETE_LOCATION_MUTATION = gql`
  mutation DeleteOneLocation($id: String!) {
    deleteOneLocation(where: { id: $id }) {
      id
    }
  }
`;

const CREATE_CATEGORY_MUTATION = gql`
  mutation CreateOneCategory($name: String!, $company: String!) {
    createOneCategory(
      data: { name: $name, company: { connect: { id: $company } } }
    ) {
      id
      name
    }
  }
`;

const DELETE_CATEGORY_MUTATION = gql`
  mutation DeleteOneCategory($id: String!) {
    deleteOneCategory(where: { id: $id }) {
      id
    }
  }
`;

const CREATE_ASSIGNTO_MUTATION = gql`
  mutation CreateOneAssignTo($name: String!, $company: String!) {
    createOneAssignTo(
      data: { name: $name, company: { connect: { id: $company } } }
    ) {
      id
      name
    }
  }
`;

const DELETE_ASSIGNTO_MUTATION = gql`
  mutation DeleteOneAssignTo($id: String!) {
    deleteOneAssignTo(where: { id: $id }) {
      id
    }
  }
`;

export function SafetyEyeConfiguration() {
  const { cid } = useParams();
  const { data, loading, error } = useQuery<
    Locations_Categories_AssignToes,
    Locations_Categories_AssignToesVariables
  >(Locations_Categories_AssignToes_QUERY, {
    variables: {
      company: cid as string
    }
  });
  const [createOneLocation] = useMutation<
    CreateOneLocation,
    CreateOneLocationVariables
  >(CREATE_LOCATION_MUTATION);
  const [deleteOneLocation] = useMutation<
    DeleteOneLocation,
    DeleteOneLocationVariables
  >(DELETE_LOCATION_MUTATION);
  const [createOneCategory] = useMutation<
    CreateOneCategory,
    CreateOneCategoryVariables
  >(CREATE_CATEGORY_MUTATION);
  const [deleteOneCategory] = useMutation<
    DeleteOneCategory,
    DeleteOneLocationVariables
  >(DELETE_CATEGORY_MUTATION);
  const [createOneAssignTo] = useMutation<
    CreateOneAssignTo,
    CreateOneAssignToVariables
  >(CREATE_ASSIGNTO_MUTATION);
  const [deleteOneAssignTo] = useMutation<
    DeleteOneAssignTo,
    DeleteOneAssignToVariables
  >(DELETE_ASSIGNTO_MUTATION);
  const [location, setLocation] = useState('');
  const [category, setCategory] = useState('');
  const [assignTo, setAssignTo] = useState('');

  if (data && data.assignTos && data.categories && data.locations) {
    return (
      <div style={{ padding: 16 }}>
        <Grid container spacing={3}>
          <Grid xs={12} md={6} item>
            <div>
              <Typography variant="h4">Locations</Typography>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TextField
                  size="small"
                  fullWidth
                  label="Name"
                  value={location}
                  onChange={e => {
                    setLocation(e.target.value);
                  }}
                />
                &nbsp;&nbsp;
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    if (!location) {
                      return;
                    }
                    await createOneLocation({
                      variables: {
                        company: cid as string,
                        name: location
                      },
                      refetchQueries: [
                        {
                          query: Locations_Categories_AssignToes_QUERY,
                          variables: { company: cid as string }
                        }
                      ]
                    });
                    setLocation('');
                  }}
                >
                  Add
                </Button>
              </div>
              <ul style={{ height: 200, overflowY: 'scroll' }}>
                {data.locations.map(location => (
                  <li key={location.id}>
                    {location.name}&nbsp;&nbsp;
                    <IconButton
                      size="small"
                      onClick={() => {
                        deleteOneLocation({
                          variables: {
                            id: location.id
                          },
                          refetchQueries: [
                            {
                              query: Locations_Categories_AssignToes_QUERY,
                              variables: { company: cid as string }
                            }
                          ]
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
          <Grid xs={12} md={6} item>
            <div>
              <Typography variant="h4">Categories</Typography>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TextField
                  size="small"
                  fullWidth
                  label="Name"
                  value={category}
                  onChange={e => {
                    setCategory(e.target.value);
                  }}
                />
                &nbsp;&nbsp;
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    if (!category) {
                      return;
                    }
                    await createOneCategory({
                      variables: {
                        company: cid as string,
                        name: category
                      },
                      refetchQueries: [
                        {
                          query: Locations_Categories_AssignToes_QUERY,
                          variables: { company: cid as string }
                        }
                      ]
                    });
                    setCategory('');
                  }}
                >
                  Add
                </Button>
              </div>
              <ul style={{ height: 200, overflowY: 'scroll' }}>
                {data.categories.map(category => (
                  <li key={category.id}>
                    {category.name}&nbsp;&nbsp;
                    <IconButton
                      size="small"
                      onClick={() => {
                        deleteOneCategory({
                          variables: {
                            id: category.id
                          },
                          refetchQueries: [
                            {
                              query: Locations_Categories_AssignToes_QUERY,
                              variables: { company: cid as string }
                            }
                          ]
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
          <Grid xs={12} md={6} item>
            <div>
              <Typography variant="h4">Assign To</Typography>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TextField
                  size="small"
                  fullWidth
                  label="Name"
                  value={assignTo}
                  onChange={e => {
                    setAssignTo(e.target.value);
                  }}
                />
                &nbsp;&nbsp;
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    if (!assignTo) {
                      return;
                    }
                    await createOneAssignTo({
                      variables: {
                        company: cid as string,
                        name: assignTo
                      },
                      refetchQueries: [
                        {
                          query: Locations_Categories_AssignToes_QUERY,
                          variables: { company: cid as string }
                        }
                      ]
                    });
                    setAssignTo('');
                  }}
                >
                  Add
                </Button>
              </div>
              <ul style={{ height: 200, overflowY: 'scroll' }}>
                {data.assignTos.map(assignTo => (
                  <li key={assignTo.id}>
                    {assignTo.name}&nbsp;&nbsp;
                    <IconButton
                      size="small"
                      onClick={() => {
                        deleteOneAssignTo({
                          variables: {
                            id: assignTo.id
                          },
                          refetchQueries: [
                            {
                              query: Locations_Categories_AssignToes_QUERY,
                              variables: { company: cid as string }
                            }
                          ]
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  return <LoadingOrError {...{ loading, error }} />;
}
