import React, { useState, useEffect } from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import { API_URL } from 'index';
import { InteractionPlayer } from 'components/InteractionPlayer';

const useStyles = makeStyles(() => {
  return {
    inputCtn: {
      margin: '0 auto',
      position: 'relative',
      width: 250
    },
    input: {
      border: '2px solid #0072BB',
      padding: 12,
      borderRadius: 24,
      width: '100%',
      marginBottom: 20,
      paddingLeft: 50,
      fontWeight: 'bold',
      outline: 'none'
    },
    sbmt: {
      backgroundColor: '#0071BD',
      color: '#fff',
      padding: 12,
      width: 250,
      borderRadius: 24,
      fontWeight: 'bold'
    },
    icon: { position: 'absolute', top: 8, left: 16 }
  };
});

const data = {
  '1': [
    {
      type: 'Rectangle',
      startX: 0.3679927667269439,
      startY: 0.8272014475271411,
      width: 0.2631103074141049,
      height: 0.08323281061519903,
      action: { onClick: { seek: '00:04' } }
    }
  ],
  '5': [
    {
      type: 'Rectangle',
      startX: 0.026220614828209764,
      startY: 0.5835343787696019,
      width: 0.17811934900542495,
      height: 0.2195416164053076,
      action: { onClick: { seek: '00:06' } }
    },
    {
      type: 'Rectangle',
      startX: 0.20976491862567812,
      startY: 0.5859469240048251,
      width: 0.18806509945750452,
      height: 0.22074788902291917,
      action: { onClick: { seek: '00:10' } }
    },
    {
      type: 'Rectangle',
      startX: 0.40596745027124775,
      startY: 0.5883594692400482,
      width: 0.18896925858951175,
      height: 0.2195416164053076,
      action: { onClick: { seek: '00:14' } }
    },
    {
      type: 'Rectangle',
      startX: 0.6003616636528029,
      startY: 0.5895657418576599,
      width: 0.18173598553345388,
      height: 0.21712907117008443,
      action: { onClick: { seek: '00:22' } }
    },
    {
      type: 'Rectangle',
      startX: 0.786618444846293,
      startY: 0.5871531966224367,
      width: 0.18806509945750452,
      height: 0.22074788902291917,
      action: { onClick: { seek: '00:26' } }
    }
  ],
  '7': [
    {
      type: 'Rectangle',
      startX: 0.003616636528028933,
      startY: 0.009348612786489748,
      width: 0.054249547920433995,
      height: 0.07237635705669482,
      action: { onClick: { seek: '00:07' } }
    }
  ],
  '11': [
    {
      type: 'Rectangle',
      startX: 0.009945750452079566,
      startY: 0.016586248492159228,
      width: 0.023508137432188065,
      height: 0.031363088057901084,
      action: { onClick: { seek: '00:11' } }
    }
  ],
  '15': [
    {
      type: 'Rectangle',
      startX: 0.0108499095840868,
      startY: 0.015379975874547648,
      width: 0.02802893309222423,
      height: 0.04101326899879373,
      action: { onClick: { seek: '00:15' } }
    }
  ],
  '19': [
    {
      type: 'Rectangle',
      startX: 0.012658227848101266,
      startY: 0.012967430639324488,
      width: 0.024412296564195298,
      height: 0.03498190591073583,
      action: { onClick: { seek: '00:19' } }
    }
  ],
  '23': [
    {
      type: 'Rectangle',
      startX: 0.009945750452079566,
      startY: 0.020205066344993968,
      width: 0.0325497287522604,
      height: 0.04945717732207479,
      action: { onClick: { seek: '00:23' } }
    }
  ]
};

export function DemoFeedbackApp() {
  const classes = useStyles();
  const [screen, setScreen] = useState('LOGIN');
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    fetch(
      `${API_URL}/signed-url?bucket=${'pl-signed-assets'}&fileKey=${'Feedback App Design_SC_E-01.mp4'}`
    )
      .then((res: any) => res.json())
      .then(res => {
        return setVideoUrl(res.url);
      });
  }, []);

  if (screen === 'LOGIN') {
    return (
      <div style={{ position: 'relative' }}>
        {/* <img
          src="https://storage.googleapis.com/pl-public-assets/feedback-app/Upside Left Corner_SC_E.svg"
          style={{ position: 'absolute', width: 250, top: -80, left: 0 }}
        /> */}
        <Typography
          variant="h2"
          style={{
            color: '#0072BB',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: 80
          }}
        >
          Welcome
        </Typography>
        {/* <img
          src="https://storage.googleapis.com/pl-public-assets/feedback-app/Downside Right Corner_SC_E.svg"
          style={{ position: 'absolute', width: 250, bottom: 0, right: 0 }}
        /> */}
        <Grid container>
          <Grid item xs={12} sm={6}>
            <img
              src="https://storage.googleapis.com/pl-public-assets/feedback-app/Smiley_SC_E.svg"
              alt=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              style={{
                margin: '0 auto',
                textAlign: 'center',
                position: 'relative'
              }}
            >
              <br />
              <br />
              <br />
              <br />
              <br />
              <div className={classes.inputCtn}>
                <PersonIcon className={classes.icon} />

                <input
                  type="text"
                  className={classes.input}
                  placeholder="NAME"
                />
              </div>
              <div className={classes.inputCtn}>
                <EmailIcon className={classes.icon} />
                <input
                  type="email"
                  className={classes.input}
                  placeholder="EMAIL ID"
                />
              </div>
              <div className={classes.inputCtn}>
                <LockIcon className={classes.icon} />
                <input
                  type="password"
                  className={classes.input}
                  placeholder="****"
                />
              </div>

              <br />
              <br />
              <button
                onClick={() => setScreen('VIDEO')}
                className={classes.sbmt}
              >
                SIGN IN
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  if (screen === 'VIDEO') {
    return <InteractionPlayer data={data} videoUrl={videoUrl} autoPlay />;
  }

  return null;
}
