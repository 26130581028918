import React, { useContext, useEffect } from 'react';
import firebase from 'firebase/app';
import { Typography, Button } from '@material-ui/core';
import { AlertContext } from 'App';
import { useLocation, useHistory } from 'react-router-dom';

export function EmailVerificationPending() {
  const { showAlert } = useContext(AlertContext);
  const history = useHistory();
  let location: any = useLocation();
  let { from } = location.state || { from: { pathname: '/' } };

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user && user.email && user.emailVerified) {
        history.replace(from);
      }
    });
  }, [from, history]);

  return (
    <div style={{ padding: 16 }}>
      <Typography>
        We need to verify your email before we can begin. Please check your
        inbox for the email.
      </Typography>
      <br />
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          firebase
            .auth()
            .currentUser?.reload()
            .then(async () => {
              await firebase.auth().currentUser?.sendEmailVerification();
              showAlert(
                { message: 'Verification email sent', type: 'Info' },
                () => {}
              );
            });
        }}
      >
        Resend verifiation Email
      </Button>
    </div>
  );
}
