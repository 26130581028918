import React from 'react';
import { Typography, TextField, Button } from '@material-ui/core';
import { Formik } from 'formik';

export function FeedbackFour({
  onSubmit,
  onNext,
  results,
  preview
}: {
  onSubmit: (answers: any, cb: any) => void;
  onNext: () => void;
  results: any;
  preview?: boolean;
}) {
  return (
    <div style={{ padding: 16 }}>
      <Typography variant="h4">Feedback:</Typography>
      <br />
      <Typography>
        What was top 3 things you liked ( good ) in the program?
      </Typography>
      <Formik
        initialValues={
          results && results.answers
            ? results.answers
            : { qa: '', qb: '', qc: '', qd: '' }
        }
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, () => {
            setSubmitting(false);
          });
        }}
      >
        {({ handleSubmit, isSubmitting, handleBlur, handleChange, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <TextField
                label="A"
                fullWidth
                disabled={!!results}
                value={values.qa}
                onChange={handleChange}
                onBlur={handleBlur}
                name="qa"
              />
              <br />
              <TextField
                label="B"
                fullWidth
                disabled={!!results}
                value={values.qb}
                onChange={handleChange}
                name="qb"
                onBlur={handleBlur}
              />
              <br />
              <TextField
                label="C"
                fullWidth
                disabled={!!results}
                value={values.qc}
                onChange={handleChange}
                onBlur={handleBlur}
                name="qc"
              />
              <br />
              <br />
              <Typography>
                What are your suggestions to improve this program?
              </Typography>
              <TextField
                label="Answer"
                fullWidth
                disabled={!!results}
                value={values.qd}
                onChange={handleChange}
                name="qd"
                onBlur={handleBlur}
                multiline
                rows={4}
              />
              <br />
              <br />
              {!results && !preview ? (
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="outlined"
                  color="primary"
                >
                  Submit
                </Button>
              ) : (
                <Button variant="outlined" color="primary" onClick={onNext}>
                  Next
                </Button>
              )}
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

FeedbackFour.defaultProps = {
  preview: false
};
