import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          yes: 'Yes',
          no: 'No',
          Single_Dose: 'Single Dose',
          Double_Dose: 'Double Dose',
          Promotional_Dose: 'Promotional Dose',
          shift: 'Shift',
          attention: 'Attention',
          healthTravel: {
            typeOfWork: 'Type of work',
            internalUse: 'FOR INTERNAL USE',
            travelNFitness: 'Travel and Fitness Declaration by Employees',
            forInternalTracking:
              'This form is for internal tracking purposes only.',
            bekaertDesc1:
              'In view of the ongoing COVID 19 situation , Bekaert Industries Pvt Ltd is taking precautionary measures to safeguard your well- being, as well as the well- being of our staff.',
            bekaertDesc2:
              'We seek your co-operation in filling up this declaration from. The details you submit will be held in confidence in accordance with data protection act and will be used to facilitate contact and health measures to be taken in the event of any incident involving the COVID- 19',
            bekaertDesc3:
              'In case of false information applicable legal action will be taken against the Individual',
            submissionDate: 'Date of submission',
            placeName: 'Name of place',
            bekaertQ9EditNew:
              'Where did you stay in last 24 hours. (name of city or village) ?',
            bekaertQ1EditNew:
              'Are you living in the affected area i.e. on the same floor of affected building or as a close neighbor of positive case of the micro containment zone?',
            bekaertQ1EditNewNote:
              'Send your address and area photograph to your area team leader',
            bekaertQ1:
              'Are you living in the affected area i.e. on the same floor of affected building or as a close neighbor of positive case of the micro containment zone?',
            bekaertQ2:
              'Have you or any of your family members traveled outside your village or city in the last 24 hours? If yes, please specify. (Mention the travel duration and mode of transport)?',
            bekaertQ3:
              'Within the last 14 days, have you been in close physical contact (6 feet or closer for a cumulative total of 15 minutes) with: Anyone who is known to have laboratory-confirmed COVID-19? Or Anyone who has any symptoms consistent with COVID-19?',
            bekaertQ4: 'Do you suffer from allergic or seasonal cold?',
            bekaertQ5:
              'Have you experienced any of the following symptoms in the past 48 hours fever or chills, cough, shortness of breath or difficulty breathing, loss of taste or smell, sore throat',
            bekaertQ5Mod:
              'Have you experienced any of the following symptoms in the past 48 hours fatigue, muscle or body aches, headache, congestion or runny nose, nausea or vomiting, diarrhoea',
            bekaertQ6:
              'Today your body temp. is <strong>above</strong> 38 degree C or 100.4 degree F.',
            bekaertQ7:
              'Did you visit any hospital in last 24 hours for self or family member? Please mention the name and location with reason for visit?',
            bekaertQ8:
              'Have you involved in any public gatherings which include, religious cultural gatherings and other similar events in the last 24 hours?',
            bekaertQ9: 'Is your location under the containment zone?',
            bekaertQ10: 'Are you in blue zone as per the Arogya Setu App?',
            bekaertQ11: 'My vaccination status?',
            leaveReason: 'Leave Reason',
            fromCity: 'From place',
            toCity: 'To place',
            title: 'EMPLOYEE SELF DECLARATION FORM',
            declarationTitle:
              'Travel and fitness declaration by employees, this form is for internal tracking purposes only.',
            desc1:
              'In view of the ongoing COVID 19 situation, Schindler is taking precautionary measure to safeguard your well-being ,as well as the well-being of our staff.',
            desc1_1: 'In view of the ongoing COVID 19 situation, ',
            desc1_2:
              ' is taking precautionary measure to safeguard your well-being ,as well as the well-being of our staff.',
            desc1Oerlikon:
              'In view of the ongoing COVID 19 situation , Oerlikon is taking precautionary measure to safeguard your well-being ,as well as the well-being of our staff.',
            desc2:
              'We seek your co-operation in filling up this declaration form. The details you submit will be held in confidence in accordance with data protection act and will be used to facilitate contact and health measures to be taken in the event of any incident involving the COVID-19.',
            empNameLabel: 'Name',
            firstNameLabel: 'First name',
            lastNameLabel: 'Last name',
            empCodeLabel: 'Empoyee Code',
            mobileNumberLabel: 'Mobile Number',
            deptLabel: 'Department',
            selfDeclarationText: 'Self – Declaration by employees',
            q1: '1. Are you or your family member suffering from any of these symptoms?',
            youAre: 'Employment',
            Employee: 'Schindler Employee',
            EmployeeText: 'Employee',
            EmployeeOerlikon: 'Oerlikon Employee',
            Contractor: 'Other',
            agency: 'Contractor agency',
            factory: 'Factory',
            recentTravel: 'Have you traveled recently?',
            fever: 'Fever',
            cold: 'Cold',
            soreThroat: 'Sore throat',
            runnyNose: 'Runny nose',
            dryCough: 'Dry cough',
            sneezing: 'Sneezing',
            shortnessOfBreath: 'Shortness of breath',
            vomiting: 'Vomiting',
            diarrhoea: 'Diarrhoea',
            symptoms: {
              fever: 'Fever',
              cold: 'Cold',
              soreThroat: 'Sore throat',
              runnyNose: 'Runny nose',
              dryCough: 'Dry cough',
              sneezing: 'Sneezing',
              shortOfBreath: 'Shortness of breath',
              vomiting: 'Vomiting',
              diarrhoea: 'Diarrhoea',
              tiredness: 'Tiredness',
              chestPainOrPressure: 'Chest pain or pressure',
              lossOfSpeechMovement: 'Loss of speech movement',
              lossOfTasteOrSmell: 'Loss of taste or smell',
              achesAndPains: 'Aches and pains',
              conjunctivitis: 'Conjunctivitis',
              headache: 'Headache',
            },
            q2: 'Do you have any history of contact with anyone with the above symptoms or suspected case of COVID 19 in the past 7 days ?',
            q3: 'Is anyone from the same floor in your building found Covid 19 positive and any government authority asked them to home isolate in last 3 days ?',
            q4: 'Is anyone from your family members / relatives / friends with whom you are living found Covid- 19 positive or asked for self quarantine by any government authority in last 14 days?',
            q5: 'Is your own house / building sealed by any government authority?',
            q6: 'Have any one or your family members/roommate advised to go for COVID-19 test by any doctor?',
            q7: 'Did You / your family members / roommates / anybody else you are staying with visited any hospital in the last 7 days?',
            q8new: 'Do you travel by bus? If yes mention the route number',
            routeNumber: 'Please mention the route number',
            remark: 'Remark',
            confirmation:
              'I confirm that I or any of my family members residing with me does not have any are not COVID 19 positive / do not have any of the above symptoms of COVID- 19 and in case of any doubt, I will submit the Doctor certificate for fitness.',
            informEmployer:
              'Have you travelled To and From from Pune in last 7 days',
            travel3days:
              'Have you travelled To and From Pune in the last 3 days in private vehicle?',
            travel7days:
              'Have you travelled To and from Pune in the last 3 days in public transport (Bus, Sharing cab, Airplane, Train etc.)',
            informEmployerSubText:
              "If you haven't traveled you don't need to provide travel details.",
            falseDeclaration:
              'Any false declaration from my end will be considered under violation of CoC.',

            private: 'Private',
            public: 'Public (Bus/Train/Flight)',
            publicBus: 'Public (Bus)',
            publicTrain: 'Public (Train)',
            publicAir: 'Public (Air)',
            publicOther: 'Public (Other)',
            modeOfTransport: 'Mode of Transport',
            city: 'City',
            from: 'From',
            to: 'To',
            remove: 'Remove',
            addMore: 'Add more',
            addTravelDetails: 'Add Travel Details',
            addFamilyDetails: 'Add Family Details',
            addHospitalDetails: 'Add Hospital Details',
            personInFamily: 'Name of the person staying with you',
            age: 'Age',
            relationship: 'Relationship',
            hospitalName: 'Hospital name',
            reason: 'Reason',
            duration: 'Duration',
            informMangeer: 'Please Inform your HR and immediate manager',
            continueFilling: 'and continue filling the form!',
            location: 'Location',
            dateOfVisit: 'Date of visit',
          },
          oerlikonHealthTravel: {
            title: 'Self-Declaration : Health, Travel & Contact History',
            nameOfPerson: '1)	Name of the person',
            ageOfPerson: '2)	Age',
            companyNameNCode: '3)	Company Name and code No',
            location: '4)	Location',
            travelModeToOffice: '5)	Normal Travel Mode to office',
            familyDetails: '6)	Family Detail',
          },
          form: {
            required: 'is a required field',
          },
        },
      },
      mr: {
        translations: {
          yes: 'होय',
          no: 'नाही',
          shift: 'शिफ्ट',
          healthTravel: {
            title: 'स्व घोषणा पत्र',
            declarationTitle:
              'अंतर्गत वापरासाठी -  हा फॉर्म केवळ अंतर्गत ट्रॅकिंग हेतूंसाठी आहे.',
            desc1:
              'सध्या सुरू असलेल्या कोविड-१९  परिस्थिती लक्षात घेता, आपल्या कर्मचार्‍यांचे हित जपण्यासाठी शिंडलर खबरदारीचा उपाय करीत आहे.',
            desc2:
              'ही घोषणा भरण्यासाठी आम्ही आपले सहकार्य घेत आहोत. आपण सबमिट केलेला तपशील विश्वासाने गुप्त ठेवला जाईल आणि कोविड-१९  परिस्थिती मध्ये कोणत्याही प्रकारची घटना घडल्यास,  संपर्क आणि आरोग्यविषयक उपायांसाठी उपयोग केला जाईल.',
            empNameLabel: 'नाव',
            firstNameLabel: 'नाव',
            lastNameLabel: 'आडनाव',
            empCodeLabel: 'कर्मचारी कोड',
            mobileNumberLabel: 'मोबाइल नंबर',
            deptLabel: 'डिपार्टमेंट',
            selfDeclarationText: 'स्वयं - घोषणा',
            recentTravel: 'आपण अलीकडे प्रवास केला आहे का?',
            q1: '1. आपणास किंवा आपल्या परिवारास खालील पैकी कोणती लक्षणे आहेत का ?',
            youAre: 'तुम्ही आहात',
            Employee: 'कर्मचारी',
            Contractor: ' इतर',
            agency: 'काॅंन्ट्रॅक्टर एजन्सी',
            factory: 'फॅक्टरी',
            symptoms: {
              fever: 'ताप',
              cold: 'सर्दी',
              soreThroat: 'घसा खवखवणे',
              runnyNose: 'नाक वाहणे',
              dryCough: 'कोरडा खोकला',
              sneezing: 'शिंका येणे',
              shortOfBreath: 'श्वास घेण्यास त्रास',
              vomiting: 'उलट्या होणे',
              diarrhoea: 'जुलाब',
              tiredness: 'थकवा',
              chestPainOrPressure: 'छातीत दुखणे किंवा दबाव',
              lossOfSpeechMovement: 'भाषण हालचाली नष्ट होणे',
              lossOfTasteOrSmell: 'चव किंवा गंध कमी होणे',
              achesAndPains: 'ठणका व वेदना',
              conjunctivitis: 'डोळे येणे (नेत्रश्लेष्मलाशोथ)',
              headache: 'डोकेदुखी',
            },
            q2: '२. मागील ७ दिवसांत तुमचा वरील लक्षणे असलेल्या कोणत्याही व्यक्तीशी किंवा कोविड 19 च्या संशयिताशीसंपर्क आला आहे का?',
            q3: '३. तुमच्या घरी कोविड 19 पॉसिटीव्ह व्यक्ती आढळली आहे का आणि कोणत्याही सरकारी अधिकाऱ्याने ने त्यांना गेल्या 3 दिवसांत घरी अलगीकरण करण्यास सांगितले आहे का?',
            q4: '४. मागील १४ दिवसांत तुमच्या कुटुंबातील सदस्य / नातेवाईक / मित्र ज्यांच्या सोबत तुम्ही राहत आहात आयसोलेट करण्यास सांगितले आहे का?',
            q5: '५. कोणत्याही शासकीय अधिकाऱ्यांनी तुमचे स्वतःचे घर / इमारत सील केले आहे का?',
            q6: 'तुमच्या कुटुंबातील सदस्य / रुममेट किंवा कोणालाही डॉक्टरांनी कोविड-19 ची टेस्ट करण्याचा सल्ला दिला आहे का?',
            q7: 'मागील ७ दिवसांत तुम्ही / तुमच्या कुटुंबातील सदस्य / रुममेट्स / इतर कोणीही ज्यांच्या सोबत तुम्ही राहता, त्यांनी कोणत्याही रुग्णालयाला भेट दिली आहे का?',
            q8new: 'तुम्ही बस मधून प्रवास करता का?',
            routeNumber: 'रुट नंबर?',
            remark: 'नोंद',
            confirmation:
              'मी खात्री करून देतो की मी / माझ्या कुटुंबातील सदस्य ज्यांच्या सोबत मी राहतो, यांपैकी कोणीही कोरोना पॉझिटीव्ह / कोणालाही वरील दिलेल्या कोविड- 19 ची कोणतीही लक्षणे नाहीत आणि जर शंका असेल तर मी फिटनेस साठी डॉक्टरांचं प्रमाणपत्र सादर करेन.',
            informEmployer:
              'गेल्या ७ दिवसात तुम्ही पुण्याहून बाहेर किंवा बाहेरून पुण्यात प्रवास केला आहे का?',
            informEmployerSubText:
              'जर आपण प्रवास केला नसेल तर आपल्याला प्रवासाचा तपशील देण्याची आवश्यकता नाही.',
            falseDeclaration:
              'माझ्याकडून  झालेल्या कोणत्याही चुकीच्या घोषणेस सीओसीच्या उल्लंघनामध्ये घेण्यात येईल.',
            typeOfWork: 'कामाचा प्रकार',
            private: 'वैयक्तिक',
            public: 'सार्वजनिक (बस / ट्रेन / विमान)',
            publicBus: 'सार्वजनिक (बस)',
            publicTrain: 'सार्वजनिक (रेल्वे)',
            publicAir: 'सार्वजनिक (हवाई प्रवास)',
            publicOther: 'सार्वजनिक (इतर)',
            modeOfTransport: 'वाहतुकीची पद्धत',
            city: 'शहर',
            from: 'या तारखेपासून',
            to: 'या तारखे पर्यंत',
            remove: 'काढा',
            addMore: 'आणखी जोडा',
            addTravelDetails: 'प्रवासाचा तपशील जोडा',
          },
          form: {
            required: 'लिहिणे गरजेचे आहे',
          },
        },
      },
      hi: {
        translations: {
          yes: 'हाँ',
          no: 'नहीं',
          attention: 'ध्यान दें!',
          healthTravel: {
            informMangeer: 'कृपया अपने HR तथा मैनेजर को सूचित करें',
            continueFilling: 'और फॉर्म भरना जारी रखें!',
            empNameLabel: 'कर्मचारी का नाम:',
            empCodeLabel: 'कर्मचारी कोड :',
            mobileNumberLabel: 'मोबाइल नंबर',
            deptLabel: 'विभाग:',
            selfDeclarationText: 'कर्मचारियों द्वारा स्व-घोषणा',
            internalUse: 'आंतरिक उपयोग के लिए',
            travelNFitness: 'कर्मचारियों द्वारा यात्रा और स्वास्थ्य कि घोषणा',
            forInternalTracking:
              'यह फॉर्म केवल आंतरिक ट्रैकिंग उद्देश्यों के लिए है।',
            bekaertDesc1:
              'हाल हि में चल रही COVID 19 स्थिति के मद्देनजर, Bekaert Industries Pvt Ltd आपकी भलाई, साथ ही साथ हमारे कर्मचारियों की सुरक्षा के लिए एहतियाती कदम उठा रहा है।',
            bekaertDesc2:
              'हम इस फॉर्म को भरने में आपका सहयोग चाहते हैं। आपके द्वारा प्रस्तुत विवरण डेटा सुरक्षा अधिनियम के अनुसार विश्वास में आयोजित किया जाएगा और COVID 19 को शामिल करने वाली किसी भी घटना की स्थिति में संपर्क और स्वास्थ्य उपायों की सुविधा के लिए उपयोग किया जाएगा।',
            bekaertDesc3:
              'झूठी सूचना के मामले में, व्यक्ति के खिलाफ लागू कानूनी कार्रवाई की जाएगी',
            submissionDate: 'ज़मा करने की तिथि',
            bekaertQ1:
              '9.	आप पिछले 24 घंटों में कहां रहे। (शहर या गाँव का नाम)?',
            placeName: 'जगह का नाम',
            bekaertQ2:
              '2.	क्या आपने या आपके परिवार के सदस्य ने पिछले 24 घंटों में अपने गाँव या शहर से बाहर यात्रा की है? यदि हां, तो कृपया निर्दिष्ट करें। (यात्रा की अवधि और परिवहन की विधि का उल्लेख करें)',
            bekaertQ3:
              '3.	क्या आप पिछले 24 घंटों में किसी के साथ करीबी संपर्क में आए हैं, जिसने कोरोना पॉसिटीव्ह या कोरोना बाधित होने कि शंका कि वाजह से क्वारंटाईन किया गया है ?',
            bekaertQ4:
              '4.	क्या आप पिछले 24 घंटों में किसी ऐसे व्यक्ति के साथ संपर्क में आए हैं, जिसने विदेश यात्रा की है / विदेश से लौटा है?',
            bekaertQ5:
              '5.	क्या आपको या आपके परिवार के सदस्य या आपके रूममेट को पिछले 24 घंटों में सर्दी, खांसी, बुखार, कमजोरी, बदन दर्द और सांस लेने में कठिनाई या गले में खराश है?',
            bekaertQ6:
              '6.	आज आपके शरीर का तापमान 38 डिग्री सेल्सियस या 100.4 डिग्री एफ से <strong>ऊपर</strong> है।',
            bekaertQ7:
              '7.	क्या आपने स्वयं या परिवार के सदस्य के लिए पिछले 24 घंटों में किसी भी अस्पताल का दौरा किया, कृपया यात्रा के कारण के साथ नाम और स्थान का उल्लेख करें?',
            bekaertQ8:
              '8.	क्या आप एक सार्वजनिक सभा में शामिल हुए हैं जिसमें पिछले 24 घंटों में खेल, धार्मिक सांस्कृतिक समारोहों और इसी तरह के अन्य कार्यक्रम शामिल हैं?',
            bekaertQ9:
              '1. क्या आप माइक्रो कन्टेनमेंट ज़ोन के प्रभावित क्षेत्र में रह रहे हैं',
            bekaertQ9Note: 'आपका पता और जगह का फोटो एरिया टीम लीडर को भेजिए।',
            bekaertQ10: '10. आप आरोग्य सेतु के अनुसार ब्लु झोन में है?',
            leaveReason: 'छुट्टी कारण',
            private: 'निजी',
            public: 'सार्वजनिक (बस / ट्रेन / विमान)',
            publicBus: 'सार्वजनिक (बस)',
            publicTrain: 'सार्वजनिक (रेल गाडी)',
            publicAir: 'सार्वजनिक (हवाई प्रवास)',
            publicOther: 'सार्वजनिक (इतर)',
            modeOfTransport: 'परिवहन के साधन',
            city: 'शहर',
            remove: 'हटाए',
            addMore: 'अधिक जोड़ें',
            duration: 'अवधि',
            addTravelDetails: 'यात्रा विवरण जोड़ें',
            addHospitalDetails: 'अस्पताल का विवरण जोड़ें',
            dateOfVisit: 'विज़िट की तारीख़',
            hospitalName: 'अस्पताल का नाम',
            location: 'स्थान',
            reason: 'कारण',
          },
        },
      },
    },
    fallbackLng: 'en',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: '.', // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
