import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { FirebaseLogin } from './FirebaseLogin';
import { EmailVerificationPending } from './EmailVerificationPending';
import ForgotPassword from './ForgotPassword';
import SignUp from './SignUp';

export function Auth() {
  const { path } = useRouteMatch();
  return (
    <div>
      <Route path={`${path}/firebase-login`}>
        <FirebaseLogin />
      </Route>
      <Route path={`${path}/email-verification-pending`}>
        <EmailVerificationPending />
      </Route>
      <Route path={`${path}/forgot-password`}>
        <ForgotPassword />
      </Route>
      <Route path={`${path}/sign-up`}>
        <SignUp />
      </Route>
    </div>
  );
}
