import React, { useRef, useEffect, useCallback } from 'react';
import { useScript } from 'hooks/useScript';
import { useWindowSize } from 'hooks/useWindowSize';

const data: any = {
  PHOTO_1730: {
    description: 'Parallel Learning | Lobby',
    centerHeading: 90,
    links: [
      {
        heading: -10,
        description: 'Parallel Learning | Printing',
        pano: 'PHOTO_1731'
      },
      {
        heading: 45,
        description: 'Parallel Learning | Work Area 1',
        pano: 'PHOTO_1739'
      },
      {
        heading: 100,
        description: 'Parallel Learning | Work Area 2',
        pano: 'PHOTO_1736'
      }
    ]
  },
  PHOTO_1731: {
    description: 'Parallel Learning | Printing',
    centerHeading: 90,
    links: [
      {
        heading: 20,
        description: 'Parallel Learning | Lobby',
        pano: 'PHOTO_1730'
      }
    ]
  },
  PHOTO_1736: {
    description: 'Parallel Learning | Work Area 2',
    centerHeading: 90,
    links: [
      {
        heading: 40,
        description: 'Parallel Learning | Lobby',
        pano: 'PHOTO_1730'
      }
    ]
  },
  PHOTO_1739: {
    description: 'Parallel Learning | Work Area 1',
    centerHeading: 90,
    links: [
      {
        heading: 40,
        description: 'Parallel Learning | Lobby',
        pano: 'PHOTO_1730'
      }
    ]
  }
};

export function DemoGoogleCustomPanorama() {
  const ref = useRef(null);
  const [loaded, error] = useScript(
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyAy4nxbC7ugY39JLEx398pQpFXve9xAtfw'
  );
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const $window: any = typeof window !== 'undefined' ? window : null;

  const getCustomPanoramaTileUrl = (
    pano: any,
    _zoom: any,
    tileX: any,
    tileY: any
  ) => {
    return `https://storage.googleapis.com/pl-public-assets/google-panorama-images/${pano}/tile_${tileX}_${tileY}.jpg`;
    // return `/demos/google-panorama/${pano}/tile_${tileX}_${tileY}.jpg`;
    // return (
    //   'https://developers.google.com/maps/documentation/javascript/examples/full/images/' +
    //   'panoReception1024-' +
    //   zoom +
    //   '-' +
    //   tileX +
    //   '-' +
    //   tileY +
    //   '.jpg'
    // );
  };

  const getCustomPanorama = useCallback(
    (pano: string) => {
      return {
        location: {
          pano,
          description: data[pano].description
        },
        links: data[pano].links,
        // The text for the copyright control.
        copyright: 'Imagery (c) 2019 Parallellearning.in',
        // The definition of the tiles for this panorama.
        tiles: {
          tileSize: new $window.google.maps.Size(480, 240),
          worldSize: new $window.google.maps.Size(5760, 2880),
          // The heading in degrees at the origin of the panorama
          // tile set.
          centerHeading: data[pano].centerHeading,
          getTileUrl: getCustomPanoramaTileUrl
        }
      };
    },
    [$window.google]
  );

  useEffect(() => {
    if (loaded && !error && $window.google.maps && ref.current) {
      const panorama = new $window.google.maps.StreetViewPanorama(
        document.getElementById('map'),
        {
          pano: 'PHOTO_1730',
          visible: true
          //   pov: {
          //     heading: 280,
          //     pitch: 30
          //   }
        }
      );
      panorama.registerPanoProvider(function(pano: string) {
        return getCustomPanorama(pano);
      });
    }
  }, [loaded, error, $window.google, getCustomPanorama]);
  return (
    <div>
      <div
        style={{ height: windowHeight, width: windowWidth }}
        id="map"
        ref={ref}
      ></div>
    </div>
  );
}
