import React, { useState, useEffect } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import firebase from 'firebase/app';

export const FirebaseAuthRoute: React.FC<RouteProps> = ({
  children,
  ...rest
}) => {
  const { pathname } = useLocation();
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // firebase.auth().signOut();
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return null;
  }

  if (!user && !loading) {
    return (
      <Redirect
        to={{
          pathname: '/phone-auth',
          state: { from: pathname },
        }}
      />
    );
  }

  return <Route {...rest}>{children}</Route>;
};
